[data-braintree-id="methods-label"] {
    color: white;
}

[data-braintree-id="other-ways-to-pay"] {
    color: white;
}

[data-braintree-id="choose-a-way-to-pay"] {
    color: white;
}

[data-braintree-id="methods-container"] {
    padding-bottom: 5px;
}

[data-braintree-id="sheet-error"] {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

[data-braintree-id="unionpay-card-icon"] {
    display: none !important;
}

[data-braintree-id="american-express-card-icon"] {
    display: none !important;
}

[data-braintree-id="jcb-card-icon"] {
    display: none !important;
}

[data-braintree-id="discover-card-icon"] {
    display: none !important;
}

.updatePaymentModal .braintree-heading {
    color: black;
}

.braintree-load-spinner-wrapper {
    height: 50%;
}

.braintree-load-spinner-wrapper .braintree-load-spinner {
    z-index: 2;
    left: 48%;
    top: 35%;
}

.braintree-method {
    width: 100%;
}

.braintree-sheet__label {
    color: black;
}

.rdrCalendarWrapper {
    font-size: 12.5px !important;
}

.free-month-box {
    padding: 70px 30px 40px;
    text-align: center;
}
.subscribe-direct-box {
    padding: 70px 30px 70px;
    text-align: center;
}
.subscription-page h4 {
    font-weight: 400;
}
.subscription-page p {
    color: #828fab;
}

.billing-address-component {
    width: 100%;
    height: 100%;
    padding-top: 25px;
}

.billing-history-component {
    width: 100%;
    height: 100%;
    padding-top: 25px;
}

.free-month-box p span,
.subscription-benefits p span {
    color: #ffffff;
}
.subscribe-direct-box p span,
.box-body p span {
    color: #ffffff;
}
.subscription-summary {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px 0;
    text-align: left;
    border-top: 1px solid #3e4b67;
    border-bottom: 1px solid #3e4b67;
}

.subscription-summary p.summary-item,
.subscription-benefits p.summary-item {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
}
.subscription-summary p.summary-item .check,
.subscription-benefits p.summary-item .check {
    position: absolute;
    left: 0;
    color: #00cbaf;
}
.btn-start-trial {
    padding: 10px 35px;
}

.subscription-row {
    height: auto;
}

.subscription-info,
.subscription-benefits,
.balance-box {
    height: 100%;
}

.sub-info-customer {
    font-size: 14px;
}

.balance-box {
    text-align: left;
    border-radius: 5px;
    background: #2f3a51;
    padding: 30px;
}

.balance-box p {
    margin-bottom: 5px;
}
.balance-box .box-head {
    border-radius: 5px 5px 0 0;
}
.balance-box .box-head h4 {
    padding-top: 15px;
}
.balance-box .box-head h4 span {
    color: #00cbaf;
}
.balance-box .box-body {
    padding-top: 20px;
}

.balance-box .box-body .token-usage {
    text-align: left;
    float: left;
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 10px;
    padding-top: 10px;
}

.balance-box .box-body .token-usage:nth-last-child(2) {
    margin-bottom: 5rem;
}
.balance-box .box-body .token-usage:last-child {
    border-bottom: 0px solid #3e4b67 !important;
    position: absolute;
    bottom: 0px;
}

.balance-box .box-body .token-usage p {
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 0px;
}
.balance-box .box-body .token-usage p.period {
    color: #828fab;
    font-size: 14px;
}
.balance-box .box-body .token-usage p span {
    color: #828fab;
}
.purchase-tokens,
.customer-information {
    background: #3a4763;
    float: left;
    width: 100%;
    border-radius: 2px;
    padding: 12px 20px;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.purchase-tokens {
    align-items: center;
    gap: 1rem;
}

.purchase-tokens button {
    height: 2.5rem;
}

.customer-information {
    display: flex;
    flex-direction: column;
}

.purchase-tokens p {
    margin-bottom: 0px;
    color: #ffffff;
}
.purchase-tokens p.period {
    color: #828fab;
    font-size: 14px;
}

.qty {
    margin-top: 30px;
    float: left;
    width: 100%;
}
.qty.qty-checkout {
    margin-top: 10px;
    margin-bottom: 10px;
}

.qty .form {
    width: 50%;
    float: left;
}
.qty .form .form-label {
    margin-top: 8px;
    margin-right: 10px;
    color: #828fab;
}
.qty .form .form-control {
    width: 80px;
}
.qty .total {
    float: right;
    margin-top: 5px;
}

.subscription-benefits .qty {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #3e4b67;
    margin-bottom: 4rem;
}

.subscription-benefits .total-amount {
    padding: 20px 0 0;
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 70px);
}

.subscription-benefits .total-amount h5 {
    float: left;
    margin-top: 10px;
}
.subscription-benefits .total-amount .btn {
    float: right;
}

.manage-subscription-info .subscr-details {
    border-bottom: 1px solid #3e4b67;
    padding: 0px 0px 20px 0px;
    margin-bottom: 10px;
}

.manage-subscription-info .cancel-subscription,
.edit-subscription {
    border-bottom: 1px solid #3e4b67;
    padding: 0px 0px 20px 0px;
    margin-bottom: 10px;
    justify-content: flex-end;
}

.manage-subscription-info .payment-card-info {
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
}

.payment-card-id {
    color: #000000;
    font-weight: 600;
}

.payment-card-type {
    color: #000000;
    font-size: 12px;
}

.subscription-info .subscr-details {
    margin-top: 20px;
}
.subscription-info .subscr-details p {
    margin-bottom: 0;
    color: #ffffff;
}
.subscription-info .subscr-details p.period {
    color: #828fab;
    font-size: 14px;
    margin: 5px 0;
}

.billing-history-address {
    width: 100%;
    margin-top: 20px;
}
.billing-link {
    color: #00cbaf;
    width: 100%;
    font-weight: 400;
    display: block;
    margin-bottom: 15px;
    text-decoration: none !important;
}
.cancel-subscr,
.pendingCancel {
    width: 100%;
    font-weight: 400;
    color: #e86881;
    margin-top: 80px;
}
.cancel-subscr:hover,
.removeAddon:hover {
    text-decoration: underline;
    color: #e86882ad;
    cursor: pointer;
}

.cancel-promo:hover {
    cursor: pointer;
}

.manage-subscr {
    width: 100%;
    font-weight: 400;
    color: #00cbaf;
    margin-top: 80px;
    float: left;
}
.manage-subscr:hover {
    cursor: pointer;
    text-decoration: underline;
}

.billing-link:hover {
    text-decoration: underline !important;
    color: #00cbaf;
    cursor: pointer;
}

.token-spend-title {
    margin-top: 20px;
}
.token-spend-title h5 {
    margin-top: 0;
}
.token-spend-title p {
    margin-bottom: 0;
    margin-top: 2px;
    color: #00cbaf;
}
.monthly-spending {
    position: relative;
}

.monthly-spending.overview {
    height: 100%;
}
.monthly-spending .chart-box {
    margin-top: 10px;
}

.overview .chart-box {
    height: calc(100% - 55px) !important;
    margin-top: 10px;
}

.monthly-spending h5 {
    float: left;
}
.monthly-spending .date-filter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}

.monthly-spending-dropdown .date-filter {
    position: initial;
}

.monthly-spending .date-filter span {
    color: #ffffff;
}

#monthlyPercent {
    padding-top: 5px;
    margin-top: 10px;
    width: 21%;
    border-radius: 6px;
    background: #415071;
    justify-content: center;
}

#monthlyPercent .percent {
    padding-right: 7px;
    font-weight: 700;
    vertical-align: top;
}

#monthlyPercent p {
    font-size: 11px;
    margin-top: -6px;
    padding: 5px;
}

.monthlyYearlyDropdown {
    height: 30px;
}

.address-title,
.customer-id-title {
    margin-bottom: 30px;
}
.address-title h5,
.customer-id-title h5 {
    margin: 0;
}
.address-title p,
.customer-id-title p {
    margin: 0;
    color: #828fab;
}
.address-title .address-img,
.customer-id-title .customer-id-img {
    float: left;
    padding: 7px 15px 0 0;
}

.customer-id-title .link {
    text-decoration-line: underline;
    color: #00cbaf;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
}

.billing-ad-info {
    overflow-wrap: break-word;
}

.payment-title p {
    margin: 0;
    margin-top: 3px;
    float: right;
}
.payment-title h5 {
    margin: 0;
    margin-top: 3px;
    float: left;
}
.payment-title .address-img {
    float: left;
    padding: 0px 15px 0 0;
}
.bill-title {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 20px;
}
.monthly-charge {
    margin-top: 20px;
    float: left;
    width: 100%;
}
.monthly-charge h6 {
    font-weight: 400;
    margin-bottom: 2px;
}

.enter-promocode {
    border-top: 1px solid #3e4b67;
    border-bottom: 1px solid #3e4b67;
    padding: 20px 0;
    float: left;
    width: 100%;
    margin: 10px 0;
}
.enter-promocode h6 {
    font-weight: 400;
    float: left;
    margin: 0;
    line-height: 24px;
}
.enter-promocode .material-icons {
    color: #00cbaf;
}
.enter-promo-btn {
    float: right;
    color: #00cbaf;
    padding: 0;
}
.enter-promo-btn:hover {
    color: #00cbaf;
    opacity: 0.5;
}
.amount-box {
    background: #3a4763;
    padding: 20px;
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 2px;
}
.amount-box p {
    color: #828fab;
    font-size: 14px;
    margin-bottom: 5px;
}
.amount-box p span {
    float: right;
    color: #ffffff;
}
.total-box {
    background: #3a4763;
    padding: 20px;
    float: left;
    width: 100%;
}
.total-box h5 {
    margin: 0;
}
.total-box h5 span {
    float: right;
    color: #00cbaf;
    border-radius: 2px;
}
.btn.proceed {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
}
.check-term {
    float: left;
    margin-top: 35px;
    padding-left: 35px;
}
.check-term .link {
    color: #00cbaf;
}
.custom-checkbox.check-term .custom-control-label::before,
.custom-checkbox.check-term .custom-control-label::after {
    left: -35px;
}

.cstm-table.biliing-history .table-row .amount {
    color: #389ef9;
}
.cstm-table.biliing-history .table-row .success {
    color: #00cbaf;
}
.cstm-table.biliing-history .table-row .fail {
    color: #e86881;
}
.cstm-table.biliing-history .table-row .download-pdf {
    background: rgba(0, 203, 175, 0.5);
    border: 1px solid #00cbaf;
    padding: 3px 18px;
    font-size: 14px;
}
.promocode-list {
    border-top: 1px solid #f0f0f0;
    margin-top: 20px;
}
.promocode-list .modal-option {
    padding: 15px;
}
.promocode-list .modal-option h5 {
    color: #2f3a51;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.promocode-list .modal-option p {
    font-size: 12px;
}
.promocode-list .modal-option .btn {
    float: right;
    color: #00cbaf;
}

.manage-subscription-info {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
}
.manage-subscription-info .subscr-details p {
    margin-bottom: 0;
    color: #ffffff;
}
.manage-subscription-info .subscr-details p.period {
    color: #828fab;
    font-size: 14px;
    margin: 5px 0;
}
.plan-status {
    color: #828fab !important;
    font-size: 14px;
    margin: 5px 0;
}
.manage-subscription-info .cancel-subscription,
.manage-subscription-info .edit-subscription {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.currentPlanTitle {
    color: #828fab;
    font-size: 14px;
}

.currentPlanRow {
    padding: 15px 0px;
}

.removeAddon {
    color: #f2495e;
    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
}

.not-allowed-input {
    color: #6c757d !important;
    cursor: not-allowed;
}

.address-title .billing-address-edit {
    text-decoration-line: underline;
    color: #00cbaf;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
}

.billing-start-text {
    color: #828fab;
}

.transaction-id-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subscription-container {
    max-width: 100%;
    padding: 0;
}

.billing-history-component #calendar {
    position: absolute;
    z-index: 2;
    top: 50px;
}

.verify-loading-btn {
    min-width: 95px;
}

.verify-error-msg {
    min-width: 200px;
    color: #f2495e;
    margin-left: 10px;
}

.font-weight-400 {
    font-weight: 400;
}

.monthly-spending-date-dropdown {
    max-height: 150px;
    overflow: scroll;
}

.increase-btn-color {
    color: #e86881;
}

.decrease-btn-color {
    color: #2cd9c5;
}

.customer-form-wrap {
    margin-right: 10px;
    min-width: 100px;
}

.customer-form-wrap .customer-form-label {
    margin-right: 5px;
    color: #828fab;
}

.current-plan-footer {
    border-top: 1px solid #3e4b67;
}

.current-plan-footer .current-plan-footer-label {
    color: #00cbaf;
    font-size: 14px;
}

.add-on-form {
    margin-right: 1rem;
    gap: 1rem;
}

.add-on-form .add-form-qty-label {
    color: #ffffff;
    margin-right: 5px;
}

.add-on-form .float-left {
    width: 80px;
    margin-right: 25px;
}

.quantity-field-form-label {
    display: flex;
    gap: 0.2rem;
    margin-bottom: 0;
}

.quantity-field-form-control {
    width: 5rem;
}

.billing-address-form-error {
    color: #dc3545;
    padding: 0.475rem 0.35rem;
    font-size: 0.85rem;
}

@media (max-width: 2000px) {
    #monthlyPercent {
        width: 30%;
    }
}

@media (max-width: 1500px) {
    .purchase-tokens,
    .qty,
    .subscription-benefits .total-amount {
        margin-top: 10px;
    }

    .subscription-benefits .total-amount .btn {
        float: right !important;
        width: initial;
    }

    #monthlyPercent {
        width: calc(100% - 55px);
        margin-top: 10px;
    }
}

@media (max-width: 992px) {
    .subscription-page .breadcrumb {
        margin-top: -20px;
    }
    .subscription-benefits .total-amount {
        width: calc(100% - 50px);
    }
    .manage-subscription-info .cancel-subscription,
    .manage-subscription-info .edit-subscription {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 20px;
    }
}

@media (max-width: 587px) {
    .add-on-field {
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    .add-on-form {
        align-self: flex-start;
    }
    .add-on-field button {
        align-self: flex-end;
    }
}

@media (max-width: 400px) {
    .subscription-benefits {
        height: initial;
    }
}

@media (max-width: 376px) {
    #monthlyPercent {
        width: calc(100% - 40px);
        margin-top: 10px;
    }
}

@media screen and (min-width: 576px) {
    #displayBox {
        min-width: 260px;
    }
}
