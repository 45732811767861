body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.switch-dropdown .org-info {
    max-width: 175px;
}

.profile-link-btn {
    background: none;
    border: none;
}

.profile-drop .profile-user-name {
    width: 150px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.unit-container {
    height: 2.5em !important;
}

.text-primary-blue-3 {
    color: #2f3a51 !important;
}

.text-decoration-line {
    text-decoration: underline;
}

.centered-spinner {
    z-index: 2;
    left: 48% !important;
}

.default-button-width {
    width: 120px !important;
}

.warning-message-red {
    color: #eb5757;
}

.small-text {
    font-size: 12px;
}

.font-size-13px {
    font-size: 13px;
}

.refresh-animation {
    animation: spin 1000ms linear infinite;
}

.default-green-color {
    color: #27ae60 !important;
}

.default-red-color {
    color: #eb5757 !important;
}

.text-primary-red-1 {
    color: #ff0000 !important;
}

.remove-button-red {
    color: #ff0000 !important;
}

.bg-light-gray {
    background-color: #f0f0f0 !important;
}

.bg-blue-gray {
    background-color: #242d41 !important;
}

.flex-zero-point-one {
    flex: 0.1;
}

.default-white-color {
    color: #ffffff !important;
}

.default-grey-color {
    color: #808080 !important;
}

.height-auto {
    height: auto;
}

.text-primary-blue-4 {
    color: #828fab !important;
}

.text-secondary-red-1 {
    color: #f2495e !important;
}

.bg-secondary-red-1 {
    background-color: #f2495e !important;
}

.text-primary-green-1 {
    color: #00cbaf !important;
}

.bg-primary-green-1 {
    background-color: #00cbaf !important;
}

.text-primary-black-1 {
    color: #000000 !important;
}

.height-90vh {
    height: 90vh !important;
}

.delete-icon {
    width: 23px;
    height: 23px;
}

.opacity-btn-enable {
    opacity: 1 !important;
}

.opacity-btn-disable {
    opacity: 0.5 !important;
}

.h-38px {
    height: 38px !important;
}

.text-size-12 {
    font-size: 12px !important;
}

.h-17px {
    height: 17px !important;
}

.h-400px {
    height: 400px !important;
}

.h-30vh {
    height: 30vh !important;
}

.max-w-180px {
    max-width: 180px !important;
}

.no-drop {
    cursor: no-drop !important;
}

.content-wrapper-spinner {
    left: 46% !important;
    top: 60% !important;
}

.flex-basis-220px {
    flex: 0 0 220px !important;
}

.flex-basis-120px {
    flex: 0 0 120px !important;
}

.w-32px {
    width: 32px !important;
}

.text-secondary-red-2 {
    color: #dc3545 !important;
}

.preserve-white-space {
    white-space: pre;
}

.text-end {
    text-align: end;
}

.gap-3 {
    gap: 1rem;
}

.gap-2 {
    gap: 0.5rem;
}

.text-16px {
    font-size: 16px !important;
}

.text-18px {
    font-size: 18px !important;
}

.iot-mb-0 {
    margin-bottom: 0px !important;
}

.iot-font-500 {
    font-weight: 500 !important;
}