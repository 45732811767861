.user-profile .form-control:disabled,
.user-profile .form-control:read-only {
    color: #a2a2a2;
}

.verify-phone-number .resend-code {
    color: #4c4c4c;
}
.verify-phone-number .btn-link {
    color: #00cbaf;
}

.verify-phone-number .resend-code button {
    text-decoration: underline;
}

.verify-phone-number .error-message {
    font-size: 14px;
    background: transparent;
    color: var(--red);
}

::-ms-reveal {
    filter: invert(100%);
}

@media screen and (min-width: 576px) {
    .verify-phone-number .modal-sm {
        max-width: 350px;
    }
}
