/* Responsive */
@media (min-width: 1800px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 1799px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 1400px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .full-option {
        display: none;
    }

    #access-key-button {
        padding: 8px 8px !important;
    }

    .gateway-detail-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1200px) {
    /* upto xl*/

    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    .login-form {
        padding: 50px;
    }
    .login-table {
        padding-left: 0px;
        width: 100%;
        min-width: inherit;
        float: left;
    }

    .gateway-ports {
        margin-top: 30px;
    }

    .access-key-header {
        display: none !important;
    }

    .access-key-label {
        display: inline !important;
    }

    .access-key-scope {
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .gateway-detail-bottom-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 80px);
    }

    .gateway-detail-item.bottom.last {
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@media (max-width: 992px) {
    /* upto lg*/

    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }

    .page-content .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-head {
        margin-left: 0;
        top: 120px;
    }

    .login-form::before {
        display: none;
    }
    .login-form {
        padding: 20px;
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: 100vh;
    }

    .topbar-divider {
        display: none;
    }

    .logo {
        padding: 0 10px;
        left: 70px;
        position: absolute;
    }
    .switch-dropdown {
        position: absolute;
        top: 60px;
        width: 100%;
    }
    .switch-dropdown .switch {
        border-radius: 0;
    }
    .switch-dropdown .switch p {
        text-align: left;
    }
    .switch {
        position: absolute;
        left: 0;
        bottom: -60px;
        background: #323d54;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;
    }
    .switch i {
        right: 15px;
    }
    .switch i.down {
        top: 25px;
    }
    .switch i.up {
        top: 15px;
    }

    .page-head .container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }
    .page-head .page-title {
        margin-bottom: 15px;
    }
    .page-content {
        margin-left: 0rem !important;
        margin-top: 4.5rem !important;
        padding-bottom: 30px;
    }

    .navbar {
        padding: 0;
    }
    .navbar-expand .navbar-toggler {
        display: block;
        top: 1rem;
        left: 1rem;
        color: #ffffff;
    }
    .sidebar {
        left: -1000px;
        width: 100% !important;
        transition: all 1s;
    }
    .sidebar.show {
        left: 0;
        transition: all 0.5s;
    }
    .sidebar .nav-item {
        border-bottom: 1px solid #364056;
        transition: all 0.5s;
    }
    .sidebar .nav-item .nav-link {
        text-align: left;
        padding: 20px 27px;
        width: 100%;
        float: left;
        transition: all 0.5s;
    }
    .sidebar .nav-item .nav-link span {
        font-size: 16px;
        display: inline;
        margin-left: 23px;
    }

    .burger {
        top: 23px;
        display: block;
        position: absolute;
        left: 25px;
        z-index: 1000;
        width: 25px;
        height: 40px;
        transition: all 0.3s;
        cursor: pointer;
    }
    .menuToggle {
        margin-top: 2px !important;
    }
    .menuToggle span {
        display: block;
        width: 100%;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        border-radius: 0;
        z-index: 1;
        transform-origin: 4px 0;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    }
    .burger.show .menuToggle span:nth-last-child(3) {
        opacity: 1;
        transform: rotate(45deg) translate(0, 1px);
        top: 0px;
    }
    .burger.show .menuToggle span:nth-last-child(2) {
        opacity: 0;
        transform: rotate(0) scale(0.2, 0.2);
    }
    .burger.show .menuToggle span {
        opacity: 1;
        transform: rotate(-45deg) translate(-1px, -2px);
        background: #fff;
    }
    .sidebar .nav-item:hover .submenu {
        display: none;
        width: 100%;
        z-index: 99;
        left: initial;
        top: initial;
        position: relative;
        border-radius: 0;
        float: left;
        box-shadow: none !important;
        padding: 20px 24px 0;
        transition: all 0.5s;
    }
    .sidebar .nav-item:hover .submenu.show {
        display: block;
        transition: all 0.5s;
    }
    .sidebar .submenu-item {
        padding: 15px 20px;
    }
    .modal-dialog .dash-icon {
        margin: 15px 25px 15px 0;
    }
    .form-box {
        padding: 20px;
    }
    .form-box .btn-primary,
    .form-box .search {
        width: 100%;
        float: none !important;
    }
    .gateway-header {
        margin-bottom: 20px;
    }

    .gateway-details-box {
        padding: 20px;
    }
    .gateway-details-box .gateway-info {
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 20px;
    }
    .gateway-info h6.ginfo {
        font-size: 14px;
    }
    .gateway-ports {
        margin-top: 30px;
    }

    .gateway-details-tabs .nav-item .nav-link h6 {
        display: none;
    }
    .gateway-details-tabs .tab-pane {
        padding: 20px;
    }
    .discriptor-box {
        padding: 0 !important;
    }
    .update-info {
        padding-bottom: 10px;
    }

    .page-tabs .nav-item {
        width: 32%;
    }
    .org-name {
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 35px;
        font-size: 20px;
    }
    .org-detail-content {
        margin-bottom: 15px;
    }
    .header-subscribe-button {
        display: none;
    }
    .cstm-table .table-head {
        display: none;
    }
    .cstm-table .table-row .row > div {
        height: 30px;
    }

    .ldsuList-row,
    .gatewayList-row {
        align-items: normal;
    }

    .ldsuList-row-middle,
    .gatewayList-row-middle {
        flex-direction: column;
        align-items: normal;
    }

    .gatewayList-row-middle {
        flex: 11;
    }

    .ldsuList-row-end,
    .gatewayList-row-end {
        flex-direction: column-reverse;
        align-items: center;
    }

    .ldsuList-row-delete-action,
    .gatewayList-row-delete-action {
        flex: 1;
        margin-left: 0;
    }
    .ldsuList-row-detail-action,
    .gatewayList-row-detail-action {
        flex: 3;
    }

    .gatewayList-row-detail-action i {
        margin-right: 0 !important;
    }

    .cstm-table .table-row .reading-label {
        display: inline-block;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    /* upto md*/
    .page-title {
        text-align: left;
    }
    .gateway-header .btn {
        width: 100%;
    }
    .gateway-header-buttons {
        width: 100%;
    }
    .btn-primary {
        padding: 6px 10px;
    }
    .search {
        width: 180px;
    }
    .gateway-list-page .search {
        width: 100%;
    }

    .subscription-page .search {
        width: 100%;
        margin-top: 10px;
    }
    .subscription-page .filter {
        width: 100%;
    }
    .subscription-page .filter-button {
        width: 100%;
    }
    .header-balance-details,
    .chart-filter {
        display: none;
    }
    .page-content .no-checkbox {
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-right: 0px;
    }

    .btn.add-gateway,
    #generate-key-button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cstm-table .table-row {
        padding: 20px;
        font-size: 15px;
    }

    .cstm-table .table-row .custom-checkbox {
        padding-left: 4px;
    }
    .cstm-table .table-row i.right {
        float: none;
    }
    .cstm-table .table-row .row > div {
        height: auto;
    }

    .max-w-180px {
        max-width: unset !important;
    }

    .LDSU-header .btn {
        margin-top: 20px;
    }
    .page-tabs .nav {
        margin-top: 20px;
        padding: 0 10px;
    }

    .page-tabs .nav-item .nav-link {
        padding: 17px 10px;
    }
    .page-tabs .nav .nav-item .nav-link .count {
        display: none;
    }

    .event-detail-head .btn {
        margin-top: 20px;
        width: 100%;
    }
    .descriptor-item h6 {
        width: 100%;
    }
    .sensor-header .page-title {
        margin-bottom: 85px;
    }
    .sensor-summary .last-active,
    .sensor-summary .sensor-info {
        width: 100%;
    }
    .sensor-summary .sensor-info h2 {
        width: 100%;
    }
    .sensor-summary .sensor-info p {
        margin: 0 15px 0 0;
    }
    .sensor-summary .last-active p {
        text-align: left !important;
    }
    .sensor-mode .custom-control,
    .alert-once-continuous .custom-control {
        margin-bottom: 15px;
    }

    .text-right-xs {
        text-align: right;
    }
    .uart-box .form-box-head {
        padding: 20px 20px 15px;
    }
    .uart-box .form-box-head h5 {
        width: 100%;
    }
    .uart-box .form-box-head .reset {
        float: left;
        text-align: left;
        margin-top: 20px;
    }
    .uart-box .form-box-head .btn {
        margin-top: 20px;
        margin-left: 0;
    }

    .org-details-head {
        margin-bottom: 20px;
    }

    .org-detail-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .org-detail-btn .org-btn,
    .org-detail-btn .org-btn .btn {
        width: 100%;
    }

    .table-head-options .btn,
    .table-head-options .search {
        width: 100%;
    }
    .member-detail-head .page-title {
        margin-bottom: 80px;
    }
    .group-detail-head .page-title,
    .role-details-header .page-title {
        margin-bottom: 80px;
    }
    .invite-user-header {
        margin-bottom: 55px;
    }
    .member-info-item {
        margin-bottom: 20px;
    }
    .member-info-item .btn {
        width: 100%;
    }
    .table-head .group-item .group-item-name,
    .table-row .group-item .group-item-name {
        padding-left: 5px;
    }

    .gateway-detail-bottom-container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 80px);
    }
}

@media (max-width: 400px) {
    .modal-dialog .dash-icon {
        margin: 15px 20px 15px 0;
    }
    .modal-dialog form .icons .dash-icon:nth-child(4n) {
        margin-right: 20px;
    }
    .ldsbus-buttons,
    .ldsu-buttons {
        flex: 1;
    }

    .ldsbus-buttons Button,
    .ldsu-buttons Button {
        width: 100%;
    }
}
@media (max-width: 330px) {
    .search {
        width: 136px;
    }
    .navbar .nav-item {
        margin: 0 3px;
    }
}

@media (min-width: 535px) {
    .new-dash .modal-dialog {
        max-width: 400px;
    }
}
