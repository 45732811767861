.events-list .table-row img {
    margin-right: 7px;
}
.events-list .table-row .event-actions {
    position: absolute;
}

.enable-event-box {
    height: calc(100% - 7px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.when-do-title {
    background: #2f3a51;
    padding: 10px;
    border-radius: 5px;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}
.arrow-circle {
    position: absolute;
    left: -35px;
    background: #2f3a51;
    border-radius: 50px;
    height: 70px;
    width: 70px;
    top: 10px;
    box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0, 0.3);
}
.arrow-circle i {
    font-size: 70px;
    color: #00cbaf;
}
.conditions-list,
.actions-list {
    margin-top: 40px;
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}
.conditions-list {
    padding-right: 40px;
    text-align: center;
}
.actions-list {
    padding-left: 40px;
    text-align: center;
}
.actions-list .btn.add-item,
.conditions-list .btn.add-item {
    padding: 6px 50px;
    margin-top: 1rem;
}
.conditions-list > .btn.add-item {
    margin-top: 20px !important;
}

.conditions-list-empty,
.actions-list-empty {
    margin-top: 40px;
    float: left;
    width: 100%;
    text-align: center;
    color: #828fab;
    position: relative;
}
.conditions-list-empty .btn,
.actions-list-empty .btn {
    padding: 10px 70px;
    margin-top: 5px;
}
.conditions-actions .divider {
    background: #2f3a51;
    width: 1px;
    height: 90%;
    position: absolute;
    top: 100px;
    right: 0px;
}
.action-item {
    background: #2f3a51;
    padding: 20px 25px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    text-align: left;
    float: left;
    width: 100%;
}
.action-item:hover {
    text-decoration: none;
    opacity: 0.9;
    color: #ffffff;
}
.action-item h5 {
    margin-bottom: 20px;
    font-weight: 400;
}
.action-item h6 {
    font-weight: 400;
}
.action-item p {
    color: #828fab;
}
.action-item img {
    margin-right: 10px;
    width: 38px;
}
.action-item .remove-action {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #f2495e;
    cursor: pointer;
}

.action-details {
    padding-left: 3rem;
}

.action-details h6:first-child {
    margin-bottom: 0.1rem;
}

.condition-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.condition-item {
    background: #2f3a51;
    padding: 20px 0px 15px;
    border-radius: 10px;
    position: relative;
    text-align: left;
    margin-top: -30px;
    float: left;
    width: 100%;
}
.condition-item .condition-box {
    float: left;
    width: 100%;
}
.condition-item .condition-box:hover {
    opacity: 1;
    color: #ffffff;
}
.condition-item:first-child {
    margin-top: 0;
}
.condition-item h6 {
    margin-bottom: 5px;
    font-weight: 400;
}
.condition-item p {
    color: #828fab;
    margin-bottom: 5px;
}
.condition-item img {
    border-radius: 60px;
    margin-left: 20px;
    float: left;
    width: 40px;
}
.condition-item .condition-texts {
    margin-left: 20px;
    float: left;
}
.condition-item .remove-action {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #f2495e;
    cursor: pointer;
}
.condition-item .and-section {
    border-top: 1px solid #3e4b67;
    text-align: center;
    margin-top: 35px;
    float: left;
    width: 100%;
}
.condition-item .and-section .and-btn {
    background: #415071;
    border-color: #415071;
    margin: auto;
    width: 80px;
    font-size: 15px;
    margin-top: -20px;
    padding: 8px 15px;
    border-radius: 0.25rem;
}
.add-condition-item {
    background: #2f3a51;
    text-align: center;
    padding: 10px;
    margin-top: -10px;
    padding-bottom: 30px;
    border-radius: 0 0 10px 10px;
    float: left;
    width: 100%;
}
.add-condition-item .btn {
    padding-left: 50px;
    padding-right: 50px;
}
.or-section {
    border-top: 1px solid #3e4b67;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    float: left;
    width: 100%;
}
.or-section .or-btn {
    background: #415071;
    border-color: #415071;
    margin: auto;
    width: 80px;
    font-size: 15px;
    margin-top: -20px;
    padding: 8px 15px;
    border-radius: 0.25rem;
}
.condition-options-list .modal-option {
    padding: 15px;
}
.condition-options-list .modal-option:hover {
    opacity: 0.8;
}
.condition-options-list .modal-option p {
    font-size: 12px;
    color: #828fab;
}
.condition-options-list .modal-option img {
    width: 40px;
    float: left;
    margin-right: 20px;
}
.condition-options-list .modal-option i {
    color: #00cbaf;
    float: right;
    margin-top: 10px;
}
.action-device-details {
    background: #3a4763;
    padding: 20px;
    border-radius: 5px;
}
.action-device-details h6 {
    font-weight: 400;
    font-size: 18px;
}
.action-device-details p {
    opacity: 0.75;
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 15px;
}
.action-detail-box .input-group .form-control {
    border-radius: 0.25rem !important;
}
.action-detail-box .input-group .input-group-text {
    background: rgb(36, 45, 65, 0);
    border: none;
    color: #ffffff;
}
.action-detail-box .added-item,
.condition-detail-box .added-item {
    float: left;
    padding: 10px 20px;
    padding-right: 12px;
    background: #3a4762;
    border-radius: 25px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.action-detail-box .added-item span,
.condition-detail-box .added-item span {
    color: #f2495e;
    font-size: 20px;
    margin-left: 7px;
    margin-top: 1px;
    cursor: pointer;
}
.condition-detail-box .recipients-added {
    position: relative;
    border-radius: 10px;
}

.condition-detail-box .recipients-added .recipient-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.condition-detail-box .recipients-added .phone-number-error-font-size {
    font-size: 0.8rem;
}

.condition-detail-box .recipient-msg {
    color: #828fab;
    font-size: 12px;
}

.added-item-recipient {
    padding-right: 20px;
    word-break: break-word;
}
.weekday {
    margin-bottom: 10px;
}
.weekday .weekday-option {
    border-bottom: 1px solid #3e4b67;
    border: 1px solid #3e4b67;
    border-radius: 5px;
    padding: 20px;
    transition: ease 0.5s;
}
.weekday .weekday-option.show {
    border-radius: 5px 5px 0 0;
    transition: ease 0.5s;
}
.weekday .weekday-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    display: none;
    transition: all 0.5s;
}
.weekday .weekday-box.show {
    display: block;
    transition: all 0.5s;
}
.weekday .weekday-option .custom-switch {
    padding-left: 40px;
}
.monthday {
    margin-bottom: 10px;
}
.monthday .monthday-date {
    border: 1px solid #3e4b67;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    transition: ease 0.5s;
}
.monthday .monthday-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    transition: all 0.5s;
}

.add-a-day .modal-option {
    background: #f8f8f8;
}

.modal-dialog:has(.delay-event__picker) {
    width: 352px;
}

.delay-event__picker > .MuiFormControl-root {
    background: #f0f0f0 !important;
    width: 100%;
    outline: none;
}

.delay-event__header:hover {
    color: #00cbaf;
    opacity: 0.5;
    text-decoration: underline;
    cursor: pointer;
}

.delay-event__desc {
    color: #00cbaf;
}

.event-edit {
    position: absolute;
    top: 20px;
    right: 60px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.event-edit[disabled] {
    opacity: 0.5;
}

.event-edit img {
    margin: 0;
}

.drag-action,
img.drag-action {
    position: absolute;
    top: 60px;
    right: 20px;
    margin: 0;
    cursor: grab;
}

.drag-action > img,
img.drag-action {
    width: 24px;
    height: 40px;
    margin: 0;
}

.permission-container.drag-action {
    cursor: no-drop;
}

.conditions-list .grid-stack {
    min-height: 40px;
}

.grid-stack-item + .grid-stack-item .condition-item {
    padding-top: 10px;
    border-radius: 0;
}

.grid-stack .grid-stack-item:first-child .condition-item {
    border-radius: 10px 10px 0 0;
}

.warning-message {
    display: flex;
    align-items: center;
    color: #f2495e;
    margin-top: 8px;
}

.warning-message.actuator {
    color: #f2994a;
}

.warning-message .material-icons {
    font-size: 1.4rem;
}

.warning-message span {
    line-height: 1.2;
    margin-left: 5px;
}

.conditions-list .grid-stack > .grid-stack-item[gs-w="1"],
.actions-list .grid-stack > .grid-stack-item[gs-w="1"] {
    width: 100%;
}

.every-input-unit {
    position: absolute;
    padding-right: 1rem;
    z-index: 15px;
}

.event-action-col {
    height: 24px;
    display: flex;
    align-items: center;
}

.grid-stack-item .action-item .content-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
}

.invalid-time-error-msg {
    color: #f2495e;
    position: absolute;
    left: 25px;
}

.related-event-condition .event-actions-col {
    display: flex;
    align-items: center;
    height: 24px;
}

.wrap-around-col {
    display: flex;
    align-items: center;
}

.wrap-around-col .custom-switch {
    padding-left: 2.25rem;
}

.wrap-around-hint {
    font-size: 12px;
    font-weight: 400;
    color: #828fab;
    margin-bottom: 0;
}

.wrap-around-col .wrap-around-hint {
    margin-left: 30px;
}

.event-variable-form-header {
    padding: 20px 30px 15px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
}

.event-variable-form-header + .form-box {
    border-radius: 0;
}

.event-variable-form-header h5 {
    font-size: 18px;
    font-weight: 400;
}

.event-variable-form-content label {
    font-size: 16px;
    font-weight: 500;
}

.event-variable-form-content .form-control {
    font-size: 14px;
    font-weight: 400;
}

.associated-events .page-title {
    font-size: 20px;
    font-weight: 500;
}

.associated-events .cstm-table {
    font-weight: 400;
    font-size: 16px;
}

.event-variable-form-footer {
    padding: 20px 30px 20px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
}

.associated-event-actions img {
    margin-right: 7px;
}

.modify-event-variable .event-variable-info {
    background-color: #394662;
    padding: 15px 0 15px 1rem;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    word-break: break-all;
    font-weight: 400;
}

.modify-event-variable .event-variable-info h4 {
    font-size: 16px;
    font-weight: 400;
}

.not-counter-info {
    color: #ced1d8;
}

.modify-event-variable .event-variable-info p {
    font-size: 12px;
    margin-bottom: 0;
}

.modify-event-variable .event-variable-info span {
    color: #828fab;
}

.modify-event-variable .action-detail-box h5 p {
    font-size: 12px;
    font-weight: 300;
}

.event-variable-label {
    font-size: 18px;
    font-weight: 500;
}

.select-event-variable-item h4 {
    font-weight: 400;
    font-size: 16px;
}

.select-event-variable-item span {
    font-weight: 400;
    font-size: 12px;
    color: #828fab;
}

.action-details .variable-details h6,
.action-details .variable-details p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 95%;
    word-break: break-all;
    padding-right: 20px;
}

.tag-input-container {
    padding: 5px;
    border-radius: 4px;
    background-color: #242d41;
    border: 1px solid #36425c;
}

.event-variable-form-content .disabled-input {
    color: #6c757d !important;
}

.tags {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.list-operation-btn {
    display: flex;
    gap: 2;
}

.list-operation-btn button {
    border: 1px solid #36425c;
    background-color: #3c4d71;
    border-radius: 40px;
    margin-right: 8px;
}

.modify-label {
    padding: 5px;
    display: flex;
    align-items: center;
    height: 50px;
}

.modify-label p {
    margin-bottom: 0;
}

.tag {
    border: 1px solid #3a4763;
    background-color: #3a4763;
    padding: 2px 5px;
    margin-right: 5px;
    border-radius: 36px;
    display: flex;
    align-items: center;
}

.tags li:first-child {
    margin-left: 10px !important;
}

.input-tag input {
    border: none;
    outline: none;
    background: transparent;
    width: auto;
    color: white;
}

.tags li {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 5px;
}

.tags li span {
    display: flex;
    align-items: center;
    height: 100%;
    word-break: break-all;
}

.expression-error-msg {
    font-size: 80%;
    font-weight: 400;
}

.add-condition-action-btn {
    border: 1px solid #00cbaf;
    border-radius: 4px;
    color: #00cbaf;
    font-weight: 400;
}

.recurrence-start-time {
    display: grid;
    grid-template-columns: 2fr 7fr 3fr;
    align-items: center;
}

.recurrence-end-time-option {
    display: grid;
    grid-template-columns: 2fr 4fr 6fr;
    align-items: center;
    margin-top: 20px;
}

.recurrence-end-time-option p {
    margin-bottom: 0;
}

.end-time-check {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
}

.recurrence-start-time p {
    margin-bottom: 0;
}

.date-and-time-picker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 2rem;
}

.condition-guard-warn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: #f2495e;
    font-size: 16px;
}

.link-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #00cbaf;
    text-decoration: none;
}

.link-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.condition-guard-warn p {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .cstm-table.events-list .table-row .btn {
        padding: 0;
    }
    .cstm-table.events-list .table-row .row div {
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .conditions-actions .divider {
        width: 0px;
    }
    .conditions-list {
        padding-right: 0px;
    }
    .actions-list {
        padding-left: 0;
    }

    .event-detail-head.event-main-page .page-title {
        margin-bottom: 25px;
    }

    .conditions-list,
    .actions-list {
        margin-top: 0px;
        padding: 20px 10px 30px;
        border: 1px solid #2f3a51;
        border-radius: 0 0 5px 5px;
    }
    .when-do-title {
        border-radius: 5px 5px 0 0;
    }
    .arrow-circle {
        width: 50px;
        height: 50px;
        left: 47%;
        top: -40px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .arrow-circle i {
        font-size: 50px;
    }
    .condition-options-list .modal-option p {
        font-size: 10px;
    }
    .condition-item img {
        margin-left: 10px;
    }
    .condition-item .condition-texts {
        margin-left: 10px;
    }
}
@media (max-width: 768px) {
    .arrow-circle {
        left: 43%;
    }
}
