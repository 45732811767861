@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.switch-dropdown .org-info {
    max-width: 175px;
}

.profile-link-btn {
    background: none;
    border: none;
}

.profile-drop .profile-user-name {
    width: 150px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.unit-container {
    height: 2.5em !important;
}

.text-primary-blue-3 {
    color: #2f3a51 !important;
}

.text-decoration-line {
    text-decoration: underline;
}

.centered-spinner {
    z-index: 2;
    left: 48% !important;
}

.default-button-width {
    width: 120px !important;
}

.warning-message-red {
    color: #eb5757;
}

.small-text {
    font-size: 12px;
}

.font-size-13px {
    font-size: 13px;
}

.refresh-animation {
    -webkit-animation: spin 1000ms linear infinite;
            animation: spin 1000ms linear infinite;
}

.default-green-color {
    color: #27ae60 !important;
}

.default-red-color {
    color: #eb5757 !important;
}

.text-primary-red-1 {
    color: #ff0000 !important;
}

.remove-button-red {
    color: #ff0000 !important;
}

.bg-light-gray {
    background-color: #f0f0f0 !important;
}

.bg-blue-gray {
    background-color: #242d41 !important;
}

.flex-zero-point-one {
    flex: 0.1 1;
}

.default-white-color {
    color: #ffffff !important;
}

.default-grey-color {
    color: #808080 !important;
}

.height-auto {
    height: auto;
}

.text-primary-blue-4 {
    color: #828fab !important;
}

.text-secondary-red-1 {
    color: #f2495e !important;
}

.bg-secondary-red-1 {
    background-color: #f2495e !important;
}

.text-primary-green-1 {
    color: #00cbaf !important;
}

.bg-primary-green-1 {
    background-color: #00cbaf !important;
}

.text-primary-black-1 {
    color: #000000 !important;
}

.height-90vh {
    height: 90vh !important;
}

.delete-icon {
    width: 23px;
    height: 23px;
}

.opacity-btn-enable {
    opacity: 1 !important;
}

.opacity-btn-disable {
    opacity: 0.5 !important;
}

.h-38px {
    height: 38px !important;
}

.text-size-12 {
    font-size: 12px !important;
}

.h-17px {
    height: 17px !important;
}

.h-400px {
    height: 400px !important;
}

.h-30vh {
    height: 30vh !important;
}

.max-w-180px {
    max-width: 180px !important;
}

.no-drop {
    cursor: no-drop !important;
}

.content-wrapper-spinner {
    left: 46% !important;
    top: 60% !important;
}

.flex-basis-220px {
    flex: 0 0 220px !important;
}

.flex-basis-120px {
    flex: 0 0 120px !important;
}

.w-32px {
    width: 32px !important;
}

.text-secondary-red-2 {
    color: #dc3545 !important;
}

.preserve-white-space {
    white-space: pre;
}

.text-end {
    text-align: end;
}

.gap-3 {
    grid-gap: 1rem;
    gap: 1rem;
}

.gap-2 {
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.text-16px {
    font-size: 16px !important;
}

.text-18px {
    font-size: 18px !important;
}

.iot-mb-0 {
    margin-bottom: 0px !important;
}

.iot-font-500 {
    font-weight: 500 !important;
}
:root {
    --create-chart-form-secondary: #2f3a51;
    --create-chart-from-background: #394662;
    --create-chart-form-active: #00cbaf;
    --create-chart-sub-text-color: #828fab;
    --create-chart-multiple-sensor-background: #252e42;
    --create-chart-deep-button-background: #242d41;
    --create-chart-deep-button-border: #36425c;
}
body {
    font-size: 1rem;
    background-color: #171f30;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}
h5 {
    font-size: 20px;
    font-weight: 400;
}
a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    color: #00cbaf;
    opacity: 0.5;
}
.green {
    color: #00cbaf !important;
}
.red {
    color: #00cbaf !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

.btn + .btn {
    margin-top: 0px;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
    padding: 6px 20px;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 0.5;
}
.btn-primary:disabled:hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 0.5;
}

.btn-primary:hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 1;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background-color: #08a58f;
    border-color: #08b39b;
}

.btn-outline-primary {
    border-color: #00cbaf;
    color: #fff;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-secondary {
    color: #aab4c9;
    background-color: #2f3a51;
    border-color: #2f3a51;
    padding: 6px 20px;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    color: #fff;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.btn-secondary:not(.sign-up):hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 1;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    background-color: #08a58f;
    border-color: #08b39b;
}

.btn-danger {
    color: #ffffff;
    background-color: #f2495e;
    border-color: #f2495e;
    padding: 6px 20px;
}

.btn-abort {
    background-color: #3c5a99 !important;
    border-color: #3c5a99 !important;
}

.btn-abort:hover {
    background-color: #3c5a99 !important;
    border-color: #3c5a99 !important;
}

.btn:disabled,
.btn:hover {
    opacity: 0.6 !important;
}

.btn-light {
    color: #00cbaf;
    background: none;
    border: none;
    padding: 6px 20px;
}
.btn-light:hover {
    color: #aab4c9;
    background-color: #2f3a51;
    border-color: #2f3a51;
    padding: 6px 20px;
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
    box-shadow: none;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    background-color: #08a58f;
    border-color: #08b39b;
}
.form-control:focus {
    border: 1px solid #08a58f;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #00cbaf;
    border-color: #00cbaf;
}
.custom-switch {
    padding-left: 20px;
}
.custom-switch .custom-control-label::before {
    width: 50px;
    height: 26px;
    border-radius: 30px;
    background: #55678c;
    border-color: #55678c;
}

.custom-switch .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 30px;
    top: 4px;
    left: -30px;
    background: #2f3b51;
}
.custom-switch,
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
    cursor: pointer;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::after {
    cursor: no-drop;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #2f3b51;
    -webkit-transform: translateX(1.4rem);
            transform: translateX(1.4rem);
}
.custom-control-label {
    cursor: pointer;
}
.table-row .custom-switch {
    float: left;
}

.custom-control-label:focus {
    box-shadow: none;
}
.custom-control-label:before {
    background-color: #242d41;
    border: 1px solid #36425c;
    border-radius: 3px;
    top: 0;
    height: 21px;
    width: 21px;
}
.custom-control-label:after {
    height: 21px;
    width: 21px;
    top: 0px;
    left: -1.5rem;
}
.custom-control-label:not(:disabled):not(.disabled).active:focus,
.custom-control-label:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.modal-header {
    background: #2f3a51;
    border-radius: 12px 12px 0 0;
    padding: 20px 20px;
}
.modal-header .close {
    color: #ffffff;
    opacity: 1;
    margin-right: -13px;
    margin-top: -13px;
}
.border-0 .modal-dialog .modal-content {
    border: 0px solid black !important;
}

.modal-content {
    border-radius: 20px 20px 12px 12px;
}
.modal-title.h4 {
    font-size: 20px;
}

.dropdown-toggle::after {
    margin-bottom: -2px;
}

.breadcrumb {
    background: none;
    padding: 0;
    margin-top: -20px;
}
.breadcrumb-item a {
    color: #00cbaf;
}
/** Navbar*/
.navbar {
    padding: 0 16px;
    height: 60px;
    background-color: #394662;
    z-index: 90;
}

.logo {
    padding: 0 10px;
}
.logo img {
    width: 140px;
}
.navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
}

.topbar-divider {
    border-right: 1px solid #536487;
    width: 0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
}

.switch {
    padding: 0 5px;
    position: relative;
    cursor: pointer;
    border: 0;
}
.switch h5,
.switch p {
    margin-bottom: 0;
}
.switch h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: -2px;
    text-align: left;
    color: #ffffff;
}
.switch p {
    font-size: 14px;
    color: #828fab;
}
.switch i {
    position: absolute;
    right: -35px;
    top: 10px;
    color: #00cbaf;
}
.switch i.up {
    top: 5px;
}
.switch i.down {
    top: 15px;
}
.switch.dropdown-toggle::after {
    content: none;
}
.switch-drop {
    width: 320px;
    padding: 20px;
    background: #2f3a51;
    border: 1px solid #414d69;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}
.new-org-button {
    margin-top: 10px;
    width: 100%;
    padding: 0px;
    background: #2f3a51;
    float: left;
}
.switch-drop p {
    color: #828fab;
    float: left;
    margin-bottom: 7px;
}
.switch-drop .dropdown-item {
    float: left;
    padding: 0;
    margin: 5px 0;
}
.switch-drop .dropdown-item .first-letter {
    padding: 5px 0px;
    border-radius: 50px;
    background: #ffffff;
    color: #00cbaf;
    font-size: 18px;
    float: left;
    width: 37px;
    height: 37px;
    margin-right: 12px;
    text-align: center;
}
.switch-drop .dropdown-item .check {
    display: none;
    float: right;
    color: #00cbaf;
    margin-top: 5px;
}
.switch-drop .dropdown-item.selected .check {
    display: block;
}

.switch-drop .dropdown-item h6 span.star {
    display: none;
}

.switch-drop .dropdown-item:focus,
.switch-drop .dropdown-item:hover {
    background: none;
}

.navbar .nav-item {
    margin: 0 15px;
    position: relative;
}

.navbar .nav-item .nav-link {
    height: 4.375rem;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    position: relative;
}
.navbar .nav-link .badge-counter {
    position: absolute;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    margin-top: -0.25rem;
    background-color: #e86881;
    padding: 9px 8px;
    right: 0px;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 400;
}

.change-account {
    display: none;
    z-index: 105;
    position: absolute;
    left: -20px;
    top: 49px;
    width: 300px;
    border-radius: 8px;
    background: #2f3a51;
    padding: 20px 25px 15px;
    border: 1px solid #47536d;
}
.change-account.show {
    display: block;
}
.change-account ul {
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin: 10px 0 20px;
    width: 100%;
}
.change-account ul li {
    margin: 10px 0;
    display: block;
    float: left;
    width: 100%;
}
.letters-round {
    text-align: center;
    font-weight: 500;
    padding: 5px 5px 5px;
    font-size: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    color: #00cbaf;
    background: #ffffff;
    float: left;
}
.account-name {
    position: relative;
    padding-left: 20px;
    margin-top: 4px;
    float: left;
}
.account-name i.star {
    position: absolute;
    top: -3px;
    right: -35px;
    color: #f2c94c;
}
i.done {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    float: right;
    color: #00cbaf;
}
.change-account .btn-primary {
    width: 100%;
}
.bg-overlay {
    z-index: 101;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

/** Sidebar*/

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 71px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar .nav-item .nav-link.actived,
.sidebar .nav-item:hover .nav-link,
.sidebar .nav-item:focus .nav-link {
    color: #00cbaf;
    opacity: 1;
}

.sidebar .nav-item .nav-link.actived svg path,
.sidebar .nav-item:hover .nav-link svg path,
.sidebar .nav-item:focus .nav-link svg path {
    fill: #00cbaf;
    opacity: 1;
}

.sidebar .nav-item:hover .nav-link,
.sidebar .nav-item:focus .nav-link {
    color: #00cbaf;
    opacity: 1;
    background: #2f3a51;
}

nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow: visible;
    width: 82px !important;
}
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    margin: 61px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #394662;
}
.sidebar .nav-item > .nav-link {
    text-align: center;
    padding: 20px 1px;
    width: 100%;
}
.sidebar .nav-item .nav-link span {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 400;
    display: block;
}
.sidebar .nav-item {
    position: relative;
}
.sidebar .submenu {
    display: none;
    position: absolute;
    left: 82px;
    top: 0px;
    list-style: none;
    width: 240px;
    border-radius: 0px 5px 5px 0;
    background: #2f3a51;
    padding: 15px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    text-align: left;
}
.sidebar .nav-item:hover .submenu {
    display: block;
}

.sidebar .submenu-item {
    padding: 10px 20px;
    width: 100%;
    color: #ffffff;
    float: left;
    text-decoration: none;
    border-radius: 0px;
}
.sidebar .submenu-item:hover {
    background: #424d65;
    opacity: 1;
    border-radius: 5px;
    color: #ffffff;
}

.navbar .nav-item .profile-drop {
    display: none;
    position: absolute;
    right: -10px;
    top: 55px;
    list-style: none;
    width: auto;
    border-radius: 8px;
    background: #2f3a51;
    padding: 7px 30px 14px;
    border: 1px solid #47536d;
    max-width: 400px;
}
.navbar .nav-item .profile-drop li {
    width: 100%;
    float: left;
    padding: 15px 0;
    cursor: pointer;
}
.navbar .nav-item .profile-drop li a {
    text-decoration: none;
    display: flex;
    width: 100%;
}
.navbar .nav-item .profile-drop li a img {
    width: 24px;
}
.profile-drop .profile-link img {
    margin-top: 10px;
}
.navbar .nav-item .profile-drop li a .text {
    padding-left: 20px;
    margin-top: 3px;
}

.navbar .nav-item .profile-drop li a h5 {
    color: #ffffff;
    width: 100%;
    font-size: 16px;
    margin: 0;
}
.navbar .nav-item .profile-drop li a p {
    font-size: 14px;
    margin: 0;
    color: #828fab;
}

/* login */
.loginf {
    color: #e86881;
    margin-top: 15px;
}
.login-form {
    padding-top: 70px;
    position: relative;
    height: 100vh;
    display: table;
    background: #ffffff;
    text-align: left;
    padding-bottom: 70px;
}
.login-form .form-group {
    margin-bottom: 10px;
}
.login-form::before {
    position: fixed;
    content: "";
    bottom: -485px;
    right: -535px;
    width: 900px;
    height: 900px;
    border-radius: 500px;
    border: 150px solid #f9f9f9;
}

.login-form a.link {
    color: #00cbaf;
}
.login-form .forgot {
    margin: 10px 0 20px;
    float: left;
}
.login-form .form-control {
    padding: 1.5rem 1rem;
    border: 1px solid #e8e8e8;
}
.login-form .form-control:focus {
    border: 1px solid #08a58f;
}
.login-form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
}
.login-form .custom-control-label:before {
    background-color: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 3px;
    top: 0.2rem;
    height: 1rem;
    width: 1rem;
}
.login-form .custom-control-label:after {
    width: 1rem;
    height: 1rem;
    top: 3px;
    left: -1.5rem;
}
.login-form .form-check {
    padding-left: 0px;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00cbaf;
    background-color: #00cbaf;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    opacity: 0.8;
    background-color: #00cbaf;
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::after {
    opacity: 0.8;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
    color: #ffffff;
    opacity: 0.8;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #242d41;
    opacity: 0.75;
}
.login-form .sign-up,
.login-form .btn-primary {
    width: 100%;
    padding: 12px 0px;
    text-transform: uppercase;
    font-weight: 500;
}
.login-box {
    display: table-cell;
    vertical-align: middle;
}
.login-table {
    padding-left: 150px;
    text-align: left;
    color: #2f3a51;
    position: relative;
    width: 59%;
    min-width: 500px;
    float: left;
}

.login-text {
    text-align: left;
    color: #2f3a51;
    margin-bottom: 30px;
}
.login-text h4 {
    font-weight: 600;
    font-size: 30px;
}

/* main page */

.page-head {
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    padding-top: 13px;
    padding-bottom: 13px;
    background: #232e44;
    z-index: 9;
    margin-left: 82px;
    margin-bottom: 20px;
}
.page-head .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
}

.page-content .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

.page-content {
    margin-left: 85px !important;
    margin-top: 20px !important;
    padding-bottom: 30px;
}

.page-content .form-control,
.page-head .form-control {
    background: #242d41;
    background-color: #242d41 !important;
    border-color: #36425c;
    color: #ffffff;
}

.form-control input[type="tel"] {
    background-color: #242d41 !important;
    color: #ffffff;
}
.form-control input[type="tel"]:disabled {
    background-color: #242d41 !important;
    color: #a2a2a2;
}

.page-content .row {
    margin-left: -5px;
    margin-right: -5px;
}

.page-content .no-checkbox {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: -5px;
}

.page-content .form-control:focus {
    border-color: #08a58f;
}
.page-content .input-group-text {
    background: #242d41;
    border-color: #36425c;
}

.page-content .refresh-zone {
    border: 1px solid rgba(235, 87, 87, 1);
    border-radius: 4px;
    padding-top: 1em;
    text-align: left;
    width: 350px;
    padding-left: 1em;
    flex-direction: column;
}
.page-content .refresh-zone .header {
    color: rgba(235, 87, 87, 1);
    font-weight: 500;
}
.page-content .refresh-zone .btn-refresh {
    cursor: pointer;
}

.widget-box.temperature,
.widget-box.light,
.widget-box.humidity,
.widget-box.gateway-storage,
.widget-box.gateway-status {
    height: 350px;
    margin-bottom: 10px;
}
.widget-box.alert-count {
    height: 350px;
    margin-bottom: 10px;
}

.widget {
    width: 100%;
    background: #2f3a51;
    border-radius: 6px;

    padding: 2px;
    position: relative;
    height: 100%;
}
.widget .title {
    padding-top: 25px;
    padding-left: 25px;
}
.widget .title h5 {
    margin-bottom: 2px;
}
.widget .title p {
    margin: 0;
    color: #828fab;
    font-size: 14px;
}

.widget .chart-box {
    height: 100% !important;
}
.alert-tab {
    position: absolute;
    top: 120px;
    left: 30px;
}
.alert-tab .btn-primary {
    font-size: 13px;
    padding: 4px 10px;
    background: #1f2738;
    border-color: #1f2738;
    color: #828fab;
}
.alert-tab .btn-primary.selected {
    background: #00cbaf;
    border-color: #00cbaf;
    color: #ffffff;
}
.dash-more .dropdown-toggle::after {
    display: none;
}
.dash-more .btn {
    padding: 6px 10px;
    line-height: 0;
}
.more-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #6f82aa;
    cursor: pointer;
}
.more-icon:after {
    display: none;
}
.last-data {
    position: absolute;
    right: 25px;
    top: 43px;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}
.last-data:hover {
    color: #08a58f;
}

.restricted-chart {
    position: absolute;
    bottom: 50%;
    text-align: center;
    width: inherit;
    height: 20px;
    color: #ffffff;
    z-index: 3;
    font-size: 14px;
    font-weight: 300;
}
.custom-range-info {
    position: absolute;
    top: 75px;
    right: 28px;
    width: 163px;
    height: 20px;
    line-height: 1.2;
    color: #828fab;
    z-index: 1;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
}

.widget-tool {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 12px;
    right: 25px;
}

.aggregate-icon {
    width: 25px;
    cursor: pointer;
    z-index: 5;
}

.aggregate-icon:hover {
    opacity: 0.75;
}

.refresh-drop {
    height: 25px;
    color: #ffffff;
    z-index: 3;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    bottom: 12px;
    right: 25px;
}

.widget-tool .refresh-drop {
    position: initial;
}

.refresh-rate {
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}
.refresh-rate:hover {
    color: #08a58f;
}
.custom-refresh {
    padding: 0 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.custom-refresh.disabled {
    opacity: 0.8;
}
.custom-refresh .form-control {
    background: #f0f0f0;
    border-color: #f0f0f0;
    padding: 0px 10px;
}
.custom-refresh .btn {
    padding: 0 10px;
}
.custom-refresh .form-label {
    font-size: 13px;
    font-weight: 400;
    color: #08b39b;
}
.custom-refresh .material-icons {
    font-size: 13px;
}
.page-title {
    position: relative;
    float: left;
    margin-bottom: 25px;
    margin-right: 20px;
    margin-top: 5px;
}
.page-title i {
    color: #00cbaf;
    margin-top: 0px;
    margin-left: 10px;
    float: right;
}
.page-head .page-title {
    margin-bottom: 5px;
}
.search {
    width: 250px;
}
.search .form-control {
    background: #242d41;
    border-color: #36425c;
}
.search .form-control:focus {
    border: 1px solid #08a58f;
}
.search .btn {
    padding: 0px 10px;
}
.search .btn .material-icons {
    margin-top: 5px;
    font-size: 20px;
}

.cstm-table .table-head {
    border-top: 1px solid #2f3a51;
    border-bottom: 1px solid #2f3a51;
    padding: 15px 0;
    display: inline-block;
    width: 100%;
}
.table-head .checkmark,
.table-row .checkmark {
    margin-left: 20px;
}
.cstm-table .table-row {
    padding: 15px 0;
    background-color: #2f3a51;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    word-break: break-all;
}
.cstm-table .table-row i.right {
    float: right;
    margin-right: 20px;
    color: #00cbaf;
}
.cstm-table .table-row i.circle {
    font-size: 12px;
    margin-right: 10px;
}
.cstm-table .table-row i.circle.online {
    color: #219653;
}
.cstm-table .table-row i.circle.offline {
    color: #f2495e;
}
.cstm-table .table-row .reading {
    color: #00cbaf;
}
.cstm-table .table-row .reading-label {
    display: none;
}
.cstm-table .table-row span.default {
    color: #828fab;
}
.cstm-table .table-row span.custom {
    color: #00cbaf;
}
.cstm-table .table-row .btn {
    padding: 1px 10px;
}
.cstm-table .custom-control-label {
    margin-left: 15px;
}
.cstm-table .table-row .standalone {
    opacity: 0.25;
}

.change-dashboard {
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
    line-height: 1.3;
}
.change-dashboard:after {
    display: none;
}
.change-dashboard:hover,
.change-dashboard:focus {
    text-decoration: none;
    color: #ffffff;
    box-shadow: none;
}
.dashboard-drop {
    padding: 25px;
    background: #2f3a51;
    z-index: 103;
    border-radius: 5px;
    width: 320px;
}
.dashboard-drop.show {
    display: block;
}
.dashboard-drop .btn-primary {
    width: 100%;
}
.dashboard-drop .search {
    margin-top: 10px;
    width: 100%;
}
.dashboard-drop .search .form-control {
    padding-left: 15px;
}

.dashboard-list {
    list-style: none;
    padding-left: 0;
    float: left;
    width: 100%;
    margin-top: 15px;
    max-height: calc(100vh - 40vh);
    overflow-y: auto;
    overflow-x: hidden;
}
.dashboard-list .dropdown-item {
    margin: 10px 0;
    float: left;
    width: 100%;
    padding: 0;
}
.dashboard-list .dropdown-item:hover {
    background: none;
    opacity: 1;
}
.dashboard-list .dropdown-item:hover h6 {
    color: #00cbaf;
}
.dashboard-list li a {
    text-decoration: none;
}
.dashboard-list .dash-icon {
    border-radius: 50px;
    float: left;

    text-align: center;
    background-color: rgb(57 70 98);
}
.dashboard-list .dash-icon svg {
    padding: 10px;
    width: 40px;
    height: 40px;
}

.form-box {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.form-box-head {
    padding: 20px 30px 15px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
}
.form-box-head + .form-box {
    border-radius: 0;
}
.form-box-head h5 {
    margin-top: 6px;
}
.form-box-head h5,
.form-box-head h6,
.form-box-head p {
    margin-bottom: 0;
}
.form-box-head p {
    color: #828fab;
}

.form-box-footer {
    padding: 20px 30px 20px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
}

.form-box .custom-select {
    color: #ffffff;
    border-color: #36425c;
    background-color: #242d41;
}
.form-box hr {
    border-color: #3e4b67;
}

.form-box.permission {
    padding: 0px;
    background: #3a4763;
    overflow: hidden;
    margin-bottom: 10px;
}
.form-box.permission .permission-box {
    padding: 20px 30px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: #ffffff;
    position: relative;
}
.form-box.permission .permission-box i.down {
    position: absolute;
    right: 30px;
    top: 35%;
    color: #00cbaf;
}
.show .form-box.permission .permission-box i.down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.form-box.permission p {
    color: #828fab;
    font-size: 14px;
    margin-top: 5px;
    font-style: italic;
    margin-bottom: 0;
}
.permission-item {
    background: #2f3a51;
    padding: 20px 30px;
    border-bottom: 1px solid #34435f;
}
.permission-item:last-child {
    border-bottom: none;
}
.permission-item h6 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 2px;
}
.recipient-option {
    border-bottom: 1px solid #3e4b67;
    border: 1px solid #3e4b67;
    border-radius: 5px;
    padding: 20px;
    transition: ease 0.5s;
}
.recipient-option.disabled {
    opacity: 0.5 !important;
}
.recipient-option.disabled:hover {
    pointer-events: none !important;
}
.recipient-option .custom-switch {
    padding-left: 40px;
}
.recipient-option label {
    cursor: pointer;
}
.recipient-option.show {
    border-radius: 5px 5px 0 0;
    transition: ease 0.5s;
}
.recipient-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    display: none;
    transition: all 0.5s;
}
.recipient-box.show {
    display: block;
    transition: all 0.5s;
}
.recipient-box .added-item {
    float: left;
    padding: 10px 20px;
    padding-right: 12px;
    background: #3a4762;
    border-radius: 10px 15px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.recipient-box .added-item span {
    float: right;
    color: #f2495e;
    font-size: 20px;
    margin-left: 7px;
    margin-top: 1px;
    cursor: pointer;
}
.recipient-box .added-item.sendgateway {
    border-radius: 10px;
    padding: 15px 20px;
    padding-right: 12px;
}
.recipient-box .added-item.sendgateway .gate-name {
    float: left;
}
.recipient-box .added-item.sendgateway h6 {
    margin-bottom: 0;
    font-weight: 400;
}
.recipient-box .added-item.sendgateway p {
    margin-bottom: 0;
}
.recipient-box .added-item.sendgateway .gatewayuuid {
    font-size: 13px;
    color: #ffffff;
    opacity: 0.5;
    float: left;
    margin-left: 0;
}
.recipient-box .added-item.sendgateway span {
    margin-left: 15px;
    margin-top: 10px;
}
.sensor-summary {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
}
.sensor-summary p {
    margin: 0;
    color: #828fab;
}
.sensor-summary h2 {
    color: #00cbaf;
}
.sensor-summary .sensor-info p {
    margin: 15px 0px 0 20px;
}
.sensor-summary p span {
    color: #ffffff;
    font-weight: 500;
}

.btn-chart-type {
    padding: 10px 15px;
}
.btn-chart-type svg {
    margin-right: 10px;
}

/* empty */
.empty-widget {
    padding: 150px 0;
    width: 100%;
    background: #2f3a51;
    border-radius: 6px;
    margin-bottom: 10px;
}
.empty-widget h5 {
    color: #828fab;
}

/* colors */
.bg-red {
    background-color: #f2495e;
}
.bg-orange {
    background-color: #f2994a;
}
.bg-yellow {
    background-color: #f2c94c;
}
.bg-green {
    background-color: #219653;
}
.bg-blue {
    background-color: #2f80ed;
}
.bg-sky {
    background-color: #56ccf2;
}
.bg-magenta {
    background-color: #bb6bd9;
}
.bg-magenta2 {
    background-color: #41b2af;
}
.bg-blue2 {
    background-color: #41b2af;
}
.bg-pink {
    background-color: #df54a1;
}
.bg-green2 {
    background-color: #82c771;
}

.create-widget-option {
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 35px;
    color: rgb(47, 58, 81);
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    border: 2px solid rgb(234, 234, 234);
}
.select-widget-type .modal-dialog {
    max-width: 350px;
}
.modal-body.widget-options {
    padding: 50px;
}
.modal-header .close-button {
    float: right;
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1;
    color: #fff;
    opacity: 1;
    padding: 0;
}

.modal-body {
    padding: 1.4rem;
}
.modal-body h4 {
    font-size: 18px;
    color: #2f3a51;
}
.modal-body > * {
    margin: 0.25rem;
}
.modal-footer {
    border-top: none;
    padding: 1rem 1.4rem;
    box-shadow: -4px -1px 14px 3px rgba(0, 0, 0, 0.1);
}
.modal-footer .btn {
    padding: 6px 20px;
    font-weight: 500;
}
.modal-dialog .form-control {
    background: #f0f0f0;
    border-color: #f0f0f0;
    padding: 20px 20px;
    color: #2f3a51;
}
.modal-dialog .dash-icon {
    border-radius: 50px;
    float: left;
    margin: 15px 40px 15px 0;
    position: relative;
    cursor: pointer;
    text-align: center;
}
.modal-dialog .dash-icon .done {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: #00cbaf;
    padding: 5px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 50px;
    border: 2px solid #ffffff;
}
.modal-dialog form .icons .dash-icon:nth-child(4n) {
    margin-right: 0px;
}
.modal-dialog .dash-icon svg {
    padding: 15px;
    width: 55px;
    height: 55px;
}
.new-dash .btn-create {
    margin-top: 50px;
    font-weight: 500;
    padding: 12px 20px;
}
.new-dash .modal-dialog .form-control {
    padding: 25px 20px;
}
.new-dash .modal-footer .btn {
    padding: 13px 20px;
    font-weight: 500;
}
.modal-option {
    padding: 4px 10px;
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    margin: 5px 0;
    width: 100%;
    float: left;
}
.modal-option.disabled {
    opacity: 0.5;
}

.modal-option.disabled:hover {
    opacity: 0.5 !important;
    pointer-events: none;
}

.modal-option .custom-radio,
.modal-option .custom-checkbox {
    padding: 6px 30px;
    font-size: 16px;
    float: left;
    width: 100%;
    cursor: pointer;
}
.modal-option .custom-control-label {
    color: #2f3a51;
    padding-left: 12px;
    width: 100%;
    cursor: pointer;
}
.modal-option .custom-control-label:before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}
.modal-option .custom-control-label:before,
.modal-option .custom-control-label:after {
    top: 28%;
}
.modal-option h4 {
    font-weight: 400;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-option .select-gw-name {
    font-size: 16px;
}

.modal-option .select-gw-id {
    font-size: 12px;
}

.modal-option p {
    color: #828fab;
    opacity: 0.75;
    margin-bottom: 0;
}

.condition-options-list .modal-option:hover {
    cursor: pointer;
}

.modal-body .scroll-list {
    max-height: 65vh;
    overflow-y: auto;
    width: 100%;
}

.modal-body .scroll-multiple-list {
    margin-top: 6px;
    max-height: 65vh;
    overflow-y: auto;
    width: 100%;
}

.modal-body .scroll-multiple-list > div {
    padding-top: 5px;
    display: inline-block;
    width: inherit;
    margin-bottom: 12px;
}

.modal-body .scroll-multiple-list > div > h4 {
    margin-bottom: 0px !important;
}

.modal-body .scroll-list .custom-control-label:before {
    width: 22px;
    height: 21px;
}
.modal-body .scroll-list .custom-control-label:after {
    width: 21px;
    height: 21px;
}
.no-header .modal-content {
    border-radius: 12px;
}
.modal h3 {
    color: #2f3a51;
    font-size: 25px;
}
.modal p,
.modal div[role="alert"] {
    color: #2f3a51;
}
.modal-body > .btn + .btn {
    margin: 0.25rem;
}
.modal-icon-box {
    margin-top: -65px;
    margin-bottom: 25px;
}
.modal-icon-box span {
    color: #ffffff;
    font-size: 25px;
    padding: 20px;
    border-radius: 52px;
    background: #00cbaf;
}
.danger .modal-icon-box span {
    background: #f2495e;
}

.page-tabs .nav {
    background: #212b40;
    padding: 0 30px;
}
.page-tabs .nav-item {
    width: 250px;
}
.page-tabs .nav-item .nav-link {
    text-align: center;
    padding: 20px 10px;
    position: relative;
    transition: all 0.5s;
}
.page-tabs .nav .nav-item .nav-link .count {
    font-size: 14px;
    font-weight: 400;
    padding: 12px 8px;
    background: #2f3a51;
    border-radius: 50%;
    margin-left: 15px;
    color: #828fab;
    width: 40px;
    height: 40px;
    display: inline-block;
}
.page-tabs .nav-item .nav-link.active .count {
    background: #00cbaf;
    color: #ffffff;
}
.page-tabs .nav-item .nav-link:hover {
    opacity: 1;
}
.page-tabs .nav-item .nav-link h6 {
    margin: 0;
    font-size: 18px;
}
.page-tabs .nav-item .nav-link.active {
    background: #171f30;
    color: #00cbaf;
    position: relative;
    transition: all 0.5s;
}
.page-tabs .nav-item .nav-link.active:after {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #00cbaf;
    top: -5px;
    left: 0;
    content: "";
    transition: all 0.5s;
}

.current-data {
    position: absolute;
    z-index: 2;
    left: 15px;
    top: 40px;
    pointer-events: none;
}
.current-data-fw {
    position: absolute;
    z-index: 2;
    left: 2%;
    top: 10%;
}

.loader-gif {
    position: absolute;
    z-index: 3;
    top: 60%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50px;
}

.live-data-text {
    display: flex;
    margin-bottom: 0px;
}

.live-data-time {
    color: #828fab;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
    margin-top: 1.5px;
}

.modal-select-items {
    width: 100%;
    border: 2px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 3%;
}
.modal-radio-button {
    display: inline-block;
    margin: 1.5%;
}
.modal-selection-items-text {
    display: inline-block;
    margin: 2.5%;
}

.label-select-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #2f3a51;
    align-items: center;
}
.label-selection-subtext {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #828fab;
}

.select-items {
    font-family: "Roboto", sans-serif;
    width: 100%;
    background: rgba(0, 203, 175, 0.1);
    border: 2px solid #00cbaf;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    padding: 5px;
    float: left;
}
.select-items span {
    top: 32%;
    right: 15px;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
}
.select-items-text {
    color: #ffffff;
    font-size: 16px;
    align-items: center;
    mix-blend-mode: normal;
    line-height: 19px;
    margin: 10px;
}
.select-items-subtext {
    color: #ffffff;
    font-size: 12px;
    mix-blend-mode: normal;
    line-height: 14px;
    margin: 10px;
    opacity: 0.75;
}
.switch-drop .dropdown-item h6 {
    width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 30px 0px 0px;
    float: left;
    color: #ffffff;
    position: relative;
}

.switch-drop .dropdown-item h6.admin span.star {
    color: #f2c94c;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 19px;
    display: block;
}

.cstm-table .table-row .btn-remove {
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 0;
    color: #f2495e;
}

.dashboard-list h6 {
    width: calc(100% - 60px);
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    float: left;
    margin: 10px 0;
    margin-left: 15px;
    color: #ffffff;
}

.selected-dashboard-name {
    float: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.org-name {
    margin-top: 15px;
    margin-left: 15px;
}
.table-head .group-item .group-item-name,
.table-row .group-item .group-item-name {
    padding-left: 30px;
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-balance-details {
    margin-top: 15px;
    margin-right: 15px;
}
.header-balance-details p {
    font-size: 14px;
    color: #828fab;
    margin-bottom: 0;
}
.header-balance-details h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: -2px;
    text-align: left;
    color: #ffffff;
}
.header-balance-details h5 span {
    color: #00cbaf;
}

.new-sensor-chart .sensor-details-item {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    height: 60px;
}
.new-sensor-chart .sensor-details-item h6 {
    margin: 0;
    font-weight: 400;
    line-height: 60px;
}
.new-sensor-chart .sensor-details-item .unit-drop {
    float: right;
    width: 100px;
    margin-top: 10px;
}
.new-sensor-chart .sensor-details-item .color-selection {
    float: right;
    margin-top: 10px;
    background: #242d41;
    border-color: #36425c;
    padding: 6px 10px;
}
.new-sensor-chart .sensor-details-item .color-selection:focus,
.new-sensor-chart
    .sensor-details-item
    .color-selection.btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: none;
    background: #242d41;
    border-color: #36425c;
}
.new-sensor-chart .sensor-details-item .color-selection .selected-color {
    margin: 2px;
    width: 60px;
    height: 20px;
    float: left;
    border-radius: 2px;
}
.new-sensor-chart .sensor-details-item .color-option {
    width: 100%;
    height: 25px;
    border-radius: 3px;
}
.new-sensor-chart .sensor-details-item .dropdown {
    float: right;
}
.new-sensor-chart .sensor-details-item .dropdown-menu {
    min-width: 100px;
}
.new-sensor-chart .sensor-details-item .dropdown-item {
    padding: 0.25rem 0.5rem;
}
.new-sensor-chart .sensor-details-item .alert-switch {
    float: right;
}
.new-sensor-chart .sensor-details-item .alert-switch .custom-control-label {
    padding: 6px;
    margin-top: 15px;
}
p.add-sensor-details {
    float: left;
    color: #828fab;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 15px;
}
.add-new-chart {
    float: left;
}
.new-sensor-chart .sensor-title .sensor-close {
    float: right;
    color: #f2495e;
    cursor: pointer;
}

/* daterangepicker  */
.daterangepicker {
    color: black !important;
}

.daterangepicker td.in-range {
    background-color: rgba(0, 203, 175, 0.1) !important;
}

.daterangepicker td.active {
    background-color: rgba(0, 203, 175, 1) !important;
}

.daterangepicker .ranges {
    overflow-y: scroll;
    max-height: 250px;
}

.daterangepicker .ranges li.active {
    background-color: rgba(0, 203, 175, 1) !important;
}

fieldset[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-gray {
    color: #2f3a51;
    color: var(--create-chart-form-secondary);
}

.text-gray-important,
.text-gray-important label {
    color: #2f3a51 !important;
    color: var(--create-chart-form-secondary) !important;
}

.color-picker__toggle,
.unit-picker__toggle {
    min-width: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: unset;
    padding: 10px !important;
    border: none;
    box-shadow: none !important;
}

.color-picker__toggle > div {
    width: 32px;
    height: 17px;
}

.color-picker .dropdown-menu {
    min-width: unset !important;
}

.color-picker .dropdown-menu .dropdown-item {
    padding: 0.25rem 0.5rem;
}

.color-picker__input {
    width: 6rem !important;
}

.unit-picker .dropdown-menu {
    background-color: #2f3a51;
    background-color: var(--create-chart-form-secondary);
}

.unit-picker__input {
    color: white;
    border: none;
}

.unit-picker .dropdown-item:hover {
    background-color: #2f3a51;
    background-color: var(--create-chart-form-secondary);
    text-decoration: none;
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multiple-chart-block {
    background-color: #394662;
    background-color: var(--create-chart-from-background);
}

.multiple-chart-block .row {
    position: relative;
}

.multiple-chart-block--delete {
    position: absolute;
    color: #f2495e;
    top: 0;
    right: 0;
    cursor: pointer;
}

.multiple-chart-block + .multiple-chart-block {
    margin-top: 1rem;
}

.multiple-chart-block .form-control {
    background-color: #2f3a51;
    background-color: var(--create-chart-form-secondary);
}

.multiple-chart-block__select-radio {
    padding: 1rem 2.5rem;
    border: 2px solid #2f3a51;
    border: 2px solid var(--create-chart-form-secondary);
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.multiple-chart-block__select-radio .custom-control-label {
    padding-left: 10px;
}

.multiple-chart-block hr {
    border-color: #2f3a51;
    border-color: var(--create-chart-form-secondary);
}

.multiple-chart-block__select-radio--active {
    border: 2px solid #00cbaf;
    border: 2px solid var(--create-chart-form-active);
}

.multiple-chart-block__panel {
    background-color: #252e42;
    background-color: var(--create-chart-multiple-sensor-background);
}

.multiple-chart-block__panel .multiple-chart-label-wrapper {
    max-width: 80px;
}

#toggle-nav-dropdown {
    opacity: 1 !important;
}

@media (max-width: 576px) {
    .multiple-chart-block .delete-block {
        justify-content: flex-end;
        padding-top: 1rem;
    }

    .multiple-chart-block .delete-block img {
        position: relative;
    }
}

.update-chart-panel .actuator-chart-label-wrapper {
    max-width: 110px;
}

#dashboard-grid.grid-stack .widget-box {
    width: 100%;
    height: 400px !important;
    max-width: 100%;
}

.fs-38 {
    font-size: 38px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-action {
    display: flex;
}

.dashboard-nav {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.panel-card-error {
    font-size: 14px;
    color: red;
}

.new-dash .dashboard-name-error-msg {
    margin-top: -30px;
    margin-bottom: 10px;
}

.dashboard-color-error-msg {
    clear: both;
    color: #dc3545 !important;
    font-size: 80%;
}

.chart-col {
    background-color: #242d41 !important;
    background-color: var(--create-chart-deep-button-background) !important;
    outline: #36425c solid 1px !important;
    outline: var(--create-chart-deep-button-border) solid 1px !important;
}

.unit-picker-toggle {
    background-color: #2f3a51 !important;
    background-color: var(--create-chart-form-secondary) !important;
}

.color-picker .color-picker-dropdown-menu {
    background-color: transparent;
    padding: 0;
}

:root {
    --btn-mute-bg: #242d41;
    --btn-mute-outline: #36425c;
    --btn-mute-active: #00cbaf;
    --checkbox-color: #dddddd;
}

h6.ginfo,
div.ginfo {
    color: #828fab;
}

.gateway-header-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
}
.gateway-details-box {
    background: #2f3a51;
    padding: 40px;
    border-radius: 5px;
}
.gateway-ports {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    justify-content: center;
    grid-gap: 10px;
}

.gateway-port {
    height: 100%;
    background-color: #3e4b67;
    border: 1px solid #00cbaf;
    border-radius: 5px;
    min-width: 220px;
    min-height: 100px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.gateway-port .top {
    justify-content: space-between;
    flex: 1 1;
}

.gateway-port .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #242d41;
    padding-top: 10px;
    flex: 1 1;
}

.gateway-port .top i {
    float: right;
    color: #00cbaf;
}

.gateway-info h6.ginfo {
    color: #828fab;
    margin-bottom: 0;
    word-break: break-all;
}
.gateway-info i.circle,
.gateway-detail-item.bottom i.circle,
.gateway-port .bottom i.circle {
    font-size: 12px;
    margin-right: 10px;
}
.gateway-info i.circle.online,
.gateway-detail-item.bottom i.circle.online,
.gateway-port .bottom i.circle.online {
    color: #219653;
}
.gateway-info i.circle.offline,
.gateway-detail-item.bottom i.circle.offline,
.gateway-port .bottom i.circle.offline {
    color: #f2495e;
}
.gateway-details-tabs {
    background: #2f3a51;
    border-radius: 5px;
}
.gateway-details-tabs .nav-item {
    width: 20%;
    text-align: center;
}
.gateway-details-tabs .nav-item .nav-link {
    padding: 20px 0;
    border-bottom: 1px solid #3e4b67;
    position: relative;
}

.gateway-details-tabs .nav-item .nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 2px;
    background: #00cbaf;
    transition: 0.5s;
}
.gateway-details-tabs .nav-item:hover .nav-link {
    opacity: 1;
}
.gateway-details-tabs .nav-item .nav-link.active::after {
    width: 100%;
}
.gateway-details-tabs .nav-item .nav-link h6 {
    margin-top: 10px;
    margin-bottom: 0;
}
.gateway-details-tabs .nav-item .nav-link.active h6 {
    color: #00cbaf;
}
.gateway-details-tabs .tab-pane {
    padding: 30px;
}

.storage-box {
    border-right: 1px solid #3e4b67;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.storage-box h5 {
    margin-bottom: 40px;
}

.percentage-circle {
    position: relative;
    margin: auto;
}
.percentage-circle__circle {
    stroke-dashoffset: 0;
    -webkit-transform-origin: 50%;
            transform-origin: 50%;
    position: absolute;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}
.percentage-circle__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.percentage-circle-info {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100px;
    height: 50px;
}
.percentage-circle-info h5 {
    margin-bottom: 0;
    font-weight: 400;
}
.percentage-circle-info p {
    font-size: 15px;
    color: #828fab;
}
.storage-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.storage-button .btn {
    width: 200px;
}

.update-info {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 40px;
}
.update-info .gateway-info {
    width: 100%;
}
.update-option {
    padding: 10px;
    background: #3a4763;
    border-radius: 5px;
    margin: 5px 0;
    width: 100%;
    float: left;
}
.update-option .custom-radio,
.update-option span {
    padding: 6px 30px;
    font-size: 16px;
    float: left;
}
.update-option .custom-control-label {
    padding-left: 10px;
    width: 250px;
}
.descriptor-item {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    padding: 15px 0;
}
.descriptor-item h6 {
    margin: 0;
}

.download-data-box {
    text-align: left;
    padding: 30px;
}
.download-data-box .btn,
.download-sensor-data .btn {
    padding: 12px;
}
.download-data-box .btn img,
.download-sensor-data .btn img {
    margin-right: 5px;
}
.download-data-option {
    padding: 20px 20px;
    background: #3e4b67;
    border-radius: 5px;
    margin-bottom: 10px;
}
.descriptor-box {
    padding-bottom: 80px !important;
}

.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-adornedEnd,
.css-1bn53lx,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-i4bv87-MuiSvgIcon-root,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.MuiSvgIcon-root {
    color: #828fab !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 15px !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.MuiFormControl-root,
.MuiTextField-root,
.css-i44wyl {
    background: #242d41 !important;
    flex: 1 1 !important;
}

.dateTimeFieldWrapper {
    width: 100%;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 1rem;
}

.dateTimeFieldWrapper.inline {
    width: 50%;
    flex-direction: column;
    padding-right: 1rem;
}

.dateTimeFieldWrapper:nth-child(2) {
    padding-right: 0;
}

.dateTimeField {
    line-height: 55px;
    flex-basis: 50px;
    margin-left: 32px;
}

.dateTimeFieldWrapper.inline .dateTimeField {
    margin-left: 0;
    color: #2f3a51;
}

.dateTimeFieldWrapper.inline .MuiFormControl-root {
    background: #f0f0f0!important;
    border-radius: 4px;
}

.dateTimeFieldWrapper.inline .MuiFormControl-root .MuiOutlinedInput-root input, 
.dateTimeFieldWrapper.inline .MuiFormControl-root .MuiOutlinedInput-root svg {
    color: #4c4c4c!important;
}

.sensor-association-box .form-box {
    border-radius: 5px 5px 0 0;
}
.sensor-association-box .form-row {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 20px;
}
.sensor-association-box .form-row:first-child,
.sensor-association-box .form-row:last-child {
    border-bottom: none;
}
.sensor-association-box h6 {
    font-weight: 400;
}
.sensor-association-box p {
    margin: 0;
    color: #828fab;
}
.sensor-association-box .selected-sensor {
    padding: 20px;
    background: #3a4763;
    margin-top: 10px;
    border-radius: 5px;
}

.cstm-table .table-row .btn.override {
    background: rgba(0, 203, 175, 0.5);
    border: 1px solid #00cbaf;
    padding: 3px 18px;
    font-size: 14px;
}

.actuator-summary {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.actuator-summary p {
    margin: 0;
    color: #828fab;
}
.actuator-summary h2 {
    color: #00cbaf;
    float: left;
}
.actuator-summary .sensor-info {
    float: left;
}
.actuator-summary .sensor-info p {
    float: left;
    margin: 15px 0px 0 20px;
}
.actuator-summary .sensor-info h5 {
    margin-bottom: 0;
}
.actuator-summary p span {
    color: #ffffff;
    font-weight: 500;
}
.actuator-summary .reading {
    color: #ffffff;
    font-weight: 400;
}
.actuator-summary i {
    font-size: 12px;
    margin-right: 10px;
}
.actuator-summary i.circle.online {
    color: #219653;
}
.actuator-summary i.circle.offline {
    color: #f2495e;
}
.auto-mode {
    border-top: 1px solid #3e4b67;
    width: 100%;
    float: left;
    margin-top: 25px;
}
.polarity-selection {
    background: #3a4763;
    border-radius: 5px;
    text-align: center;
}
.polarity-selection.custom-control {
    padding-left: 0;
}
.polarity-selection .custom-control-label {
    width: 100%;
    padding: 40px 10px 20px;
}
.polarity-selection .custom-control-label::before {
    left: 15px;
    top: 15px;
}
.polarity-selection .custom-control-label::after {
    left: 15px;
    top: 15px;
}
.polarity-box {
    max-width: 700px;
    min-width: 400px;
    margin-bottom: 10px;
}

.minuteSecondsField {
    min-width: 66px !important;
    max-width: 100px;
}

.minuteSecondsFieldLabel {
    display: flex;
    align-items: center;
}

.btn-remove {
    color: #f2495e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.btn-remove:hover {
    cursor: pointer;
}

.refresh-state {
    -webkit-animation: spin 1000ms linear infinite;
            animation: spin 1000ms linear infinite;
}

.refresh-actuator:hover,
.refresh-sensor:hover,
.bigRefresh:hover {
    cursor: pointer;
}

.gateway-header {
    display: flex;
    justify-content: space-between;
}

.gateway-details-buttons {
    display: flex;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
}

.mini-button {
    padding: 4px 14px;
    height: 35px;
    white-space: nowrap;
}

.btn-mute {
    background-color: #242d41;
    background-color: var(--btn-mute-bg);
    color: #ffffff;
    transition: all 0.2s ease-in-out;
}

.btn-mute:hover {
    color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
}
.btn-mute:active {
    box-shadow: none;
}

.add-prefix-suffix .symbol {
    color: #00cbaf;
    color: var(--btn-mute-active);
    margin-right: 12px;
    font-size: 18px;
}

.open-modal .custom-control-label:before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}

.input-right-text input[type="number"]::-webkit-outer-spin-button,
.input-right-text input[type="number"]::-webkit-inner-spin-button {
    margin-right: 30px;
}

.input-right-text input + span {
    position: absolute;
    right: 20px;
    z-index: 3;
}

.input-right-text .input-group {
    align-items: center;
}

.Toastify__toast-container:has(#update_success) {
    width: 358px;
}

#update_success .Toastify__toast-body {
    align-items: flex-start;
}

#update_success .Toastify__toast-body > div:nth-child(2) {
    font-size: 11px;
    line-height: 16px;
}

#update_success.Toastify__toast--success .Toastify__toast-body svg {
    fill: #00cbaf;
    fill: var(--btn-mute-active);
}

#update_success.Toastify__toast--success
    .Toastify__toast-body
    > div:nth-child(2)::before {
    content: "Success";
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    color: #00cbaf;
    color: var(--btn-mute-active);
}

#update_success .Toastify__progress-bar {
    opacity: 0 !important;
}

.fs-14 {
    font-size: 14px;
}

.scrollingContainer {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.versions-zone .scrollingContainer {
    height: auto;
}

.scrollingContainer::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.scrollingContainer::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}

.scrollingContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #f5f5f511;
}

#lastUpdateDate {
    display: flex;
    justify-content: center;
}

#lastUpdateStatus {
    display: flex;
    justify-content: flex-end;
}

.selectionSubtext {
    font-size: 12px;
    line-height: 1.2rem;
}

.uart-reset {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: flex-end;
    text-align: right;
}

.uart-reset h6 {
    font-weight: 400;
}

.uart-reset .reset-btn {
    width: 128px;
}

.gateway-detail-container {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: (auto, 80px);
    grid-gap: 2rem;
    gap: 2rem;
}

.gateway-detail-bottom-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80px;
    grid-gap: 1rem;
    gap: 1rem;
}

.gateway-detail-item.bottom.last {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ldsuList-row,
.gatewayList-row {
    display: flex;
    width: 100%;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    align-items: center;
}

.ldsuList-row-start {
    flex: 1 1;
}

.ldsuList-row-middle {
    flex: 9 1;
    display: flex;
    align-items: center;
}
.ldsuList-row-end {
    flex: 2 1;
    display: flex;
    align-items: center;
}

.ldsuList-row-ldsu-name {
    flex: 1 1;
}

.ldsuList-row-uuid {
    flex: 1 1;
}

.ldsuList-row-status {
    flex: 1 1;
}

.ldsuList-row-reporting-rate {
    flex: 1 1;
}

.ldsuList-row-termination-status {
    flex: 1 1;
}

.ldsuList-row-delete-action {
    flex: 3 1;
    margin-left: 1rem;
    cursor: pointer;
}
.ldsuList-row-detail-action {
    flex: 1 1;
}

.ldsu-prefix-suffix-input {
    width: 222px;
}

.no-checkbox.ldsuList {
    margin-left: 1rem;
}

.gatewayList-row-middle {
    flex: 10 1;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.gatewayList-row-end {
    flex: 2 1;
    display: flex;
    align-items: center;
}

.gatewayList-row-group-name,
.gatewayList-row-group-members {
    flex: 5 1;
}

.gatewayList-row-name,
.gatewayList-row-uuid,
.gatewayList-row-last-active,
.gatewayList-row-group {
    flex: 2 1;
}

.gatewayList-row-standalone-gateway-name,
.gatewayList-row-standalone-gateway-uuid,
.gatewayList-row-standalone-gateway-last-active {
    flex: 3 1;
}

.gatewayList-row-delete-action,
.gatewayList-row-detail-action {
    flex: 1 1;
}

.ldsbus-buttons-container,
.ldsu-buttons-container {
    display: flex;
    justify-content: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.alert-modal h3 {
    color: #000000;
}

.report-rate-unit,
.analog-config-unit {
    position: absolute;
    top: 8px;
    right: 20px;
}

.no-gateways-text {
    text-align: center;
    border-radius: 4px;
    background-color: #2f3a51;
    padding: 19px 20px 19px 20px;
}

.gateway-ports .refresh-icon {
    cursor: pointer;
}

.gw-group-btn {
    width: 120px;
}

.actuator-modal-error-msg {
    left: 5%;
    position: absolute;
    color: #ff0000;
}

.check-before-configure-msg {
    font-weight: 600;
    color: #2f3a51;
}

.form-box-footer .save-btn {
    width: 120px !important;
}

.select-sensor-error-msg {
    left: 5%;
    position: absolute;
    color: #ff0000;
}

.not-onboard-notification {
    font-size: 18px;
}

.location-box .map-container {
    height: 450px;
    width: 100%;
}

.location-box .edit-mode-button {
    width: 225px;
}

.location-box .loader-gif {
    top: 50%;
}

.sensor-summary .last-active .last-active-date-time {
    font-weight: 400;
}

.recipient-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.mobile-chart-info {
    display: none;
}

.desktop-chart-info {
    display: block;
}

@media (max-width: 1800px) {
    .timeFields {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 1400px) {
    .single-sensor-chart .table-head {
        display: none;
    }

    .single-sensor-chart .gateway-chart-info .row > div {
        height: auto !important;
    }
    
    .single-sensor-chart .gateway-chart-info .custom-checkbox {
        padding: 12px !important;
    }

    .single-sensor-chart .mobile-creating-chart {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .single-sensor-chart .tick-icon {
        align-self: flex-end;
    }

    .single-sensor-chart .mobile-chart-label {
        font-weight: 600;
    }

    .single-sensor-chart .mobile-chart-col {
        height: 30px !important;
    }

    .single-sensor-chart .mobile-chart-col .color-picker__toggle {
        padding: 0 !important;
    }

    .single-sensor-chart .mobile-creating-chart .flex-basis-220px, .single-sensor-chart .mobile-creating-chart .flex-basis-120px {
        flex: none !important;
    }

    .single-sensor-chart .mobile-creating-chart .mobile-chart-col {
        height: auto !important;
    }

    .single-sensor-chart .mobile-chart-info {
        display: block;
    }

    .single-sensor-chart .mobile-chart-info #chart-edit-form {
        width: 100%;
    }
    
    .single-sensor-chart .mobile-chart-info .gateway-chart-info {
        grid-gap: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 992px) {
    .storage-box {
        border-right: 0px;
    }

    .gateway-chart-info .row {
        margin: 0;
    }

    .selected-gateway-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .selected-gateway-info {
        display: flex;
        flex-direction: column;
        border: 1px solid #2F3A51;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 5px;
        padding: 20px;
        grid-gap: 20px;
        gap: 20px;
    }

    .gateway-chart-info {
        padding: 20px !important;
    }

    .selected-gateway-header i {
        padding-right: 0;
    }

    .field-group {
        display: flex;
        justify-content: space-between;
    }
    
    .field-group > * {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mobile-chart-info {
        display: block;
    }

    .desktop-chart-info {
        display: none;
    }    
    
    .mobile-chart-info #chart-edit-form {
        width: 100%;
    }
    
    .mobile-chart-info .gateway-chart-info {
        grid-gap: 10px;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .cstm-table .gateway-chart-info .row > div {
        height: auto !important;
    }
    
    .cstm-table .gateway-chart-info .custom-checkbox {
        padding: 12px !important;
    }

    .mobile-creating-chart {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
        gap: 0.5rem;
    }

    .tick-icon {
        align-self: flex-end;
    }

    .mobile-chart-label {
        font-weight: 600;
    }

    .mobile-chart-col {
        height: 30px !important;
    }

    .mobile-chart-col .color-picker__toggle {
        padding: 0 !important;
    }

    .mobile-creating-chart .flex-basis-220px, .mobile-creating-chart .flex-basis-120px {
        flex: none !important;
    }

    .mobile-creating-chart .mobile-chart-col {
        height: auto !important;
    }
}

@media (max-width: 768px) {
    .gateway-header,
    .uart-config-subheader {
        flex-direction: column;
    }
    .gateway-detail-button {
        display: flex;
        flex-grow: 1;
    }

    .gateway-detail-button button {
        flex: 1 1;
    }

    .mini-button {
        margin-bottom: 0.5rem;
    }

    #lastUpdateDate {
        justify-content: flex-start;
    }

    #lastUpdateStatus {
        justify-content: flex-start;
    }

    .uart-reset {
        justify-content: space-between;
        align-items: end;
        text-align: left;
        font-size: 0.7rem;
    }
}

@media (max-width: 400px) {
    .Toastify__toast-container:has(#update_success) {
        width: 100%;
    }
    .polarity-box {
        margin-bottom: 10px;
    }
}

/* Responsive */
@media (min-width: 1800px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 1799px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 1400px) {
    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }
    .widget-box {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .widget-box.full-width {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .full-option {
        display: none;
    }

    #access-key-button {
        padding: 8px 8px !important;
    }

    .gateway-detail-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 1200px) {
    /* upto xl*/

    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }

    .login-form {
        padding: 50px;
    }
    .login-table {
        padding-left: 0px;
        width: 100%;
        min-width: inherit;
        float: left;
    }

    .gateway-ports {
        margin-top: 30px;
    }

    .access-key-header {
        display: none !important;
    }

    .access-key-label {
        display: inline !important;
    }

    .access-key-scope {
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .gateway-detail-bottom-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, 80px);
    }

    .gateway-detail-item.bottom.last {
        align-items: flex-start;
        justify-content: flex-start;
    }
}

@media (max-width: 992px) {
    /* upto lg*/

    .org-detail-btn {
        display: flex;
        justify-content: flex-end;
        grid-gap: 10px;
        gap: 10px;
    }

    .page-content .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .page-head {
        margin-left: 0;
        top: 120px;
    }

    .login-form::before {
        display: none;
    }
    .login-form {
        padding: 20px;
        padding-top: 80px;
        padding-bottom: 80px;
        min-height: 100vh;
    }

    .topbar-divider {
        display: none;
    }

    .logo {
        padding: 0 10px;
        left: 70px;
        position: absolute;
    }
    .switch-dropdown {
        position: absolute;
        top: 60px;
        width: 100%;
    }
    .switch-dropdown .switch {
        border-radius: 0;
    }
    .switch-dropdown .switch p {
        text-align: left;
    }
    .switch {
        position: absolute;
        left: 0;
        bottom: -60px;
        background: #323d54;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;
    }
    .switch i {
        right: 15px;
    }
    .switch i.down {
        top: 25px;
    }
    .switch i.up {
        top: 15px;
    }

    .page-head .container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }
    .page-head .page-title {
        margin-bottom: 15px;
    }
    .page-content {
        margin-left: 0rem !important;
        margin-top: 4.5rem !important;
        padding-bottom: 30px;
    }

    .navbar {
        padding: 0;
    }
    .navbar-expand .navbar-toggler {
        display: block;
        top: 1rem;
        left: 1rem;
        color: #ffffff;
    }
    .sidebar {
        left: -1000px;
        width: 100% !important;
        transition: all 1s;
    }
    .sidebar.show {
        left: 0;
        transition: all 0.5s;
    }
    .sidebar .nav-item {
        border-bottom: 1px solid #364056;
        transition: all 0.5s;
    }
    .sidebar .nav-item .nav-link {
        text-align: left;
        padding: 20px 27px;
        width: 100%;
        float: left;
        transition: all 0.5s;
    }
    .sidebar .nav-item .nav-link span {
        font-size: 16px;
        display: inline;
        margin-left: 23px;
    }

    .burger {
        top: 23px;
        display: block;
        position: absolute;
        left: 25px;
        z-index: 1000;
        width: 25px;
        height: 40px;
        transition: all 0.3s;
        cursor: pointer;
    }
    .menuToggle {
        margin-top: 2px !important;
    }
    .menuToggle span {
        display: block;
        width: 100%;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: #fff;
        border-radius: 0;
        z-index: 1;
        -webkit-transform-origin: 4px 0;
                transform-origin: 4px 0;
        transition: background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
            -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease,
            -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    }
    .burger.show .menuToggle span:nth-last-child(3) {
        opacity: 1;
        -webkit-transform: rotate(45deg) translate(0, 1px);
                transform: rotate(45deg) translate(0, 1px);
        top: 0px;
    }
    .burger.show .menuToggle span:nth-last-child(2) {
        opacity: 0;
        -webkit-transform: rotate(0) scale(0.2, 0.2);
                transform: rotate(0) scale(0.2, 0.2);
    }
    .burger.show .menuToggle span {
        opacity: 1;
        -webkit-transform: rotate(-45deg) translate(-1px, -2px);
                transform: rotate(-45deg) translate(-1px, -2px);
        background: #fff;
    }
    .sidebar .nav-item:hover .submenu {
        display: none;
        width: 100%;
        z-index: 99;
        left: initial;
        top: initial;
        position: relative;
        border-radius: 0;
        float: left;
        box-shadow: none !important;
        padding: 20px 24px 0;
        transition: all 0.5s;
    }
    .sidebar .nav-item:hover .submenu.show {
        display: block;
        transition: all 0.5s;
    }
    .sidebar .submenu-item {
        padding: 15px 20px;
    }
    .modal-dialog .dash-icon {
        margin: 15px 25px 15px 0;
    }
    .form-box {
        padding: 20px;
    }
    .form-box .btn-primary,
    .form-box .search {
        width: 100%;
        float: none !important;
    }
    .gateway-header {
        margin-bottom: 20px;
    }

    .gateway-details-box {
        padding: 20px;
    }
    .gateway-details-box .gateway-info {
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 20px;
    }
    .gateway-info h6.ginfo {
        font-size: 14px;
    }
    .gateway-ports {
        margin-top: 30px;
    }

    .gateway-details-tabs .nav-item .nav-link h6 {
        display: none;
    }
    .gateway-details-tabs .tab-pane {
        padding: 20px;
    }
    .discriptor-box {
        padding: 0 !important;
    }
    .update-info {
        padding-bottom: 10px;
    }

    .page-tabs .nav-item {
        width: 32%;
    }
    .org-name {
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: 35px;
        font-size: 20px;
    }
    .org-detail-content {
        margin-bottom: 15px;
    }
    .header-subscribe-button {
        display: none;
    }
    .cstm-table .table-head {
        display: none;
    }
    .cstm-table .table-row .row > div {
        height: 30px;
    }

    .ldsuList-row,
    .gatewayList-row {
        align-items: normal;
    }

    .ldsuList-row-middle,
    .gatewayList-row-middle {
        flex-direction: column;
        align-items: normal;
    }

    .gatewayList-row-middle {
        flex: 11 1;
    }

    .ldsuList-row-end,
    .gatewayList-row-end {
        flex-direction: column-reverse;
        align-items: center;
    }

    .ldsuList-row-delete-action,
    .gatewayList-row-delete-action {
        flex: 1 1;
        margin-left: 0;
    }
    .ldsuList-row-detail-action,
    .gatewayList-row-detail-action {
        flex: 3 1;
    }

    .gatewayList-row-detail-action i {
        margin-right: 0 !important;
    }

    .cstm-table .table-row .reading-label {
        display: inline-block;
        margin-right: 10px;
    }
}

@media (max-width: 768px) {
    /* upto md*/
    .page-title {
        text-align: left;
    }
    .gateway-header .btn {
        width: 100%;
    }
    .gateway-header-buttons {
        width: 100%;
    }
    .btn-primary {
        padding: 6px 10px;
    }
    .search {
        width: 180px;
    }
    .gateway-list-page .search {
        width: 100%;
    }

    .subscription-page .search {
        width: 100%;
        margin-top: 10px;
    }
    .subscription-page .filter {
        width: 100%;
    }
    .subscription-page .filter-button {
        width: 100%;
    }
    .header-balance-details,
    .chart-filter {
        display: none;
    }
    .page-content .no-checkbox {
        display: flex;
        align-items: center;
        margin-left: 0px;
        margin-right: 0px;
    }

    .btn.add-gateway,
    #generate-key-button {
        width: 100%;
        margin-bottom: 10px;
    }

    .cstm-table .table-row {
        padding: 20px;
        font-size: 15px;
    }

    .cstm-table .table-row .custom-checkbox {
        padding-left: 4px;
    }
    .cstm-table .table-row i.right {
        float: none;
    }
    .cstm-table .table-row .row > div {
        height: auto;
    }

    .max-w-180px {
        max-width: unset !important;
    }

    .LDSU-header .btn {
        margin-top: 20px;
    }
    .page-tabs .nav {
        margin-top: 20px;
        padding: 0 10px;
    }

    .page-tabs .nav-item .nav-link {
        padding: 17px 10px;
    }
    .page-tabs .nav .nav-item .nav-link .count {
        display: none;
    }

    .event-detail-head .btn {
        margin-top: 20px;
        width: 100%;
    }
    .descriptor-item h6 {
        width: 100%;
    }
    .sensor-header .page-title {
        margin-bottom: 85px;
    }
    .sensor-summary .last-active,
    .sensor-summary .sensor-info {
        width: 100%;
    }
    .sensor-summary .sensor-info h2 {
        width: 100%;
    }
    .sensor-summary .sensor-info p {
        margin: 0 15px 0 0;
    }
    .sensor-summary .last-active p {
        text-align: left !important;
    }
    .sensor-mode .custom-control,
    .alert-once-continuous .custom-control {
        margin-bottom: 15px;
    }

    .text-right-xs {
        text-align: right;
    }
    .uart-box .form-box-head {
        padding: 20px 20px 15px;
    }
    .uart-box .form-box-head h5 {
        width: 100%;
    }
    .uart-box .form-box-head .reset {
        float: left;
        text-align: left;
        margin-top: 20px;
    }
    .uart-box .form-box-head .btn {
        margin-top: 20px;
        margin-left: 0;
    }

    .org-details-head {
        margin-bottom: 20px;
    }

    .org-detail-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        grid-gap: 10px;
        gap: 10px;
    }

    .org-detail-btn .org-btn,
    .org-detail-btn .org-btn .btn {
        width: 100%;
    }

    .table-head-options .btn,
    .table-head-options .search {
        width: 100%;
    }
    .member-detail-head .page-title {
        margin-bottom: 80px;
    }
    .group-detail-head .page-title,
    .role-details-header .page-title {
        margin-bottom: 80px;
    }
    .invite-user-header {
        margin-bottom: 55px;
    }
    .member-info-item {
        margin-bottom: 20px;
    }
    .member-info-item .btn {
        width: 100%;
    }
    .table-head .group-item .group-item-name,
    .table-row .group-item .group-item-name {
        padding-left: 5px;
    }

    .gateway-detail-bottom-container {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 80px);
    }
}

@media (max-width: 400px) {
    .modal-dialog .dash-icon {
        margin: 15px 20px 15px 0;
    }
    .modal-dialog form .icons .dash-icon:nth-child(4n) {
        margin-right: 20px;
    }
    .ldsbus-buttons,
    .ldsu-buttons {
        flex: 1 1;
    }

    .ldsbus-buttons Button,
    .ldsu-buttons Button {
        width: 100%;
    }
}
@media (max-width: 330px) {
    .search {
        width: 136px;
    }
    .navbar .nav-item {
        margin: 0 3px;
    }
}

@media (min-width: 535px) {
    .new-dash .modal-dialog {
        max-width: 400px;
    }
}

.global-modal .modal-body {
    color: #828fab;
}
.global-modal .main-message {
    font-size: 1.75rem;
}

.hero {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    display: table;
    background-color: #2f3a51;
}
.hero::before {
    position: fixed;
    content: "";
    top: -380px;
    left: -520px;
    width: 900px;
    height: 900px;
    border-radius: 500px;
    border: 150px solid #2c374e;
    z-index: -1;
}
.hero-row {
    display: table-row;
}
.hero-box {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    padding: 0px 100px;
}
.hero-box img {
    width: 330px;
    position: relative;
}
.hero-box p {
    color: #828fab;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 400;
}
.hero-box p.copyright {
    color: #586173;
    opacity: 1;
    font-weight: 400;
}
.hero-box ul {
    list-style: none;
    padding: 0;
    margin-top: 100px;
}
.hero-box ul li {
    display: inline-block;
    padding: 0 20px;
    border-right: 1px solid rgb(194 203 223 / 50%);
    line-height: 1;
}
.hero-box ul li:first-child {
    padding-left: 0;
}
.hero-box ul li:last-child {
    border-right: none;
}
.hero-box ul li a {
    color: #828fab;
    font-size: 18px;
    font-weight: 400;
}
.hero-box .app-details {
    margin-top: 100px;
}
.hero-box p.summary-item {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
    display: flex;
    align-items: center;
}
.hero-box p.summary-item .check {
    position: absolute;
    left: 0;
    color: #3c5a99;
}
.hero-box p span {
    color: #ffffff;
}

.hero-box .app-details > h4 {
    font-size: 30px;
}
.hero-box .app-details p:nth-child(2) {
    margin-bottom: 50px;
}

@media (max-width: 1200px) {
    .hero-box {
        padding: 60px 20px 20px;
    }
    .hero-box ul li {
        display: block;
        padding: 15px 0;
        border-right: none;
    }
}

@media (max-width: 992px) {
    .hero::before {
        display: none;
    }

    .hero {
        position: relative;
        height: initial;
    }
    .hero-box ul {
        margin-top: 50px;
    }
}

.react-tel-input .form-control {
    width: 100%;
    border: 1px solid #e8e8e8;
    padding-left: 70px;
}
.react-tel-input .selected-flag {
    width: 60px;
    padding-left: 20px;
}

.react-tel-input .flag-dropdown {
    background-color: #ffffff;
    border-color: #e8e8e8;
}

.spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #00cbaf !important;
}

.verify-spinner {
    color: white !important;
    position: relative;
    top: 0%;
    left: 0%;
    height: 20px;
    width: 20px;
}

.loading-modal-title {
    color: #2f3a51;
    text-align: center;
    padding-top: 2rem;
    margin-bottom: -2rem;
}

:root .grid-stack-item>.ui-resizable-handle {
    -webkit-filter: none;
            filter: none;
}

.grid-stack {
    position: relative;
}

.grid-stack.grid-stack-rtl {
    direction: ltr;
}

.grid-stack.grid-stack-rtl>.grid-stack-item {
    direction: rtl;
}

.grid-stack .grid-stack-placeholder>.placeholder-content {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0;
    position: absolute;
    width: auto;
    z-index: 0 !important;
    text-align: center;
}

.grid-stack>.grid-stack-item {
    width: 100%;
    position: absolute;
    padding: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    touch-action: none;
}

.grid-stack>.grid-stack-item.ui-resizable-disabled>.ui-resizable-handle,
.grid-stack>.grid-stack-item.ui-resizable-autohide>.ui-resizable-handle {
    display: none;
}

.grid-stack>.grid-stack-item>.ui-resizable-se,
.grid-stack>.grid-stack-item>.ui-resizable-sw {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMS42MjYgNTExLjYyNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTExLjYyNiA1MTEuNjI3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTMyOC45MDYsNDAxLjk5NGgtMzYuNTUzVjEwOS42MzZoMzYuNTUzYzQuOTQ4LDAsOS4yMzYtMS44MDksMTIuODQ3LTUuNDI2YzMuNjEzLTMuNjE1LDUuNDIxLTcuODk4LDUuNDIxLTEyLjg0NSAgIGMwLTQuOTQ5LTEuODAxLTkuMjMxLTUuNDI4LTEyLjg1MWwtNzMuMDg3LTczLjA5QzI2NS4wNDQsMS44MDksMjYwLjc2LDAsMjU1LjgxMywwYy00Ljk0OCwwLTkuMjI5LDEuODA5LTEyLjg0Nyw1LjQyNCAgIGwtNzMuMDg4LDczLjA5Yy0zLjYxOCwzLjYxOS01LjQyNCw3LjkwMi01LjQyNCwxMi44NTFjMCw0Ljk0NiwxLjgwNyw5LjIyOSw1LjQyNCwxMi44NDVjMy42MTksMy42MTcsNy45MDEsNS40MjYsMTIuODUsNS40MjYgICBoMzYuNTQ1djI5Mi4zNThoLTM2LjU0MmMtNC45NTIsMC05LjIzNSwxLjgwOC0xMi44NSw1LjQyMWMtMy42MTcsMy42MjEtNS40MjQsNy45MDUtNS40MjQsMTIuODU0ICAgYzAsNC45NDUsMS44MDcsOS4yMjcsNS40MjQsMTIuODQ3bDczLjA4OSw3My4wODhjMy42MTcsMy42MTcsNy44OTgsNS40MjQsMTIuODQ3LDUuNDI0YzQuOTUsMCw5LjIzNC0xLjgwNywxMi44NDktNS40MjQgICBsNzMuMDg3LTczLjA4OGMzLjYxMy0zLjYyLDUuNDIxLTcuOTAxLDUuNDIxLTEyLjg0N2MwLTQuOTQ4LTEuODA4LTkuMjMyLTUuNDIxLTEyLjg1NCAgIEMzMzguMTQyLDQwMy44MDIsMzMzLjg1Nyw0MDEuOTk0LDMyOC45MDYsNDAxLjk5NHoiIGZpbGw9IiM2NjY2NjYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.grid-stack>.grid-stack-item>.ui-resizable-nw {
    cursor: nw-resize;
    width: 20px;
    height: 20px;
    top: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-n {
    cursor: n-resize;
    height: 10px;
    top: 0;
    left: 25px;
    right: 25px;
}

.grid-stack>.grid-stack-item>.ui-resizable-ne {
    cursor: ne-resize;
    width: 20px;
    height: 20px;
    top: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-e {
    cursor: e-resize;
    width: 10px;
    top: 15px;
    bottom: 15px;
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    cursor: se-resize;
    width: 20px;
    height: 20px;
}

.grid-stack>.grid-stack-item>.ui-resizable-s {
    cursor: s-resize;
    height: 10px;
    left: 25px;
    bottom: 0;
    right: 25px;
}

.grid-stack>.grid-stack-item>.ui-resizable-sw {
    cursor: sw-resize;
    width: 20px;
    height: 20px;
}

.grid-stack>.grid-stack-item>.ui-resizable-w {
    cursor: w-resize;
    width: 10px;
    top: 15px;
    bottom: 15px;
}

.grid-stack>.grid-stack-item.ui-draggable-dragging>.ui-resizable-handle {
    display: none !important;
}

.grid-stack.grid-stack-animate,
.grid-stack.grid-stack-animate .grid-stack-item {
    transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
}

.grid-stack.grid-stack-animate .grid-stack-item.ui-draggable-dragging,
.grid-stack.grid-stack-animate .grid-stack-item.ui-resizable-resizing,
.grid-stack.grid-stack-animate .grid-stack-item.grid-stack-placeholder {
    transition: left 0s, top 0s, height 0s, width 0s;
}

.ui-draggable-dragging,
.ui-resizable-resizing {
    z-index: 100;
}

.ui-draggable-dragging>.grid-stack-item-content,
.ui-resizable-resizing>.grid-stack-item-content {
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0.8;
}

.ui-draggable-dragging {
    will-change: left, top;
    cursor: move;
}

.ui-resizable-resizing {
    will-change: width, height;
}

.grid-stack-item > .grid-stack-item-content {
    inset: 0 !important;
}

.grid-stack > .grid-stack-item[gs-w="0"] {
    width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-x="0"] {
    left: 0%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="0"] {
    min-width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="0"] {
    max-width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-w="1"] {
    width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="1"] {
    left: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="1"] {
    min-width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="1"] {
    max-width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="2"] {
    width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="2"] {
    left: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="2"] {
    min-width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="2"] {
    max-width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="3"] {
    width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-x="3"] {
    left: 25%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="3"] {
    min-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="3"] {
    max-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-w="4"] {
    width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="4"] {
    left: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="4"] {
    min-width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="4"] {
    max-width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="5"] {
    width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="5"] {
    left: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="5"] {
    min-width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="5"] {
    max-width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="6"] {
    width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-x="6"] {
    left: 50%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="6"] {
    min-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="6"] {
    max-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-w="7"] {
    width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="7"] {
    left: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="7"] {
    min-width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="7"] {
    max-width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="8"] {
    width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="8"] {
    left: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="8"] {
    min-width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="8"] {
    max-width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="9"] {
    width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-x="9"] {
    left: 75%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="9"] {
    min-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="9"] {
    max-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-w="10"] {
    width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="10"] {
    left: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="10"] {
    min-width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="10"] {
    max-width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="11"] {
    width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="11"] {
    left: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="11"] {
    min-width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="11"] {
    max-width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="12"] {
    width: 100%;
  }
  .grid-stack > .grid-stack-item[gs-x="12"] {
    left: 100%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="12"] {
    min-width: 100% !important;
  }
  .grid-stack > .grid-stack-item[gs-max-w="12"] {
    max-width: 100% !important;
  }

  @media screen and (max-width: 768px) {
    [gs-w] {
      width: 100% !important;
    }
  }
.events-list .table-row img {
    margin-right: 7px;
}
.events-list .table-row .event-actions {
    position: absolute;
}

.enable-event-box {
    height: calc(100% - 7px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.when-do-title {
    background: #2f3a51;
    padding: 10px;
    border-radius: 5px;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin-top: 20px;
}
.arrow-circle {
    position: absolute;
    left: -35px;
    background: #2f3a51;
    border-radius: 50px;
    height: 70px;
    width: 70px;
    top: 10px;
    box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -4px 4px 23px 1px rgba(0, 0, 0, 0, 0.3);
}
.arrow-circle i {
    font-size: 70px;
    color: #00cbaf;
}
.conditions-list,
.actions-list {
    margin-top: 40px;
    float: left;
    width: 100%;
    position: relative;
    margin-bottom: 50px;
}
.conditions-list {
    padding-right: 40px;
    text-align: center;
}
.actions-list {
    padding-left: 40px;
    text-align: center;
}
.actions-list .btn.add-item,
.conditions-list .btn.add-item {
    padding: 6px 50px;
    margin-top: 1rem;
}
.conditions-list > .btn.add-item {
    margin-top: 20px !important;
}

.conditions-list-empty,
.actions-list-empty {
    margin-top: 40px;
    float: left;
    width: 100%;
    text-align: center;
    color: #828fab;
    position: relative;
}
.conditions-list-empty .btn,
.actions-list-empty .btn {
    padding: 10px 70px;
    margin-top: 5px;
}
.conditions-actions .divider {
    background: #2f3a51;
    width: 1px;
    height: 90%;
    position: absolute;
    top: 100px;
    right: 0px;
}
.action-item {
    background: #2f3a51;
    padding: 20px 25px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
    text-align: left;
    float: left;
    width: 100%;
}
.action-item:hover {
    text-decoration: none;
    opacity: 0.9;
    color: #ffffff;
}
.action-item h5 {
    margin-bottom: 20px;
    font-weight: 400;
}
.action-item h6 {
    font-weight: 400;
}
.action-item p {
    color: #828fab;
}
.action-item img {
    margin-right: 10px;
    width: 38px;
}
.action-item .remove-action {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #f2495e;
    cursor: pointer;
}

.action-details {
    padding-left: 3rem;
}

.action-details h6:first-child {
    margin-bottom: 0.1rem;
}

.condition-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}
.condition-item {
    background: #2f3a51;
    padding: 20px 0px 15px;
    border-radius: 10px;
    position: relative;
    text-align: left;
    margin-top: -30px;
    float: left;
    width: 100%;
}
.condition-item .condition-box {
    float: left;
    width: 100%;
}
.condition-item .condition-box:hover {
    opacity: 1;
    color: #ffffff;
}
.condition-item:first-child {
    margin-top: 0;
}
.condition-item h6 {
    margin-bottom: 5px;
    font-weight: 400;
}
.condition-item p {
    color: #828fab;
    margin-bottom: 5px;
}
.condition-item img {
    border-radius: 60px;
    margin-left: 20px;
    float: left;
    width: 40px;
}
.condition-item .condition-texts {
    margin-left: 20px;
    float: left;
}
.condition-item .remove-action {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #f2495e;
    cursor: pointer;
}
.condition-item .and-section {
    border-top: 1px solid #3e4b67;
    text-align: center;
    margin-top: 35px;
    float: left;
    width: 100%;
}
.condition-item .and-section .and-btn {
    background: #415071;
    border-color: #415071;
    margin: auto;
    width: 80px;
    font-size: 15px;
    margin-top: -20px;
    padding: 8px 15px;
    border-radius: 0.25rem;
}
.add-condition-item {
    background: #2f3a51;
    text-align: center;
    padding: 10px;
    margin-top: -10px;
    padding-bottom: 30px;
    border-radius: 0 0 10px 10px;
    float: left;
    width: 100%;
}
.add-condition-item .btn {
    padding-left: 50px;
    padding-right: 50px;
}
.or-section {
    border-top: 1px solid #3e4b67;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    float: left;
    width: 100%;
}
.or-section .or-btn {
    background: #415071;
    border-color: #415071;
    margin: auto;
    width: 80px;
    font-size: 15px;
    margin-top: -20px;
    padding: 8px 15px;
    border-radius: 0.25rem;
}
.condition-options-list .modal-option {
    padding: 15px;
}
.condition-options-list .modal-option:hover {
    opacity: 0.8;
}
.condition-options-list .modal-option p {
    font-size: 12px;
    color: #828fab;
}
.condition-options-list .modal-option img {
    width: 40px;
    float: left;
    margin-right: 20px;
}
.condition-options-list .modal-option i {
    color: #00cbaf;
    float: right;
    margin-top: 10px;
}
.action-device-details {
    background: #3a4763;
    padding: 20px;
    border-radius: 5px;
}
.action-device-details h6 {
    font-weight: 400;
    font-size: 18px;
}
.action-device-details p {
    opacity: 0.75;
    font-weight: 300;
    margin-bottom: 5px;
    font-size: 15px;
}
.action-detail-box .input-group .form-control {
    border-radius: 0.25rem !important;
}
.action-detail-box .input-group .input-group-text {
    background: rgb(36, 45, 65, 0);
    border: none;
    color: #ffffff;
}
.action-detail-box .added-item,
.condition-detail-box .added-item {
    float: left;
    padding: 10px 20px;
    padding-right: 12px;
    background: #3a4762;
    border-radius: 25px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.action-detail-box .added-item span,
.condition-detail-box .added-item span {
    color: #f2495e;
    font-size: 20px;
    margin-left: 7px;
    margin-top: 1px;
    cursor: pointer;
}
.condition-detail-box .recipients-added {
    position: relative;
    border-radius: 10px;
}

.condition-detail-box .recipients-added .recipient-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.condition-detail-box .recipients-added .phone-number-error-font-size {
    font-size: 0.8rem;
}

.condition-detail-box .recipient-msg {
    color: #828fab;
    font-size: 12px;
}

.added-item-recipient {
    padding-right: 20px;
    word-break: break-word;
}
.weekday {
    margin-bottom: 10px;
}
.weekday .weekday-option {
    border-bottom: 1px solid #3e4b67;
    border: 1px solid #3e4b67;
    border-radius: 5px;
    padding: 20px;
    transition: ease 0.5s;
}
.weekday .weekday-option.show {
    border-radius: 5px 5px 0 0;
    transition: ease 0.5s;
}
.weekday .weekday-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    display: none;
    transition: all 0.5s;
}
.weekday .weekday-box.show {
    display: block;
    transition: all 0.5s;
}
.weekday .weekday-option .custom-switch {
    padding-left: 40px;
}
.monthday {
    margin-bottom: 10px;
}
.monthday .monthday-date {
    border: 1px solid #3e4b67;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    transition: ease 0.5s;
}
.monthday .monthday-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    transition: all 0.5s;
}

.add-a-day .modal-option {
    background: #f8f8f8;
}

.modal-dialog:has(.delay-event__picker) {
    width: 352px;
}

.delay-event__picker > .MuiFormControl-root {
    background: #f0f0f0 !important;
    width: 100%;
    outline: none;
}

.delay-event__header:hover {
    color: #00cbaf;
    opacity: 0.5;
    text-decoration: underline;
    cursor: pointer;
}

.delay-event__desc {
    color: #00cbaf;
}

.event-edit {
    position: absolute;
    top: 20px;
    right: 60px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.event-edit[disabled] {
    opacity: 0.5;
}

.event-edit img {
    margin: 0;
}

.drag-action,
img.drag-action {
    position: absolute;
    top: 60px;
    right: 20px;
    margin: 0;
    cursor: grab;
}

.drag-action > img,
img.drag-action {
    width: 24px;
    height: 40px;
    margin: 0;
}

.permission-container.drag-action {
    cursor: no-drop;
}

.conditions-list .grid-stack {
    min-height: 40px;
}

.grid-stack-item + .grid-stack-item .condition-item {
    padding-top: 10px;
    border-radius: 0;
}

.grid-stack .grid-stack-item:first-child .condition-item {
    border-radius: 10px 10px 0 0;
}

.warning-message {
    display: flex;
    align-items: center;
    color: #f2495e;
    margin-top: 8px;
}

.warning-message.actuator {
    color: #f2994a;
}

.warning-message .material-icons {
    font-size: 1.4rem;
}

.warning-message span {
    line-height: 1.2;
    margin-left: 5px;
}

.conditions-list .grid-stack > .grid-stack-item[gs-w="1"],
.actions-list .grid-stack > .grid-stack-item[gs-w="1"] {
    width: 100%;
}

.every-input-unit {
    position: absolute;
    padding-right: 1rem;
    z-index: 15px;
}

.event-action-col {
    height: 24px;
    display: flex;
    align-items: center;
}

.grid-stack-item .action-item .content-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;
}

.invalid-time-error-msg {
    color: #f2495e;
    position: absolute;
    left: 25px;
}

.related-event-condition .event-actions-col {
    display: flex;
    align-items: center;
    height: 24px;
}

.wrap-around-col {
    display: flex;
    align-items: center;
}

.wrap-around-col .custom-switch {
    padding-left: 2.25rem;
}

.wrap-around-hint {
    font-size: 12px;
    font-weight: 400;
    color: #828fab;
    margin-bottom: 0;
}

.wrap-around-col .wrap-around-hint {
    margin-left: 30px;
}

.event-variable-form-header {
    padding: 20px 30px 15px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
}

.event-variable-form-header + .form-box {
    border-radius: 0;
}

.event-variable-form-header h5 {
    font-size: 18px;
    font-weight: 400;
}

.event-variable-form-content label {
    font-size: 16px;
    font-weight: 500;
}

.event-variable-form-content .form-control {
    font-size: 14px;
    font-weight: 400;
}

.associated-events .page-title {
    font-size: 20px;
    font-weight: 500;
}

.associated-events .cstm-table {
    font-weight: 400;
    font-size: 16px;
}

.event-variable-form-footer {
    padding: 20px 30px 20px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
}

.associated-event-actions img {
    margin-right: 7px;
}

.modify-event-variable .event-variable-info {
    background-color: #394662;
    padding: 15px 0 15px 1rem;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    word-break: break-all;
    font-weight: 400;
}

.modify-event-variable .event-variable-info h4 {
    font-size: 16px;
    font-weight: 400;
}

.not-counter-info {
    color: #ced1d8;
}

.modify-event-variable .event-variable-info p {
    font-size: 12px;
    margin-bottom: 0;
}

.modify-event-variable .event-variable-info span {
    color: #828fab;
}

.modify-event-variable .action-detail-box h5 p {
    font-size: 12px;
    font-weight: 300;
}

.event-variable-label {
    font-size: 18px;
    font-weight: 500;
}

.select-event-variable-item h4 {
    font-weight: 400;
    font-size: 16px;
}

.select-event-variable-item span {
    font-weight: 400;
    font-size: 12px;
    color: #828fab;
}

.action-details .variable-details h6,
.action-details .variable-details p {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    width: 95%;
    word-break: break-all;
    padding-right: 20px;
}

.tag-input-container {
    padding: 5px;
    border-radius: 4px;
    background-color: #242d41;
    border: 1px solid #36425c;
}

.event-variable-form-content .disabled-input {
    color: #6c757d !important;
}

.tags {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.list-operation-btn {
    display: flex;
    grid-gap: 2;
    gap: 2;
}

.list-operation-btn button {
    border: 1px solid #36425c;
    background-color: #3c4d71;
    border-radius: 40px;
    margin-right: 8px;
}

.modify-label {
    padding: 5px;
    display: flex;
    align-items: center;
    height: 50px;
}

.modify-label p {
    margin-bottom: 0;
}

.tag {
    border: 1px solid #3a4763;
    background-color: #3a4763;
    padding: 2px 5px;
    margin-right: 5px;
    border-radius: 36px;
    display: flex;
    align-items: center;
}

.tags li:first-child {
    margin-left: 10px !important;
}

.input-tag input {
    border: none;
    outline: none;
    background: transparent;
    width: auto;
    color: white;
}

.tags li {
    display: flex;
    align-items: center;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-right: 5px;
}

.tags li span {
    display: flex;
    align-items: center;
    height: 100%;
    word-break: break-all;
}

.expression-error-msg {
    font-size: 80%;
    font-weight: 400;
}

.add-condition-action-btn {
    border: 1px solid #00cbaf;
    border-radius: 4px;
    color: #00cbaf;
    font-weight: 400;
}

.recurrence-start-time {
    display: grid;
    grid-template-columns: 2fr 7fr 3fr;
    align-items: center;
}

.recurrence-end-time-option {
    display: grid;
    grid-template-columns: 2fr 4fr 6fr;
    align-items: center;
    margin-top: 20px;
}

.recurrence-end-time-option p {
    margin-bottom: 0;
}

.end-time-check {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.recurrence-start-time p {
    margin-bottom: 0;
}

.date-and-time-picker {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
}

.condition-guard-warn {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    color: #f2495e;
    font-size: 16px;
}

.link-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #00cbaf;
    text-decoration: none;
}

.link-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.condition-guard-warn p {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .cstm-table.events-list .table-row .btn {
        padding: 0;
    }
    .cstm-table.events-list .table-row .row div {
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .conditions-actions .divider {
        width: 0px;
    }
    .conditions-list {
        padding-right: 0px;
    }
    .actions-list {
        padding-left: 0;
    }

    .event-detail-head.event-main-page .page-title {
        margin-bottom: 25px;
    }

    .conditions-list,
    .actions-list {
        margin-top: 0px;
        padding: 20px 10px 30px;
        border: 1px solid #2f3a51;
        border-radius: 0 0 5px 5px;
    }
    .when-do-title {
        border-radius: 5px 5px 0 0;
    }
    .arrow-circle {
        width: 50px;
        height: 50px;
        left: 47%;
        top: -40px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    .arrow-circle i {
        font-size: 50px;
    }
    .condition-options-list .modal-option p {
        font-size: 10px;
    }
    .condition-item img {
        margin-left: 10px;
    }
    .condition-item .condition-texts {
        margin-left: 10px;
    }
}
@media (max-width: 768px) {
    .arrow-circle {
        left: 43%;
    }
}

@media screen and (max-width: 545px) {
    .custom-daterangepicker.opensright:before {
        left: auto;
        right: 9px;
    }

    .custom-daterangepicker.opensright:after {
        left: auto;
        right: 10px;
    }
}

.leaflet-control-geosearch .results > * {
    color: #2D3747;
}

.leaflet-container {
    z-index: 0;
}
.continuous-alert-option-label {
    display: flex;
    align-items: center;
}

.continuous-alert-option-label > label {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .continuous-alert-option-label > label {
        margin-bottom: 0.5rem;
    }
}

.user-profile .form-control:disabled,
.user-profile .form-control:read-only {
    color: #a2a2a2;
}

.verify-phone-number .resend-code {
    color: #4c4c4c;
}
.verify-phone-number .btn-link {
    color: #00cbaf;
}

.verify-phone-number .resend-code button {
    text-decoration: underline;
}

.verify-phone-number .error-message {
    font-size: 14px;
    background: transparent;
    color: var(--red);
}

::-ms-reveal {
    filter: invert(100%);
}

@media screen and (min-width: 576px) {
    .verify-phone-number .modal-sm {
        max-width: 350px;
    }
}

.invitation-box {
    background: #2f3a51;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    padding-left: 90px;
    float: left;
    padding-right: 40px;
    margin-bottom: 10px;
}
.invitation-box .first-letter {
    padding: 7px 0px;
    border-radius: 50px;
    background: #ffffff;
    color: #00cbaf;
    font-size: 18px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 22px;
    left: 25px;
    text-align: center;
}
.invitation-box .invitation-details {
    width: 70%;
    float: left;
}
.invitation-box .accept-decline {
    width: 30%;
    float: left;
}
.invitation-box .btn.accepted {
    color: #ffffff;
}
.invitation-box .btn.accepted i {
    color: #00cbaf;
    float: left;
    margin-right: 10px;
}

.btn.declined {
    color: #ffffff;
}

.btn.declined i {
    color: #f2495e;
    float: left;
    margin-right: 10px;
}

.invitation-box p.invite-date {
    color: #828fab;
    margin-bottom: 5px;
}
.invitation-box h5 {
    margin-bottom: 0;
}
.invitation-box .invite-org-details {
    background: #3a4660;
    padding: 20px 0;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.invitation-box .descriptor-item {
    width: 100%;
    border-bottom: none;
    float: left;
    padding: 0px 30px;
}
.invitation-box .descriptor-item .b-bottom {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    margin: 10px 0;
}
.invitation-box .descriptor-item h6 {
    margin: 0;
}
.invitation-box h6.ginfo {
    color: #828fab;
    text-align: right;
    word-wrap: break-word;
}

.text-limitor {
    max-width: 50%;
}

@media (max-width: 992px) {
    .invitation-box .invitation-details {
        width: 100%;
    }
    .invitation-box .accept-decline {
        width: 100%;
        margin-bottom: 20px;
        text-align: left !important;
    }
}
@media (max-width: 768px) {
    /* upto md*/
    .invitation-box .invite-org-details {
        padding: 15px 0;
    }
    .invitation-box .descriptor-item {
        padding: 10px 20px;
    }
    .invitation-box .descriptor-item .b-bottom {
        display: none;
    }
    .invitation-box .descriptor-item h6 {
        width: 100%;
    }
    .text-limitor {
        max-width: 100%;
    }
}

.modal-warning-message .modal-body {
    color: #828fab;
}

.error-message {
    font-size: 14px;
    background: transparent;
    color: var(--red);
}

.remove-email {
    color: var(--red);
    background: #242d41;
    box-shadow: none;
    height: 38px;
    border-color: #36425c;
    border: 1px solid #36425c!important;
}

.invite-email {
    border: 1px solid #36425c!important;
}

.invite-email:focus {
    border: 1px solid #08a58f!important;
}

.modal-add-roles .modal-body {
    color: #828fab;
    max-height: calc(100vh - 255px);
    overflow: auto;
    padding-top: 0;
}

.modal-add-roles .list-group {
    margin: 0;
}

.modal-add-roles .search-role {
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    background: white;
}

.modal-add-roles .search-role .form-control {
    padding-right: 42px;
}

.modal-add-roles .clear-search {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 0;
    font-size: 0;
    width: 42px;
    height: 42px;
    border-radius: 2px;
}

.modal-add-roles .list-group-item {
    position: relative;
    margin-bottom: 10px;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    padding: 0;
    
}

.modal-add-roles .custom-checkbox {
    padding: .75rem 1.25rem;
}

.modal-add-roles .list-group-item:last-child {
    margin-bottom: 0px;
}

.modal-add-roles .list-group-item:hover {
    background: #f4f4f4;
}

.modal-add-roles .custom-control-label {
    padding-left: 10px;
    display: block;
    color: #2f3a51;
}


.modal-add-roles .custom-control-input:disabled ~ .custom-control-label,
.modal-add-roles .custom-control-input[disabled] ~ .custom-control-label {
    color: #aaa;
}

.modal-add-roles .custom-control-label:hover {
    cursor: pointer;
}

.modal-add-roles .custom-control-label:before {
    background-color: #ddd;
    border-color: #ddd;
}

.modal-add-roles .role-type {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    -webkit-transform: translate(0px, -50%);
            transform: translate(0px, -50%);
    line-height: 100%;
    padding: 3px 10px;
    border: 1px solid #00cbaf;
    font-size: 12px;
    width: 91px;
}

.modal-add-roles .close-button {
    float: right;
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1;
    color: #fff;
    opacity: 1;
    padding: 0;
}

.modal-add-roles .btn-add {
    padding: 0.5rem 2rem;
    text-transform: uppercase;
}
.modal-add-roles .custom-checkbox .custom-control-input {
    left: 23px;
}
.modal-add-roles .custom-checkbox .custom-control-label {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 30px;
}
.modal-add-roles .custom-checkbox .custom-control-label:before, .modal-add-roles .custom-checkbox .custom-control-label:after {
    left:0;
}

@media screen and (min-width: 768px) {
    .modal-add-roles .modal-body {
        max-height: 465px;
    }
}

.selected-roles .row {
    background: #3a4763;
    border-radius: 4px;
}

.selected-roles .remove-role-button {
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 0;
    color: #f2495e;
}

.role-type {
    color: #828fab;
}

.modal-add-members .modal-body {
    color: #828fab;
    max-height: calc(100vh - 255px);
    overflow: auto;
    padding-top: 0;
}

.modal-add-members .list-group {
    margin: 0;
}

.modal-add-members .search-members {
    margin: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    background: white;
}

.modal-add-members .search-members .form-control {
    padding-right: 42px;
}

.modal-add-members .clear-search {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 0;
    font-size: 0;
    width: 42px;
    height: 42px;
    border-radius: 2px;
}

.modal-add-members .list-group-item {
    position: relative;
    margin-bottom: 10px;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    padding: 0;
    padding-left: 1.5rem;
}

.modal-add-members .custom-checkbox {
    padding: .75rem 1.25rem;
}

.modal-add-members .list-group-item:last-child {
    margin-bottom: 0px;
}

.modal-add-members .list-group-item:hover {
    background: #f4f4f4;
}

.modal-add-members .custom-control-label {
    padding-left: 10px;
    display: block;
    color: #2f3a51;
}


.modal-add-members .custom-control-input:disabled ~ .custom-control-label,
.modal-add-members .custom-control-input[disabled] ~ .custom-control-label {
    color: #aaa;
}

.modal-add-members .custom-control-label:hover {
    cursor: pointer;
}

.modal-add-members .custom-control-label:before {
    background-color: #ddd;
    border-color: #ddd;
}

.modal-add-members .members-type {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    -webkit-transform: translate(0px, -50%);
            transform: translate(0px, -50%);
    line-height: 100%;
    padding: 3px 10px;
    border: 1px solid #00cbaf;
    font-size: 12px;
    width: 120px;
}

.modal-add-members .close-button {
    float: right;
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1;
    color: #fff;
    opacity: 1;
    padding: 0;
}

.modal-add-members .btn-add {
    padding: 0.5rem 2rem;
    text-transform: uppercase;
}

@media screen and (min-width: 768px) {
    .modal-add-members .modal-body {
        max-height: 465px;
    }
}

[data-braintree-id="methods-label"] {
    color: white;
}

[data-braintree-id="other-ways-to-pay"] {
    color: white;
}

[data-braintree-id="choose-a-way-to-pay"] {
    color: white;
}

[data-braintree-id="methods-container"] {
    padding-bottom: 5px;
}

[data-braintree-id="sheet-error"] {
    padding-bottom: 20px !important;
    padding-top: 20px !important;
}

[data-braintree-id="unionpay-card-icon"] {
    display: none !important;
}

[data-braintree-id="american-express-card-icon"] {
    display: none !important;
}

[data-braintree-id="jcb-card-icon"] {
    display: none !important;
}

[data-braintree-id="discover-card-icon"] {
    display: none !important;
}

.updatePaymentModal .braintree-heading {
    color: black;
}

.braintree-load-spinner-wrapper {
    height: 50%;
}

.braintree-load-spinner-wrapper .braintree-load-spinner {
    z-index: 2;
    left: 48%;
    top: 35%;
}

.braintree-method {
    width: 100%;
}

.braintree-sheet__label {
    color: black;
}

.rdrCalendarWrapper {
    font-size: 12.5px !important;
}

.free-month-box {
    padding: 70px 30px 40px;
    text-align: center;
}
.subscribe-direct-box {
    padding: 70px 30px 70px;
    text-align: center;
}
.subscription-page h4 {
    font-weight: 400;
}
.subscription-page p {
    color: #828fab;
}

.billing-address-component {
    width: 100%;
    height: 100%;
    padding-top: 25px;
}

.billing-history-component {
    width: 100%;
    height: 100%;
    padding-top: 25px;
}

.free-month-box p span,
.subscription-benefits p span {
    color: #ffffff;
}
.subscribe-direct-box p span,
.box-body p span {
    color: #ffffff;
}
.subscription-summary {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 20px 0;
    text-align: left;
    border-top: 1px solid #3e4b67;
    border-bottom: 1px solid #3e4b67;
}

.subscription-summary p.summary-item,
.subscription-benefits p.summary-item {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
}
.subscription-summary p.summary-item .check,
.subscription-benefits p.summary-item .check {
    position: absolute;
    left: 0;
    color: #00cbaf;
}
.btn-start-trial {
    padding: 10px 35px;
}

.subscription-row {
    height: auto;
}

.subscription-info,
.subscription-benefits,
.balance-box {
    height: 100%;
}

.sub-info-customer {
    font-size: 14px;
}

.balance-box {
    text-align: left;
    border-radius: 5px;
    background: #2f3a51;
    padding: 30px;
}

.balance-box p {
    margin-bottom: 5px;
}
.balance-box .box-head {
    border-radius: 5px 5px 0 0;
}
.balance-box .box-head h4 {
    padding-top: 15px;
}
.balance-box .box-head h4 span {
    color: #00cbaf;
}
.balance-box .box-body {
    padding-top: 20px;
}

.balance-box .box-body .token-usage {
    text-align: left;
    float: left;
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 10px;
    padding-top: 10px;
}

.balance-box .box-body .token-usage:nth-last-child(2) {
    margin-bottom: 5rem;
}
.balance-box .box-body .token-usage:last-child {
    border-bottom: 0px solid #3e4b67 !important;
    position: absolute;
    bottom: 0px;
}

.balance-box .box-body .token-usage p {
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 0px;
}
.balance-box .box-body .token-usage p.period {
    color: #828fab;
    font-size: 14px;
}
.balance-box .box-body .token-usage p span {
    color: #828fab;
}
.purchase-tokens,
.customer-information {
    background: #3a4763;
    float: left;
    width: 100%;
    border-radius: 2px;
    padding: 12px 20px;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.purchase-tokens {
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
}

.purchase-tokens button {
    height: 2.5rem;
}

.customer-information {
    display: flex;
    flex-direction: column;
}

.purchase-tokens p {
    margin-bottom: 0px;
    color: #ffffff;
}
.purchase-tokens p.period {
    color: #828fab;
    font-size: 14px;
}

.qty {
    margin-top: 30px;
    float: left;
    width: 100%;
}
.qty.qty-checkout {
    margin-top: 10px;
    margin-bottom: 10px;
}

.qty .form {
    width: 50%;
    float: left;
}
.qty .form .form-label {
    margin-top: 8px;
    margin-right: 10px;
    color: #828fab;
}
.qty .form .form-control {
    width: 80px;
}
.qty .total {
    float: right;
    margin-top: 5px;
}

.subscription-benefits .qty {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #3e4b67;
    margin-bottom: 4rem;
}

.subscription-benefits .total-amount {
    padding: 20px 0 0;
    position: absolute;
    bottom: 1rem;
    width: calc(100% - 70px);
}

.subscription-benefits .total-amount h5 {
    float: left;
    margin-top: 10px;
}
.subscription-benefits .total-amount .btn {
    float: right;
}

.manage-subscription-info .subscr-details {
    border-bottom: 1px solid #3e4b67;
    padding: 0px 0px 20px 0px;
    margin-bottom: 10px;
}

.manage-subscription-info .cancel-subscription,
.edit-subscription {
    border-bottom: 1px solid #3e4b67;
    padding: 0px 0px 20px 0px;
    margin-bottom: 10px;
    justify-content: flex-end;
}

.manage-subscription-info .payment-card-info {
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
}

.payment-card-id {
    color: #000000;
    font-weight: 600;
}

.payment-card-type {
    color: #000000;
    font-size: 12px;
}

.subscription-info .subscr-details {
    margin-top: 20px;
}
.subscription-info .subscr-details p {
    margin-bottom: 0;
    color: #ffffff;
}
.subscription-info .subscr-details p.period {
    color: #828fab;
    font-size: 14px;
    margin: 5px 0;
}

.billing-history-address {
    width: 100%;
    margin-top: 20px;
}
.billing-link {
    color: #00cbaf;
    width: 100%;
    font-weight: 400;
    display: block;
    margin-bottom: 15px;
    text-decoration: none !important;
}
.cancel-subscr,
.pendingCancel {
    width: 100%;
    font-weight: 400;
    color: #e86881;
    margin-top: 80px;
}
.cancel-subscr:hover,
.removeAddon:hover {
    text-decoration: underline;
    color: #e86882ad;
    cursor: pointer;
}

.cancel-promo:hover {
    cursor: pointer;
}

.manage-subscr {
    width: 100%;
    font-weight: 400;
    color: #00cbaf;
    margin-top: 80px;
    float: left;
}
.manage-subscr:hover {
    cursor: pointer;
    text-decoration: underline;
}

.billing-link:hover {
    text-decoration: underline !important;
    color: #00cbaf;
    cursor: pointer;
}

.token-spend-title {
    margin-top: 20px;
}
.token-spend-title h5 {
    margin-top: 0;
}
.token-spend-title p {
    margin-bottom: 0;
    margin-top: 2px;
    color: #00cbaf;
}
.monthly-spending {
    position: relative;
}

.monthly-spending.overview {
    height: 100%;
}
.monthly-spending .chart-box {
    margin-top: 10px;
}

.overview .chart-box {
    height: calc(100% - 55px) !important;
    margin-top: 10px;
}

.monthly-spending h5 {
    float: left;
}
.monthly-spending .date-filter {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}

.monthly-spending-dropdown .date-filter {
    position: initial;
}

.monthly-spending .date-filter span {
    color: #ffffff;
}

#monthlyPercent {
    padding-top: 5px;
    margin-top: 10px;
    width: 21%;
    border-radius: 6px;
    background: #415071;
    justify-content: center;
}

#monthlyPercent .percent {
    padding-right: 7px;
    font-weight: 700;
    vertical-align: top;
}

#monthlyPercent p {
    font-size: 11px;
    margin-top: -6px;
    padding: 5px;
}

.monthlyYearlyDropdown {
    height: 30px;
}

.address-title,
.customer-id-title {
    margin-bottom: 30px;
}
.address-title h5,
.customer-id-title h5 {
    margin: 0;
}
.address-title p,
.customer-id-title p {
    margin: 0;
    color: #828fab;
}
.address-title .address-img,
.customer-id-title .customer-id-img {
    float: left;
    padding: 7px 15px 0 0;
}

.customer-id-title .link {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #00cbaf;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
}

.billing-ad-info {
    overflow-wrap: break-word;
}

.payment-title p {
    margin: 0;
    margin-top: 3px;
    float: right;
}
.payment-title h5 {
    margin: 0;
    margin-top: 3px;
    float: left;
}
.payment-title .address-img {
    float: left;
    padding: 0px 15px 0 0;
}
.bill-title {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 20px;
}
.monthly-charge {
    margin-top: 20px;
    float: left;
    width: 100%;
}
.monthly-charge h6 {
    font-weight: 400;
    margin-bottom: 2px;
}

.enter-promocode {
    border-top: 1px solid #3e4b67;
    border-bottom: 1px solid #3e4b67;
    padding: 20px 0;
    float: left;
    width: 100%;
    margin: 10px 0;
}
.enter-promocode h6 {
    font-weight: 400;
    float: left;
    margin: 0;
    line-height: 24px;
}
.enter-promocode .material-icons {
    color: #00cbaf;
}
.enter-promo-btn {
    float: right;
    color: #00cbaf;
    padding: 0;
}
.enter-promo-btn:hover {
    color: #00cbaf;
    opacity: 0.5;
}
.amount-box {
    background: #3a4763;
    padding: 20px;
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 2px;
}
.amount-box p {
    color: #828fab;
    font-size: 14px;
    margin-bottom: 5px;
}
.amount-box p span {
    float: right;
    color: #ffffff;
}
.total-box {
    background: #3a4763;
    padding: 20px;
    float: left;
    width: 100%;
}
.total-box h5 {
    margin: 0;
}
.total-box h5 span {
    float: right;
    color: #00cbaf;
    border-radius: 2px;
}
.btn.proceed {
    width: 100%;
    margin-top: 20px;
    padding: 10px;
}
.check-term {
    float: left;
    margin-top: 35px;
    padding-left: 35px;
}
.check-term .link {
    color: #00cbaf;
}
.custom-checkbox.check-term .custom-control-label::before,
.custom-checkbox.check-term .custom-control-label::after {
    left: -35px;
}

.cstm-table.biliing-history .table-row .amount {
    color: #389ef9;
}
.cstm-table.biliing-history .table-row .success {
    color: #00cbaf;
}
.cstm-table.biliing-history .table-row .fail {
    color: #e86881;
}
.cstm-table.biliing-history .table-row .download-pdf {
    background: rgba(0, 203, 175, 0.5);
    border: 1px solid #00cbaf;
    padding: 3px 18px;
    font-size: 14px;
}
.promocode-list {
    border-top: 1px solid #f0f0f0;
    margin-top: 20px;
}
.promocode-list .modal-option {
    padding: 15px;
}
.promocode-list .modal-option h5 {
    color: #2f3a51;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}
.promocode-list .modal-option p {
    font-size: 12px;
}
.promocode-list .modal-option .btn {
    float: right;
    color: #00cbaf;
}

.manage-subscription-info {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
}
.manage-subscription-info .subscr-details p {
    margin-bottom: 0;
    color: #ffffff;
}
.manage-subscription-info .subscr-details p.period {
    color: #828fab;
    font-size: 14px;
    margin: 5px 0;
}
.plan-status {
    color: #828fab !important;
    font-size: 14px;
    margin: 5px 0;
}
.manage-subscription-info .cancel-subscription,
.manage-subscription-info .edit-subscription {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.currentPlanTitle {
    color: #828fab;
    font-size: 14px;
}

.currentPlanRow {
    padding: 15px 0px;
}

.removeAddon {
    color: #f2495e;
    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
}

.not-allowed-input {
    color: #6c757d !important;
    cursor: not-allowed;
}

.address-title .billing-address-edit {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    color: #00cbaf;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
}

.billing-start-text {
    color: #828fab;
}

.transaction-id-col {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subscription-container {
    max-width: 100%;
    padding: 0;
}

.billing-history-component #calendar {
    position: absolute;
    z-index: 2;
    top: 50px;
}

.verify-loading-btn {
    min-width: 95px;
}

.verify-error-msg {
    min-width: 200px;
    color: #f2495e;
    margin-left: 10px;
}

.font-weight-400 {
    font-weight: 400;
}

.monthly-spending-date-dropdown {
    max-height: 150px;
    overflow: scroll;
}

.increase-btn-color {
    color: #e86881;
}

.decrease-btn-color {
    color: #2cd9c5;
}

.customer-form-wrap {
    margin-right: 10px;
    min-width: 100px;
}

.customer-form-wrap .customer-form-label {
    margin-right: 5px;
    color: #828fab;
}

.current-plan-footer {
    border-top: 1px solid #3e4b67;
}

.current-plan-footer .current-plan-footer-label {
    color: #00cbaf;
    font-size: 14px;
}

.add-on-form {
    margin-right: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.add-on-form .add-form-qty-label {
    color: #ffffff;
    margin-right: 5px;
}

.add-on-form .float-left {
    width: 80px;
    margin-right: 25px;
}

.quantity-field-form-label {
    display: flex;
    grid-gap: 0.2rem;
    gap: 0.2rem;
    margin-bottom: 0;
}

.quantity-field-form-control {
    width: 5rem;
}

.billing-address-form-error {
    color: #dc3545;
    padding: 0.475rem 0.35rem;
    font-size: 0.85rem;
}

@media (max-width: 2000px) {
    #monthlyPercent {
        width: 30%;
    }
}

@media (max-width: 1500px) {
    .purchase-tokens,
    .qty,
    .subscription-benefits .total-amount {
        margin-top: 10px;
    }

    .subscription-benefits .total-amount .btn {
        float: right !important;
        width: initial;
    }

    #monthlyPercent {
        width: calc(100% - 55px);
        margin-top: 10px;
    }
}

@media (max-width: 992px) {
    .subscription-page .breadcrumb {
        margin-top: -20px;
    }
    .subscription-benefits .total-amount {
        width: calc(100% - 50px);
    }
    .manage-subscription-info .cancel-subscription,
    .manage-subscription-info .edit-subscription {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 20px;
    }
}

@media (max-width: 587px) {
    .add-on-field {
        flex-direction: column;
        grid-gap: 1rem;
        gap: 1rem;
        width: 100%;
    }

    .add-on-form {
        align-self: flex-start;
    }
    .add-on-field button {
        align-self: flex-end;
    }
}

@media (max-width: 400px) {
    .subscription-benefits {
        height: initial;
    }
}

@media (max-width: 376px) {
    #monthlyPercent {
        width: calc(100% - 40px);
        margin-top: 10px;
    }
}

@media screen and (min-width: 576px) {
    #displayBox {
        min-width: 260px;
    }
}

.reusable-description {
    color: #828fab;
    font-size: 13px;
    word-break: break-word;
}

.copy:hover,
.scopeInfo:hover {
    cursor: pointer;
    opacity: 0.5;
}

.lightCheckbox {
    display: flex;
    grid-gap: 4rem;
    gap: 4rem;
}

.lightCheckbox .custom-control-label::before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}

.lightCheckbox .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00cbaf;
    background-color: #00cbaf;
}

.access-key-label {
    display: none;
}

.access-key-scope {
    word-break: break-word;
    flex: 3 1;
    white-space: pre-line;
}

.access-key-list .scopeInfo {
    flex: 1 1;
}

.scope-warning-msg {
    color: #DC3545;
    font-size: 80%;
}

.generate-key-btn {
    -webkit-margin-start: auto;
            margin-inline-start: auto;
}

#access-key-button {
    padding: 8px 15px;
}

