.hero {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    display: table;
    background-color: #2f3a51;
}
.hero::before {
    position: fixed;
    content: "";
    top: -380px;
    left: -520px;
    width: 900px;
    height: 900px;
    border-radius: 500px;
    border: 150px solid #2c374e;
    z-index: -1;
}
.hero-row {
    display: table-row;
}
.hero-box {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    padding: 0px 100px;
}
.hero-box img {
    width: 330px;
    position: relative;
}
.hero-box p {
    color: #828fab;
    font-size: 20px;
    margin-top: 10px;
    font-weight: 400;
}
.hero-box p.copyright {
    color: #586173;
    opacity: 1;
    font-weight: 400;
}
.hero-box ul {
    list-style: none;
    padding: 0;
    margin-top: 100px;
}
.hero-box ul li {
    display: inline-block;
    padding: 0 20px;
    border-right: 1px solid rgb(194 203 223 / 50%);
    line-height: 1;
}
.hero-box ul li:first-child {
    padding-left: 0;
}
.hero-box ul li:last-child {
    border-right: none;
}
.hero-box ul li a {
    color: #828fab;
    font-size: 18px;
    font-weight: 400;
}
.hero-box .app-details {
    margin-top: 100px;
}
.hero-box p.summary-item {
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
    display: flex;
    align-items: center;
}
.hero-box p.summary-item .check {
    position: absolute;
    left: 0;
    color: #3c5a99;
}
.hero-box p span {
    color: #ffffff;
}

.hero-box .app-details > h4 {
    font-size: 30px;
}
.hero-box .app-details p:nth-child(2) {
    margin-bottom: 50px;
}

@media (max-width: 1200px) {
    .hero-box {
        padding: 60px 20px 20px;
    }
    .hero-box ul li {
        display: block;
        padding: 15px 0;
        border-right: none;
    }
}

@media (max-width: 992px) {
    .hero::before {
        display: none;
    }

    .hero {
        position: relative;
        height: initial;
    }
    .hero-box ul {
        margin-top: 50px;
    }
}
