.error-message {
    font-size: 14px;
    background: transparent;
    color: var(--red);
}

.remove-email {
    color: var(--red);
    background: #242d41;
    box-shadow: none;
    height: 38px;
    border-color: #36425c;
    border: 1px solid #36425c!important;
}

.invite-email {
    border: 1px solid #36425c!important;
}

.invite-email:focus {
    border: 1px solid #08a58f!important;
}
