.reusable-description {
    color: #828fab;
    font-size: 13px;
    word-break: break-word;
}

.copy:hover,
.scopeInfo:hover {
    cursor: pointer;
    opacity: 0.5;
}

.lightCheckbox {
    display: flex;
    gap: 4rem;
}

.lightCheckbox .custom-control-label::before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}

.lightCheckbox .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00cbaf;
    background-color: #00cbaf;
}

.access-key-label {
    display: none;
}

.access-key-scope {
    word-break: break-word;
    flex: 3;
    white-space: pre-line;
}

.access-key-list .scopeInfo {
    flex: 1;
}

.scope-warning-msg {
    color: #DC3545;
    font-size: 80%;
}

.generate-key-btn {
    margin-inline-start: auto;
}

#access-key-button {
    padding: 8px 15px;
}
