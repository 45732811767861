.modal-add-roles .modal-body {
    color: #828fab;
    max-height: calc(100vh - 255px);
    overflow: auto;
    padding-top: 0;
}

.modal-add-roles .list-group {
    margin: 0;
}

.modal-add-roles .search-role {
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 99;
    padding-top: 1.25rem;
    padding-bottom: 1rem;
    background: white;
}

.modal-add-roles .search-role .form-control {
    padding-right: 42px;
}

.modal-add-roles .clear-search {
    position: absolute;
    top: 20px;
    right: 0;
    padding: 0;
    font-size: 0;
    width: 42px;
    height: 42px;
    border-radius: 2px;
}

.modal-add-roles .list-group-item {
    position: relative;
    margin-bottom: 10px;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    padding: 0;
    
}

.modal-add-roles .custom-checkbox {
    padding: .75rem 1.25rem;
}

.modal-add-roles .list-group-item:last-child {
    margin-bottom: 0px;
}

.modal-add-roles .list-group-item:hover {
    background: #f4f4f4;
}

.modal-add-roles .custom-control-label {
    padding-left: 10px;
    display: block;
    color: #2f3a51;
}


.modal-add-roles .custom-control-input:disabled ~ .custom-control-label,
.modal-add-roles .custom-control-input[disabled] ~ .custom-control-label {
    color: #aaa;
}

.modal-add-roles .custom-control-label:hover {
    cursor: pointer;
}

.modal-add-roles .custom-control-label:before {
    background-color: #ddd;
    border-color: #ddd;
}

.modal-add-roles .role-type {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translate(0px, -50%);
    line-height: 100%;
    padding: 3px 10px;
    border: 1px solid #00cbaf;
    font-size: 12px;
    width: 91px;
}

.modal-add-roles .close-button {
    float: right;
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1;
    color: #fff;
    opacity: 1;
    padding: 0;
}

.modal-add-roles .btn-add {
    padding: 0.5rem 2rem;
    text-transform: uppercase;
}
.modal-add-roles .custom-checkbox .custom-control-input {
    left: 23px;
}
.modal-add-roles .custom-checkbox .custom-control-label {
    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 30px;
}
.modal-add-roles .custom-checkbox .custom-control-label:before, .modal-add-roles .custom-checkbox .custom-control-label:after {
    left:0;
}

@media screen and (min-width: 768px) {
    .modal-add-roles .modal-body {
        max-height: 465px;
    }
}
