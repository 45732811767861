:root {
    --create-chart-form-secondary: #2f3a51;
    --create-chart-from-background: #394662;
    --create-chart-form-active: #00cbaf;
    --create-chart-sub-text-color: #828fab;
    --create-chart-multiple-sensor-background: #252e42;
    --create-chart-deep-button-background: #242d41;
    --create-chart-deep-button-border: #36425c;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
    font-size: 1rem;
    background-color: #171f30;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}
h5 {
    font-size: 20px;
    font-weight: 400;
}
a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    color: #00cbaf;
    opacity: 0.5;
}
.green {
    color: #00cbaf !important;
}
.red {
    color: #00cbaf !important;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.container-fluid {
    padding-left: 0px;
    padding-right: 0px;
}

.btn + .btn {
    margin-top: 0px;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.btn-primary {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
    padding: 6px 20px;
}

.btn-primary:disabled {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 0.5;
}
.btn-primary:disabled:hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 0.5;
}

.btn-primary:hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 1;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background-color: #08a58f;
    border-color: #08b39b;
}

.btn-outline-primary {
    border-color: #00cbaf;
    color: #fff;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-secondary {
    color: #aab4c9;
    background-color: #2f3a51;
    border-color: #2f3a51;
    padding: 6px 20px;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    color: #fff;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
}

.btn-secondary:not(.sign-up):hover {
    color: #fff;
    background-color: #08a58f;
    border-color: #08b39b;
    opacity: 1;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #08a58f;
    border-color: #08a58f;
    box-shadow: none;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
    background-color: #08a58f;
    border-color: #08b39b;
}

.btn-danger {
    color: #ffffff;
    background-color: #f2495e;
    border-color: #f2495e;
    padding: 6px 20px;
}

.btn-abort {
    background-color: #3c5a99 !important;
    border-color: #3c5a99 !important;
}

.btn-abort:hover {
    background-color: #3c5a99 !important;
    border-color: #3c5a99 !important;
}

.btn:disabled,
.btn:hover {
    opacity: 0.6 !important;
}

.btn-light {
    color: #00cbaf;
    background: none;
    border: none;
    padding: 6px 20px;
}
.btn-light:hover {
    color: #aab4c9;
    background-color: #2f3a51;
    border-color: #2f3a51;
    padding: 6px 20px;
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
    color: #fff;
    background-color: #00cbaf;
    border-color: #00cbaf;
    box-shadow: none;
}
.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
    background-color: #08a58f;
    border-color: #08b39b;
}
.form-control:focus {
    border: 1px solid #08a58f;
    box-shadow: none;
}

.form-check-input:checked {
    background-color: #00cbaf;
    border-color: #00cbaf;
}
.custom-switch {
    padding-left: 20px;
}
.custom-switch .custom-control-label::before {
    width: 50px;
    height: 26px;
    border-radius: 30px;
    background: #55678c;
    border-color: #55678c;
}

.custom-switch .custom-control-label::after {
    width: 18px;
    height: 18px;
    border-radius: 30px;
    top: 4px;
    left: -30px;
    background: #2f3b51;
}
.custom-switch,
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
    cursor: pointer;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::after {
    cursor: no-drop;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #2f3b51;
    transform: translateX(1.4rem);
}
.custom-control-label {
    cursor: pointer;
}
.table-row .custom-switch {
    float: left;
}

.custom-control-label:focus {
    box-shadow: none;
}
.custom-control-label:before {
    background-color: #242d41;
    border: 1px solid #36425c;
    border-radius: 3px;
    top: 0;
    height: 21px;
    width: 21px;
}
.custom-control-label:after {
    height: 21px;
    width: 21px;
    top: 0px;
    left: -1.5rem;
}
.custom-control-label:not(:disabled):not(.disabled).active:focus,
.custom-control-label:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}

.modal-header {
    background: #2f3a51;
    border-radius: 12px 12px 0 0;
    padding: 20px 20px;
}
.modal-header .close {
    color: #ffffff;
    opacity: 1;
    margin-right: -13px;
    margin-top: -13px;
}
.border-0 .modal-dialog .modal-content {
    border: 0px solid black !important;
}

.modal-content {
    border-radius: 20px 20px 12px 12px;
}
.modal-title.h4 {
    font-size: 20px;
}

.dropdown-toggle::after {
    margin-bottom: -2px;
}

.breadcrumb {
    background: none;
    padding: 0;
    margin-top: -20px;
}
.breadcrumb-item a {
    color: #00cbaf;
}
/** Navbar*/
.navbar {
    padding: 0 16px;
    height: 60px;
    background-color: #394662;
    z-index: 90;
}

.logo {
    padding: 0 10px;
}
.logo img {
    width: 140px;
}
.navbar .navbar-toggler {
    top: 0.25rem;
    right: 1rem;
}

.topbar-divider {
    border-right: 1px solid #536487;
    width: 0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
}

.switch {
    padding: 0 5px;
    position: relative;
    cursor: pointer;
    border: 0;
}
.switch h5,
.switch p {
    margin-bottom: 0;
}
.switch h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: -2px;
    text-align: left;
    color: #ffffff;
}
.switch p {
    font-size: 14px;
    color: #828fab;
}
.switch i {
    position: absolute;
    right: -35px;
    top: 10px;
    color: #00cbaf;
}
.switch i.up {
    top: 5px;
}
.switch i.down {
    top: 15px;
}
.switch.dropdown-toggle::after {
    content: none;
}
.switch-drop {
    width: 320px;
    padding: 20px;
    background: #2f3a51;
    border: 1px solid #414d69;
    border-radius: 5px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}
.new-org-button {
    margin-top: 10px;
    width: 100%;
    padding: 0px;
    background: #2f3a51;
    float: left;
}
.switch-drop p {
    color: #828fab;
    float: left;
    margin-bottom: 7px;
}
.switch-drop .dropdown-item {
    float: left;
    padding: 0;
    margin: 5px 0;
}
.switch-drop .dropdown-item .first-letter {
    padding: 5px 0px;
    border-radius: 50px;
    background: #ffffff;
    color: #00cbaf;
    font-size: 18px;
    float: left;
    width: 37px;
    height: 37px;
    margin-right: 12px;
    text-align: center;
}
.switch-drop .dropdown-item .check {
    display: none;
    float: right;
    color: #00cbaf;
    margin-top: 5px;
}
.switch-drop .dropdown-item.selected .check {
    display: block;
}

.switch-drop .dropdown-item h6 span.star {
    display: none;
}

.switch-drop .dropdown-item:focus,
.switch-drop .dropdown-item:hover {
    background: none;
}

.navbar .nav-item {
    margin: 0 15px;
    position: relative;
}

.navbar .nav-item .nav-link {
    height: 4.375rem;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    position: relative;
}
.navbar .nav-link .badge-counter {
    position: absolute;
    transform: scale(0.7);
    transform-origin: top right;
    margin-top: -0.25rem;
    background-color: #e86881;
    padding: 9px 8px;
    right: 0px;
    border-radius: 15px;
    font-size: 15px;
    font-weight: 400;
}

.change-account {
    display: none;
    z-index: 105;
    position: absolute;
    left: -20px;
    top: 49px;
    width: 300px;
    border-radius: 8px;
    background: #2f3a51;
    padding: 20px 25px 15px;
    border: 1px solid #47536d;
}
.change-account.show {
    display: block;
}
.change-account ul {
    list-style: none;
    padding-left: 0;
    display: inline-block;
    margin: 10px 0 20px;
    width: 100%;
}
.change-account ul li {
    margin: 10px 0;
    display: block;
    float: left;
    width: 100%;
}
.letters-round {
    text-align: center;
    font-weight: 500;
    padding: 5px 5px 5px;
    font-size: 16px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    color: #00cbaf;
    background: #ffffff;
    float: left;
}
.account-name {
    position: relative;
    padding-left: 20px;
    margin-top: 4px;
    float: left;
}
.account-name i.star {
    position: absolute;
    top: -3px;
    right: -35px;
    color: #f2c94c;
}
i.done {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    float: right;
    color: #00cbaf;
}
.change-account .btn-primary {
    width: 100%;
}
.bg-overlay {
    z-index: 101;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

/** Sidebar*/

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 71px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar .nav-item .nav-link.actived,
.sidebar .nav-item:hover .nav-link,
.sidebar .nav-item:focus .nav-link {
    color: #00cbaf;
    opacity: 1;
}

.sidebar .nav-item .nav-link.actived svg path,
.sidebar .nav-item:hover .nav-link svg path,
.sidebar .nav-item:focus .nav-link svg path {
    fill: #00cbaf;
    opacity: 1;
}

.sidebar .nav-item:hover .nav-link,
.sidebar .nav-item:focus .nav-link {
    color: #00cbaf;
    opacity: 1;
    background: #2f3a51;
}

nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow: visible;
    width: 82px !important;
}
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    margin: 61px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #394662;
}
.sidebar .nav-item > .nav-link {
    text-align: center;
    padding: 20px 1px;
    width: 100%;
}
.sidebar .nav-item .nav-link span {
    font-size: 12px;
    margin-top: 4px;
    font-weight: 400;
    display: block;
}
.sidebar .nav-item {
    position: relative;
}
.sidebar .submenu {
    display: none;
    position: absolute;
    left: 82px;
    top: 0px;
    list-style: none;
    width: 240px;
    border-radius: 0px 5px 5px 0;
    background: #2f3a51;
    padding: 15px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    text-align: left;
}
.sidebar .nav-item:hover .submenu {
    display: block;
}

.sidebar .submenu-item {
    padding: 10px 20px;
    width: 100%;
    color: #ffffff;
    float: left;
    text-decoration: none;
    border-radius: 0px;
}
.sidebar .submenu-item:hover {
    background: #424d65;
    opacity: 1;
    border-radius: 5px;
    color: #ffffff;
}

.navbar .nav-item .profile-drop {
    display: none;
    position: absolute;
    right: -10px;
    top: 55px;
    list-style: none;
    width: auto;
    border-radius: 8px;
    background: #2f3a51;
    padding: 7px 30px 14px;
    border: 1px solid #47536d;
    max-width: 400px;
}
.navbar .nav-item .profile-drop li {
    width: 100%;
    float: left;
    padding: 15px 0;
    cursor: pointer;
}
.navbar .nav-item .profile-drop li a {
    text-decoration: none;
    display: flex;
    width: 100%;
}
.navbar .nav-item .profile-drop li a img {
    width: 24px;
}
.profile-drop .profile-link img {
    margin-top: 10px;
}
.navbar .nav-item .profile-drop li a .text {
    padding-left: 20px;
    margin-top: 3px;
}

.navbar .nav-item .profile-drop li a h5 {
    color: #ffffff;
    width: 100%;
    font-size: 16px;
    margin: 0;
}
.navbar .nav-item .profile-drop li a p {
    font-size: 14px;
    margin: 0;
    color: #828fab;
}

/* login */
.loginf {
    color: #e86881;
    margin-top: 15px;
}
.login-form {
    padding-top: 70px;
    position: relative;
    height: 100vh;
    display: table;
    background: #ffffff;
    text-align: left;
    padding-bottom: 70px;
}
.login-form .form-group {
    margin-bottom: 10px;
}
.login-form::before {
    position: fixed;
    content: "";
    bottom: -485px;
    right: -535px;
    width: 900px;
    height: 900px;
    border-radius: 500px;
    border: 150px solid #f9f9f9;
}

.login-form a.link {
    color: #00cbaf;
}
.login-form .forgot {
    margin: 10px 0 20px;
    float: left;
}
.login-form .form-control {
    padding: 1.5rem 1rem;
    border: 1px solid #e8e8e8;
}
.login-form .form-control:focus {
    border: 1px solid #08a58f;
}
.login-form label {
    font-size: 16px;
    margin-bottom: 0.5rem;
}
.login-form .custom-control-label:before {
    background-color: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 3px;
    top: 0.2rem;
    height: 1rem;
    width: 1rem;
}
.login-form .custom-control-label:after {
    width: 1rem;
    height: 1rem;
    top: 3px;
    left: -1.5rem;
}
.login-form .form-check {
    padding-left: 0px;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00cbaf;
    background-color: #00cbaf;
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    opacity: 0.8;
    background-color: #00cbaf;
}
.custom-radio
    .custom-control-input:disabled:checked
    ~ .custom-control-label::after {
    opacity: 0.8;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
    color: #ffffff;
    opacity: 0.8;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
    background-color: #242d41;
    opacity: 0.75;
}
.login-form .sign-up,
.login-form .btn-primary {
    width: 100%;
    padding: 12px 0px;
    text-transform: uppercase;
    font-weight: 500;
}
.login-box {
    display: table-cell;
    vertical-align: middle;
}
.login-table {
    padding-left: 150px;
    text-align: left;
    color: #2f3a51;
    position: relative;
    width: 59%;
    min-width: 500px;
    float: left;
}

.login-text {
    text-align: left;
    color: #2f3a51;
    margin-bottom: 30px;
}
.login-text h4 {
    font-weight: 600;
    font-size: 30px;
}

/* main page */

.page-head {
    position: sticky;
    top: 60px;
    padding-top: 13px;
    padding-bottom: 13px;
    background: #232e44;
    z-index: 9;
    margin-left: 82px;
    margin-bottom: 20px;
}
.page-head .container-fluid {
    padding-right: 25px;
    padding-left: 25px;
}

.page-content .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

.page-content {
    margin-left: 85px !important;
    margin-top: 20px !important;
    padding-bottom: 30px;
}

.page-content .form-control,
.page-head .form-control {
    background: #242d41;
    background-color: #242d41 !important;
    border-color: #36425c;
    color: #ffffff;
}

.form-control input[type="tel"] {
    background-color: #242d41 !important;
    color: #ffffff;
}
.form-control input[type="tel"]:disabled {
    background-color: #242d41 !important;
    color: #a2a2a2;
}

.page-content .row {
    margin-left: -5px;
    margin-right: -5px;
}

.page-content .no-checkbox {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: -5px;
}

.page-content .form-control:focus {
    border-color: #08a58f;
}
.page-content .input-group-text {
    background: #242d41;
    border-color: #36425c;
}

.page-content .refresh-zone {
    border: 1px solid rgba(235, 87, 87, 1);
    border-radius: 4px;
    padding-top: 1em;
    text-align: left;
    width: 350px;
    padding-left: 1em;
    flex-direction: column;
}
.page-content .refresh-zone .header {
    color: rgba(235, 87, 87, 1);
    font-weight: 500;
}
.page-content .refresh-zone .btn-refresh {
    cursor: pointer;
}

.widget-box.temperature,
.widget-box.light,
.widget-box.humidity,
.widget-box.gateway-storage,
.widget-box.gateway-status {
    height: 350px;
    margin-bottom: 10px;
}
.widget-box.alert-count {
    height: 350px;
    margin-bottom: 10px;
}

.widget {
    width: 100%;
    background: #2f3a51;
    border-radius: 6px;

    padding: 2px;
    position: relative;
    height: 100%;
}
.widget .title {
    padding-top: 25px;
    padding-left: 25px;
}
.widget .title h5 {
    margin-bottom: 2px;
}
.widget .title p {
    margin: 0;
    color: #828fab;
    font-size: 14px;
}

.widget .chart-box {
    height: 100% !important;
}
.alert-tab {
    position: absolute;
    top: 120px;
    left: 30px;
}
.alert-tab .btn-primary {
    font-size: 13px;
    padding: 4px 10px;
    background: #1f2738;
    border-color: #1f2738;
    color: #828fab;
}
.alert-tab .btn-primary.selected {
    background: #00cbaf;
    border-color: #00cbaf;
    color: #ffffff;
}
.dash-more .dropdown-toggle::after {
    display: none;
}
.dash-more .btn {
    padding: 6px 10px;
    line-height: 0;
}
.more-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #6f82aa;
    cursor: pointer;
}
.more-icon:after {
    display: none;
}
.last-data {
    position: absolute;
    right: 25px;
    top: 43px;
    z-index: 1;
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}
.last-data:hover {
    color: #08a58f;
}

.restricted-chart {
    position: absolute;
    bottom: 50%;
    text-align: center;
    width: inherit;
    height: 20px;
    color: #ffffff;
    z-index: 3;
    font-size: 14px;
    font-weight: 300;
}
.custom-range-info {
    position: absolute;
    top: 75px;
    right: 28px;
    width: 163px;
    height: 20px;
    line-height: 1.2;
    color: #828fab;
    z-index: 1;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
}

.widget-tool {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 12px;
    right: 25px;
}

.aggregate-icon {
    width: 25px;
    cursor: pointer;
    z-index: 5;
}

.aggregate-icon:hover {
    opacity: 0.75;
}

.refresh-drop {
    height: 25px;
    color: #ffffff;
    z-index: 3;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    bottom: 12px;
    right: 25px;
}

.widget-tool .refresh-drop {
    position: initial;
}

.refresh-rate {
    line-height: 0;
    padding: 0;
    color: #08b39b;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
}
.refresh-rate:hover {
    color: #08a58f;
}
.custom-refresh {
    padding: 0 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.custom-refresh.disabled {
    opacity: 0.8;
}
.custom-refresh .form-control {
    background: #f0f0f0;
    border-color: #f0f0f0;
    padding: 0px 10px;
}
.custom-refresh .btn {
    padding: 0 10px;
}
.custom-refresh .form-label {
    font-size: 13px;
    font-weight: 400;
    color: #08b39b;
}
.custom-refresh .material-icons {
    font-size: 13px;
}
.page-title {
    position: relative;
    float: left;
    margin-bottom: 25px;
    margin-right: 20px;
    margin-top: 5px;
}
.page-title i {
    color: #00cbaf;
    margin-top: 0px;
    margin-left: 10px;
    float: right;
}
.page-head .page-title {
    margin-bottom: 5px;
}
.search {
    width: 250px;
}
.search .form-control {
    background: #242d41;
    border-color: #36425c;
}
.search .form-control:focus {
    border: 1px solid #08a58f;
}
.search .btn {
    padding: 0px 10px;
}
.search .btn .material-icons {
    margin-top: 5px;
    font-size: 20px;
}

.cstm-table .table-head {
    border-top: 1px solid #2f3a51;
    border-bottom: 1px solid #2f3a51;
    padding: 15px 0;
    display: inline-block;
    width: 100%;
}
.table-head .checkmark,
.table-row .checkmark {
    margin-left: 20px;
}
.cstm-table .table-row {
    padding: 15px 0;
    background-color: #2f3a51;
    border-radius: 4px;
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    word-break: break-all;
}
.cstm-table .table-row i.right {
    float: right;
    margin-right: 20px;
    color: #00cbaf;
}
.cstm-table .table-row i.circle {
    font-size: 12px;
    margin-right: 10px;
}
.cstm-table .table-row i.circle.online {
    color: #219653;
}
.cstm-table .table-row i.circle.offline {
    color: #f2495e;
}
.cstm-table .table-row .reading {
    color: #00cbaf;
}
.cstm-table .table-row .reading-label {
    display: none;
}
.cstm-table .table-row span.default {
    color: #828fab;
}
.cstm-table .table-row span.custom {
    color: #00cbaf;
}
.cstm-table .table-row .btn {
    padding: 1px 10px;
}
.cstm-table .custom-control-label {
    margin-left: 15px;
}
.cstm-table .table-row .standalone {
    opacity: 0.25;
}

.change-dashboard {
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    padding: 0;
    text-decoration: none;
    line-height: 1.3;
}
.change-dashboard:after {
    display: none;
}
.change-dashboard:hover,
.change-dashboard:focus {
    text-decoration: none;
    color: #ffffff;
    box-shadow: none;
}
.dashboard-drop {
    padding: 25px;
    background: #2f3a51;
    z-index: 103;
    border-radius: 5px;
    width: 320px;
}
.dashboard-drop.show {
    display: block;
}
.dashboard-drop .btn-primary {
    width: 100%;
}
.dashboard-drop .search {
    margin-top: 10px;
    width: 100%;
}
.dashboard-drop .search .form-control {
    padding-left: 15px;
}

.dashboard-list {
    list-style: none;
    padding-left: 0;
    float: left;
    width: 100%;
    margin-top: 15px;
    max-height: calc(100vh - 40vh);
    overflow-y: auto;
    overflow-x: hidden;
}
.dashboard-list .dropdown-item {
    margin: 10px 0;
    float: left;
    width: 100%;
    padding: 0;
}
.dashboard-list .dropdown-item:hover {
    background: none;
    opacity: 1;
}
.dashboard-list .dropdown-item:hover h6 {
    color: #00cbaf;
}
.dashboard-list li a {
    text-decoration: none;
}
.dashboard-list .dash-icon {
    border-radius: 50px;
    float: left;

    text-align: center;
    background-color: rgb(57 70 98);
}
.dashboard-list .dash-icon svg {
    padding: 10px;
    width: 40px;
    height: 40px;
}

.form-box {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.form-box-head {
    padding: 20px 30px 15px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 5px 5px 0 0;
    margin-bottom: 1px;
}
.form-box-head + .form-box {
    border-radius: 0;
}
.form-box-head h5 {
    margin-top: 6px;
}
.form-box-head h5,
.form-box-head h6,
.form-box-head p {
    margin-bottom: 0;
}
.form-box-head p {
    color: #828fab;
}

.form-box-footer {
    padding: 20px 30px 20px;
    float: left;
    width: 100%;
    background: #2f3a51;
    border-radius: 0 0 5px 5px;
    margin-top: 1px;
}

.form-box .custom-select {
    color: #ffffff;
    border-color: #36425c;
    background-color: #242d41;
}
.form-box hr {
    border-color: #3e4b67;
}

.form-box.permission {
    padding: 0px;
    background: #3a4763;
    overflow: hidden;
    margin-bottom: 10px;
}
.form-box.permission .permission-box {
    padding: 20px 30px;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    color: #ffffff;
    position: relative;
}
.form-box.permission .permission-box i.down {
    position: absolute;
    right: 30px;
    top: 35%;
    color: #00cbaf;
}
.show .form-box.permission .permission-box i.down {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}
.form-box.permission p {
    color: #828fab;
    font-size: 14px;
    margin-top: 5px;
    font-style: italic;
    margin-bottom: 0;
}
.permission-item {
    background: #2f3a51;
    padding: 20px 30px;
    border-bottom: 1px solid #34435f;
}
.permission-item:last-child {
    border-bottom: none;
}
.permission-item h6 {
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 2px;
}
.recipient-option {
    border-bottom: 1px solid #3e4b67;
    border: 1px solid #3e4b67;
    border-radius: 5px;
    padding: 20px;
    transition: ease 0.5s;
}
.recipient-option.disabled {
    opacity: 0.5 !important;
}
.recipient-option.disabled:hover {
    pointer-events: none !important;
}
.recipient-option .custom-switch {
    padding-left: 40px;
}
.recipient-option label {
    cursor: pointer;
}
.recipient-option.show {
    border-radius: 5px 5px 0 0;
    transition: ease 0.5s;
}
.recipient-box {
    border: 1px solid #3e4b67;
    border-top: 0px;
    border-radius: 0 0 5px 5px;
    padding: 20px;
    display: none;
    transition: all 0.5s;
}
.recipient-box.show {
    display: block;
    transition: all 0.5s;
}
.recipient-box .added-item {
    float: left;
    padding: 10px 20px;
    padding-right: 12px;
    background: #3a4762;
    border-radius: 10px 15px;
    font-size: 15px;
    margin-right: 5px;
    margin-bottom: 10px;
}
.recipient-box .added-item span {
    float: right;
    color: #f2495e;
    font-size: 20px;
    margin-left: 7px;
    margin-top: 1px;
    cursor: pointer;
}
.recipient-box .added-item.sendgateway {
    border-radius: 10px;
    padding: 15px 20px;
    padding-right: 12px;
}
.recipient-box .added-item.sendgateway .gate-name {
    float: left;
}
.recipient-box .added-item.sendgateway h6 {
    margin-bottom: 0;
    font-weight: 400;
}
.recipient-box .added-item.sendgateway p {
    margin-bottom: 0;
}
.recipient-box .added-item.sendgateway .gatewayuuid {
    font-size: 13px;
    color: #ffffff;
    opacity: 0.5;
    float: left;
    margin-left: 0;
}
.recipient-box .added-item.sendgateway span {
    margin-left: 15px;
    margin-top: 10px;
}
.sensor-summary {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
}
.sensor-summary p {
    margin: 0;
    color: #828fab;
}
.sensor-summary h2 {
    color: #00cbaf;
}
.sensor-summary .sensor-info p {
    margin: 15px 0px 0 20px;
}
.sensor-summary p span {
    color: #ffffff;
    font-weight: 500;
}

.btn-chart-type {
    padding: 10px 15px;
}
.btn-chart-type svg {
    margin-right: 10px;
}

/* empty */
.empty-widget {
    padding: 150px 0;
    width: 100%;
    background: #2f3a51;
    border-radius: 6px;
    margin-bottom: 10px;
}
.empty-widget h5 {
    color: #828fab;
}

/* colors */
.bg-red {
    background-color: #f2495e;
}
.bg-orange {
    background-color: #f2994a;
}
.bg-yellow {
    background-color: #f2c94c;
}
.bg-green {
    background-color: #219653;
}
.bg-blue {
    background-color: #2f80ed;
}
.bg-sky {
    background-color: #56ccf2;
}
.bg-magenta {
    background-color: #bb6bd9;
}
.bg-magenta2 {
    background-color: #41b2af;
}
.bg-blue2 {
    background-color: #41b2af;
}
.bg-pink {
    background-color: #df54a1;
}
.bg-green2 {
    background-color: #82c771;
}

.create-widget-option {
    width: 100%;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 35px;
    color: rgb(47, 58, 81);
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    border: 2px solid rgb(234, 234, 234);
}
.select-widget-type .modal-dialog {
    max-width: 350px;
}
.modal-body.widget-options {
    padding: 50px;
}
.modal-header .close-button {
    float: right;
    font-size: 1.3rem;
    font-weight: 200;
    line-height: 1;
    color: #fff;
    opacity: 1;
    padding: 0;
}

.modal-body {
    padding: 1.4rem;
}
.modal-body h4 {
    font-size: 18px;
    color: #2f3a51;
}
.modal-body > * {
    margin: 0.25rem;
}
.modal-footer {
    border-top: none;
    padding: 1rem 1.4rem;
    -webkit-box-shadow: -4px -1px 14px 3px rgba(0, 0, 0, 0.1);
    box-shadow: -4px -1px 14px 3px rgba(0, 0, 0, 0.1);
}
.modal-footer .btn {
    padding: 6px 20px;
    font-weight: 500;
}
.modal-dialog .form-control {
    background: #f0f0f0;
    border-color: #f0f0f0;
    padding: 20px 20px;
    color: #2f3a51;
}
.modal-dialog .dash-icon {
    border-radius: 50px;
    float: left;
    margin: 15px 40px 15px 0;
    position: relative;
    cursor: pointer;
    text-align: center;
}
.modal-dialog .dash-icon .done {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background-color: #00cbaf;
    padding: 5px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 50px;
    border: 2px solid #ffffff;
}
.modal-dialog form .icons .dash-icon:nth-child(4n) {
    margin-right: 0px;
}
.modal-dialog .dash-icon svg {
    padding: 15px;
    width: 55px;
    height: 55px;
}
.new-dash .btn-create {
    margin-top: 50px;
    font-weight: 500;
    padding: 12px 20px;
}
.new-dash .modal-dialog .form-control {
    padding: 25px 20px;
}
.new-dash .modal-footer .btn {
    padding: 13px 20px;
    font-weight: 500;
}
.modal-option {
    padding: 4px 10px;
    background: #ffffff;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    margin: 5px 0;
    width: 100%;
    float: left;
}
.modal-option.disabled {
    opacity: 0.5;
}

.modal-option.disabled:hover {
    opacity: 0.5 !important;
    pointer-events: none;
}

.modal-option .custom-radio,
.modal-option .custom-checkbox {
    padding: 6px 30px;
    font-size: 16px;
    float: left;
    width: 100%;
    cursor: pointer;
}
.modal-option .custom-control-label {
    color: #2f3a51;
    padding-left: 12px;
    width: 100%;
    cursor: pointer;
}
.modal-option .custom-control-label:before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}
.modal-option .custom-control-label:before,
.modal-option .custom-control-label:after {
    top: 28%;
}
.modal-option h4 {
    font-weight: 400;
    margin-bottom: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-option .select-gw-name {
    font-size: 16px;
}

.modal-option .select-gw-id {
    font-size: 12px;
}

.modal-option p {
    color: #828fab;
    opacity: 0.75;
    margin-bottom: 0;
}

.condition-options-list .modal-option:hover {
    cursor: pointer;
}

.modal-body .scroll-list {
    max-height: 65vh;
    overflow-y: auto;
    width: 100%;
}

.modal-body .scroll-multiple-list {
    margin-top: 6px;
    max-height: 65vh;
    overflow-y: auto;
    width: 100%;
}

.modal-body .scroll-multiple-list > div {
    padding-top: 5px;
    display: inline-block;
    width: inherit;
    margin-bottom: 12px;
}

.modal-body .scroll-multiple-list > div > h4 {
    margin-bottom: 0px !important;
}

.modal-body .scroll-list .custom-control-label:before {
    width: 22px;
    height: 21px;
}
.modal-body .scroll-list .custom-control-label:after {
    width: 21px;
    height: 21px;
}
.no-header .modal-content {
    border-radius: 12px;
}
.modal h3 {
    color: #2f3a51;
    font-size: 25px;
}
.modal p,
.modal div[role="alert"] {
    color: #2f3a51;
}
.modal-body > .btn + .btn {
    margin: 0.25rem;
}
.modal-icon-box {
    margin-top: -65px;
    margin-bottom: 25px;
}
.modal-icon-box span {
    color: #ffffff;
    font-size: 25px;
    padding: 20px;
    border-radius: 52px;
    background: #00cbaf;
}
.danger .modal-icon-box span {
    background: #f2495e;
}

.page-tabs .nav {
    background: #212b40;
    padding: 0 30px;
}
.page-tabs .nav-item {
    width: 250px;
}
.page-tabs .nav-item .nav-link {
    text-align: center;
    padding: 20px 10px;
    position: relative;
    transition: all 0.5s;
}
.page-tabs .nav .nav-item .nav-link .count {
    font-size: 14px;
    font-weight: 400;
    padding: 12px 8px;
    background: #2f3a51;
    border-radius: 50%;
    margin-left: 15px;
    color: #828fab;
    width: 40px;
    height: 40px;
    display: inline-block;
}
.page-tabs .nav-item .nav-link.active .count {
    background: #00cbaf;
    color: #ffffff;
}
.page-tabs .nav-item .nav-link:hover {
    opacity: 1;
}
.page-tabs .nav-item .nav-link h6 {
    margin: 0;
    font-size: 18px;
}
.page-tabs .nav-item .nav-link.active {
    background: #171f30;
    color: #00cbaf;
    position: relative;
    transition: all 0.5s;
}
.page-tabs .nav-item .nav-link.active:after {
    position: absolute;
    width: 100%;
    height: 4px;
    background: #00cbaf;
    top: -5px;
    left: 0;
    content: "";
    transition: all 0.5s;
}

.current-data {
    position: absolute;
    z-index: 2;
    left: 15px;
    top: 40px;
    pointer-events: none;
}
.current-data-fw {
    position: absolute;
    z-index: 2;
    left: 2%;
    top: 10%;
}

.loader-gif {
    position: absolute;
    z-index: 3;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
}

.live-data-text {
    display: flex;
    margin-bottom: 0px;
}

.live-data-time {
    color: #828fab;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    margin-left: 5px;
    margin-top: 1.5px;
}

.modal-select-items {
    width: 100%;
    border: 2px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 3%;
}
.modal-radio-button {
    display: inline-block;
    margin: 1.5%;
}
.modal-selection-items-text {
    display: inline-block;
    margin: 2.5%;
}

.label-select-text {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #2f3a51;
    align-items: center;
}
.label-selection-subtext {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 14px;
    align-items: center;
    color: #828fab;
}

.select-items {
    font-family: "Roboto", sans-serif;
    width: 100%;
    background: rgba(0, 203, 175, 0.1);
    border: 2px solid #00cbaf;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    padding: 5px;
    float: left;
}
.select-items span {
    top: 32%;
    right: 15px;
    position: absolute;
    cursor: pointer;
    opacity: 0.7;
}
.select-items-text {
    color: #ffffff;
    font-size: 16px;
    align-items: center;
    mix-blend-mode: normal;
    line-height: 19px;
    margin: 10px;
}
.select-items-subtext {
    color: #ffffff;
    font-size: 12px;
    mix-blend-mode: normal;
    line-height: 14px;
    margin: 10px;
    opacity: 0.75;
}
.switch-drop .dropdown-item h6 {
    width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 30px 0px 0px;
    float: left;
    color: #ffffff;
    position: relative;
}

.switch-drop .dropdown-item h6.admin span.star {
    color: #f2c94c;
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 19px;
    display: block;
}

.cstm-table .table-row .btn-remove {
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 0;
    color: #f2495e;
}

.dashboard-list h6 {
    width: calc(100% - 60px);
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    float: left;
    margin: 10px 0;
    margin-left: 15px;
    color: #ffffff;
}

.selected-dashboard-name {
    float: left;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.org-name {
    margin-top: 15px;
    margin-left: 15px;
}
.table-head .group-item .group-item-name,
.table-row .group-item .group-item-name {
    padding-left: 30px;
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
}
.header-balance-details {
    margin-top: 15px;
    margin-right: 15px;
}
.header-balance-details p {
    font-size: 14px;
    color: #828fab;
    margin-bottom: 0;
}
.header-balance-details h5 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: -2px;
    text-align: left;
    color: #ffffff;
}
.header-balance-details h5 span {
    color: #00cbaf;
}

.new-sensor-chart .sensor-details-item {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    height: 60px;
}
.new-sensor-chart .sensor-details-item h6 {
    margin: 0;
    font-weight: 400;
    line-height: 60px;
}
.new-sensor-chart .sensor-details-item .unit-drop {
    float: right;
    width: 100px;
    margin-top: 10px;
}
.new-sensor-chart .sensor-details-item .color-selection {
    float: right;
    margin-top: 10px;
    background: #242d41;
    border-color: #36425c;
    padding: 6px 10px;
}
.new-sensor-chart .sensor-details-item .color-selection:focus,
.new-sensor-chart
    .sensor-details-item
    .color-selection.btn-primary:not(:disabled):not(.disabled):active {
    box-shadow: none;
    background: #242d41;
    border-color: #36425c;
}
.new-sensor-chart .sensor-details-item .color-selection .selected-color {
    margin: 2px;
    width: 60px;
    height: 20px;
    float: left;
    border-radius: 2px;
}
.new-sensor-chart .sensor-details-item .color-option {
    width: 100%;
    height: 25px;
    border-radius: 3px;
}
.new-sensor-chart .sensor-details-item .dropdown {
    float: right;
}
.new-sensor-chart .sensor-details-item .dropdown-menu {
    min-width: 100px;
}
.new-sensor-chart .sensor-details-item .dropdown-item {
    padding: 0.25rem 0.5rem;
}
.new-sensor-chart .sensor-details-item .alert-switch {
    float: right;
}
.new-sensor-chart .sensor-details-item .alert-switch .custom-control-label {
    padding: 6px;
    margin-top: 15px;
}
p.add-sensor-details {
    float: left;
    color: #828fab;
    font-size: 14px;
    margin-top: 10px;
    margin-left: 15px;
}
.add-new-chart {
    float: left;
}
.new-sensor-chart .sensor-title .sensor-close {
    float: right;
    color: #f2495e;
    cursor: pointer;
}

/* daterangepicker  */
.daterangepicker {
    color: black !important;
}

.daterangepicker td.in-range {
    background-color: rgba(0, 203, 175, 0.1) !important;
}

.daterangepicker td.active {
    background-color: rgba(0, 203, 175, 1) !important;
}

.daterangepicker .ranges {
    overflow-y: scroll;
    max-height: 250px;
}

.daterangepicker .ranges li.active {
    background-color: rgba(0, 203, 175, 1) !important;
}

fieldset[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-gray {
    color: var(--create-chart-form-secondary);
}

.text-gray-important,
.text-gray-important label {
    color: var(--create-chart-form-secondary) !important;
}

.color-picker__toggle,
.unit-picker__toggle {
    min-width: 76px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: unset;
    padding: 10px !important;
    border: none;
    box-shadow: none !important;
}

.color-picker__toggle > div {
    width: 32px;
    height: 17px;
}

.color-picker .dropdown-menu {
    min-width: unset !important;
}

.color-picker .dropdown-menu .dropdown-item {
    padding: 0.25rem 0.5rem;
}

.color-picker__input {
    width: 6rem !important;
}

.unit-picker .dropdown-menu {
    background-color: var(--create-chart-form-secondary);
}

.unit-picker__input {
    color: white;
    border: none;
}

.unit-picker .dropdown-item:hover {
    background-color: var(--create-chart-form-secondary);
    text-decoration: none;
}

.text-nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.multiple-chart-block {
    background-color: var(--create-chart-from-background);
}

.multiple-chart-block .row {
    position: relative;
}

.multiple-chart-block--delete {
    position: absolute;
    color: #f2495e;
    top: 0;
    right: 0;
    cursor: pointer;
}

.multiple-chart-block + .multiple-chart-block {
    margin-top: 1rem;
}

.multiple-chart-block .form-control {
    background-color: var(--create-chart-form-secondary);
}

.multiple-chart-block__select-radio {
    padding: 1rem 2.5rem;
    border: 2px solid var(--create-chart-form-secondary);
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
}

.multiple-chart-block__select-radio .custom-control-label {
    padding-left: 10px;
}

.multiple-chart-block hr {
    border-color: var(--create-chart-form-secondary);
}

.multiple-chart-block__select-radio--active {
    border: 2px solid var(--create-chart-form-active);
}

.multiple-chart-block__panel {
    background-color: var(--create-chart-multiple-sensor-background);
}

.multiple-chart-block__panel .multiple-chart-label-wrapper {
    max-width: 80px;
}

#toggle-nav-dropdown {
    opacity: 1 !important;
}

@media (max-width: 576px) {
    .multiple-chart-block .delete-block {
        justify-content: flex-end;
        padding-top: 1rem;
    }

    .multiple-chart-block .delete-block img {
        position: relative;
    }
}

.update-chart-panel .actuator-chart-label-wrapper {
    max-width: 110px;
}

#dashboard-grid.grid-stack .widget-box {
    width: 100%;
    height: 400px !important;
    max-width: 100%;
}

.fs-38 {
    font-size: 38px;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-action {
    display: flex;
}

.dashboard-nav {
    margin-left: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.panel-card-error {
    font-size: 14px;
    color: red;
}

.new-dash .dashboard-name-error-msg {
    margin-top: -30px;
    margin-bottom: 10px;
}

.dashboard-color-error-msg {
    clear: both;
    color: #dc3545 !important;
    font-size: 80%;
}

.chart-col {
    background-color: var(--create-chart-deep-button-background) !important;
    outline: var(--create-chart-deep-button-border) solid 1px !important;
}

.unit-picker-toggle {
    background-color: var(--create-chart-form-secondary) !important;
}

.color-picker .color-picker-dropdown-menu {
    background-color: transparent;
    padding: 0;
}
