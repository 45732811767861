@media screen and (max-width: 545px) {
    .custom-daterangepicker.opensright:before {
        left: auto;
        right: 9px;
    }

    .custom-daterangepicker.opensright:after {
        left: auto;
        right: 10px;
    }
}
