.spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #00cbaf !important;
}

.verify-spinner {
    color: white !important;
    position: relative;
    top: 0%;
    left: 0%;
    height: 20px;
    width: 20px;
}

.loading-modal-title {
    color: #2f3a51;
    text-align: center;
    padding-top: 2rem;
    margin-bottom: -2rem;
}
