.selected-roles .row {
    background: #3a4763;
    border-radius: 4px;
}

.selected-roles .remove-role-button {
    width: 35px;
    height: 35px;
    padding: 0;
    font-size: 0;
    color: #f2495e;
}

.role-type {
    color: #828fab;
}
