:root {
    --btn-mute-bg: #242d41;
    --btn-mute-outline: #36425c;
    --btn-mute-active: #00cbaf;
    --checkbox-color: #dddddd;
}

h6.ginfo,
div.ginfo {
    color: #828fab;
}

.gateway-header-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
}
.gateway-details-box {
    background: #2f3a51;
    padding: 40px;
    border-radius: 5px;
}
.gateway-ports {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    justify-content: center;
    grid-gap: 10px;
}

.gateway-port {
    height: 100%;
    background-color: #3e4b67;
    border: 1px solid #00cbaf;
    border-radius: 5px;
    min-width: 220px;
    min-height: 100px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.gateway-port .top {
    justify-content: space-between;
    flex: 1;
}

.gateway-port .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid #242d41;
    padding-top: 10px;
    flex: 1;
}

.gateway-port .top i {
    float: right;
    color: #00cbaf;
}

.gateway-info h6.ginfo {
    color: #828fab;
    margin-bottom: 0;
    word-break: break-all;
}
.gateway-info i.circle,
.gateway-detail-item.bottom i.circle,
.gateway-port .bottom i.circle {
    font-size: 12px;
    margin-right: 10px;
}
.gateway-info i.circle.online,
.gateway-detail-item.bottom i.circle.online,
.gateway-port .bottom i.circle.online {
    color: #219653;
}
.gateway-info i.circle.offline,
.gateway-detail-item.bottom i.circle.offline,
.gateway-port .bottom i.circle.offline {
    color: #f2495e;
}
.gateway-details-tabs {
    background: #2f3a51;
    border-radius: 5px;
}
.gateway-details-tabs .nav-item {
    width: 20%;
    text-align: center;
}
.gateway-details-tabs .nav-item .nav-link {
    padding: 20px 0;
    border-bottom: 1px solid #3e4b67;
    position: relative;
}

.gateway-details-tabs .nav-item .nav-link::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 2px;
    background: #00cbaf;
    transition: 0.5s;
}
.gateway-details-tabs .nav-item:hover .nav-link {
    opacity: 1;
}
.gateway-details-tabs .nav-item .nav-link.active::after {
    width: 100%;
}
.gateway-details-tabs .nav-item .nav-link h6 {
    margin-top: 10px;
    margin-bottom: 0;
}
.gateway-details-tabs .nav-item .nav-link.active h6 {
    color: #00cbaf;
}
.gateway-details-tabs .tab-pane {
    padding: 30px;
}

.storage-box {
    border-right: 1px solid #3e4b67;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.storage-box h5 {
    margin-bottom: 40px;
}

.percentage-circle {
    position: relative;
    margin: auto;
}
.percentage-circle__circle {
    stroke-dashoffset: 0;
    transform-origin: 50%;
    position: absolute;
    transform: rotate(-90deg);
}
.percentage-circle__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.percentage-circle-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 50px;
}
.percentage-circle-info h5 {
    margin-bottom: 0;
    font-weight: 400;
}
.percentage-circle-info p {
    font-size: 15px;
    color: #828fab;
}
.storage-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
.storage-button .btn {
    width: 200px;
}

.update-info {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 40px;
}
.update-info .gateway-info {
    width: 100%;
}
.update-option {
    padding: 10px;
    background: #3a4763;
    border-radius: 5px;
    margin: 5px 0;
    width: 100%;
    float: left;
}
.update-option .custom-radio,
.update-option span {
    padding: 6px 30px;
    font-size: 16px;
    float: left;
}
.update-option .custom-control-label {
    padding-left: 10px;
    width: 250px;
}
.descriptor-item {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    padding: 15px 0;
}
.descriptor-item h6 {
    margin: 0;
}

.download-data-box {
    text-align: left;
    padding: 30px;
}
.download-data-box .btn,
.download-sensor-data .btn {
    padding: 12px;
}
.download-data-box .btn img,
.download-sensor-data .btn img {
    margin-right: 5px;
}
.download-data-option {
    padding: 20px 20px;
    background: #3e4b67;
    border-radius: 5px;
    margin-bottom: 10px;
}
.descriptor-box {
    padding-bottom: 80px !important;
}

.MuiOutlinedInput-root,
.MuiInputBase-colorPrimary,
.MuiInputBase-formControl,
.MuiInputBase-adornedEnd,
.css-1bn53lx,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-i4bv87-MuiSvgIcon-root,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.MuiSvgIcon-root {
    color: #828fab !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 15px !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.MuiFormControl-root,
.MuiTextField-root,
.css-i44wyl {
    background: #242d41 !important;
    flex: 1 !important;
}

.dateTimeFieldWrapper {
    width: 100%;
    display: flex;
    height: fit-content;
    margin-bottom: 1rem;
}

.dateTimeFieldWrapper.inline {
    width: 50%;
    flex-direction: column;
    padding-right: 1rem;
}

.dateTimeFieldWrapper:nth-child(2) {
    padding-right: 0;
}

.dateTimeField {
    line-height: 55px;
    flex-basis: 50px;
    margin-left: 32px;
}

.dateTimeFieldWrapper.inline .dateTimeField {
    margin-left: 0;
    color: #2f3a51;
}

.dateTimeFieldWrapper.inline .MuiFormControl-root {
    background: #f0f0f0!important;
    border-radius: 4px;
}

.dateTimeFieldWrapper.inline .MuiFormControl-root .MuiOutlinedInput-root input, 
.dateTimeFieldWrapper.inline .MuiFormControl-root .MuiOutlinedInput-root svg {
    color: #4c4c4c!important;
}

.sensor-association-box .form-box {
    border-radius: 5px 5px 0 0;
}
.sensor-association-box .form-row {
    border-bottom: 1px solid #3e4b67;
    padding-bottom: 20px;
}
.sensor-association-box .form-row:first-child,
.sensor-association-box .form-row:last-child {
    border-bottom: none;
}
.sensor-association-box h6 {
    font-weight: 400;
}
.sensor-association-box p {
    margin: 0;
    color: #828fab;
}
.sensor-association-box .selected-sensor {
    padding: 20px;
    background: #3a4763;
    margin-top: 10px;
    border-radius: 5px;
}

.cstm-table .table-row .btn.override {
    background: rgba(0, 203, 175, 0.5);
    border: 1px solid #00cbaf;
    padding: 3px 18px;
    font-size: 14px;
}

.actuator-summary {
    background: #2f3a51;
    padding: 30px;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.actuator-summary p {
    margin: 0;
    color: #828fab;
}
.actuator-summary h2 {
    color: #00cbaf;
    float: left;
}
.actuator-summary .sensor-info {
    float: left;
}
.actuator-summary .sensor-info p {
    float: left;
    margin: 15px 0px 0 20px;
}
.actuator-summary .sensor-info h5 {
    margin-bottom: 0;
}
.actuator-summary p span {
    color: #ffffff;
    font-weight: 500;
}
.actuator-summary .reading {
    color: #ffffff;
    font-weight: 400;
}
.actuator-summary i {
    font-size: 12px;
    margin-right: 10px;
}
.actuator-summary i.circle.online {
    color: #219653;
}
.actuator-summary i.circle.offline {
    color: #f2495e;
}
.auto-mode {
    border-top: 1px solid #3e4b67;
    width: 100%;
    float: left;
    margin-top: 25px;
}
.polarity-selection {
    background: #3a4763;
    border-radius: 5px;
    text-align: center;
}
.polarity-selection.custom-control {
    padding-left: 0;
}
.polarity-selection .custom-control-label {
    width: 100%;
    padding: 40px 10px 20px;
}
.polarity-selection .custom-control-label::before {
    left: 15px;
    top: 15px;
}
.polarity-selection .custom-control-label::after {
    left: 15px;
    top: 15px;
}
.polarity-box {
    max-width: 700px;
    min-width: 400px;
    margin-bottom: 10px;
}

.minuteSecondsField {
    min-width: 66px !important;
    max-width: 100px;
}

.minuteSecondsFieldLabel {
    display: flex;
    align-items: center;
}

.btn-remove {
    color: #f2495e;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.btn-remove:hover {
    cursor: pointer;
}

.refresh-state {
    animation: spin 1000ms linear infinite;
}

.refresh-actuator:hover,
.refresh-sensor:hover,
.bigRefresh:hover {
    cursor: pointer;
}

.gateway-header {
    display: flex;
    justify-content: space-between;
}

.gateway-details-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.mini-button {
    padding: 4px 14px;
    height: 35px;
    white-space: nowrap;
}

.btn-mute {
    background-color: var(--btn-mute-bg);
    color: #ffffff;
    transition: all 0.2s ease-in-out;
}

.btn-mute:hover {
    color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
}
.btn-mute:active {
    box-shadow: none;
}

.add-prefix-suffix .symbol {
    color: var(--btn-mute-active);
    margin-right: 12px;
    font-size: 18px;
}

.open-modal .custom-control-label:before {
    background-color: #dddddd;
    border: 1px solid #dddddd;
}

.input-right-text input[type="number"]::-webkit-outer-spin-button,
.input-right-text input[type="number"]::-webkit-inner-spin-button {
    margin-right: 30px;
}

.input-right-text input + span {
    position: absolute;
    right: 20px;
    z-index: 3;
}

.input-right-text .input-group {
    align-items: center;
}

.Toastify__toast-container:has(#update_success) {
    width: 358px;
}

#update_success .Toastify__toast-body {
    align-items: flex-start;
}

#update_success .Toastify__toast-body > div:nth-child(2) {
    font-size: 11px;
    line-height: 16px;
}

#update_success.Toastify__toast--success .Toastify__toast-body svg {
    fill: var(--btn-mute-active);
}

#update_success.Toastify__toast--success
    .Toastify__toast-body
    > div:nth-child(2)::before {
    content: "Success";
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    color: var(--btn-mute-active);
}

#update_success .Toastify__progress-bar {
    opacity: 0 !important;
}

.fs-14 {
    font-size: 14px;
}

.scrollingContainer {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.versions-zone .scrollingContainer {
    height: auto;
}

.scrollingContainer::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
}

.scrollingContainer::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}

.scrollingContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
    background-color: #f5f5f511;
}

#lastUpdateDate {
    display: flex;
    justify-content: center;
}

#lastUpdateStatus {
    display: flex;
    justify-content: flex-end;
}

.selectionSubtext {
    font-size: 12px;
    line-height: 1.2rem;
}

.uart-reset {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
    text-align: right;
}

.uart-reset h6 {
    font-weight: 400;
}

.uart-reset .reset-btn {
    width: 128px;
}

.gateway-detail-container {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: (auto, 80px);
    gap: 2rem;
}

.gateway-detail-bottom-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 80px;
    gap: 1rem;
}

.gateway-detail-item.bottom.last {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ldsuList-row,
.gatewayList-row {
    display: flex;
    width: 100%;
    height: fit-content !important;
    align-items: center;
}

.ldsuList-row-start {
    flex: 1;
}

.ldsuList-row-middle {
    flex: 9;
    display: flex;
    align-items: center;
}
.ldsuList-row-end {
    flex: 2;
    display: flex;
    align-items: center;
}

.ldsuList-row-ldsu-name {
    flex: 1;
}

.ldsuList-row-uuid {
    flex: 1;
}

.ldsuList-row-status {
    flex: 1;
}

.ldsuList-row-reporting-rate {
    flex: 1;
}

.ldsuList-row-termination-status {
    flex: 1;
}

.ldsuList-row-delete-action {
    flex: 3;
    margin-left: 1rem;
    cursor: pointer;
}
.ldsuList-row-detail-action {
    flex: 1;
}

.ldsu-prefix-suffix-input {
    width: 222px;
}

.no-checkbox.ldsuList {
    margin-left: 1rem;
}

.gatewayList-row-middle {
    flex: 10;
    display: flex;
    align-items: center;
    padding-left: 5px;
}
.gatewayList-row-end {
    flex: 2;
    display: flex;
    align-items: center;
}

.gatewayList-row-group-name,
.gatewayList-row-group-members {
    flex: 5;
}

.gatewayList-row-name,
.gatewayList-row-uuid,
.gatewayList-row-last-active,
.gatewayList-row-group {
    flex: 2;
}

.gatewayList-row-standalone-gateway-name,
.gatewayList-row-standalone-gateway-uuid,
.gatewayList-row-standalone-gateway-last-active {
    flex: 3;
}

.gatewayList-row-delete-action,
.gatewayList-row-detail-action {
    flex: 1;
}

.ldsbus-buttons-container,
.ldsu-buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.alert-modal h3 {
    color: #000000;
}

.report-rate-unit,
.analog-config-unit {
    position: absolute;
    top: 8px;
    right: 20px;
}

.no-gateways-text {
    text-align: center;
    border-radius: 4px;
    background-color: #2f3a51;
    padding: 19px 20px 19px 20px;
}

.gateway-ports .refresh-icon {
    cursor: pointer;
}

.gw-group-btn {
    width: 120px;
}

.actuator-modal-error-msg {
    left: 5%;
    position: absolute;
    color: #ff0000;
}

.check-before-configure-msg {
    font-weight: 600;
    color: #2f3a51;
}

.form-box-footer .save-btn {
    width: 120px !important;
}

.select-sensor-error-msg {
    left: 5%;
    position: absolute;
    color: #ff0000;
}

.not-onboard-notification {
    font-size: 18px;
}

.location-box .map-container {
    height: 450px;
    width: 100%;
}

.location-box .edit-mode-button {
    width: 225px;
}

.location-box .loader-gif {
    top: 50%;
}

.sensor-summary .last-active .last-active-date-time {
    font-weight: 400;
}

.recipient-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}

.mobile-chart-info {
    display: none;
}

.desktop-chart-info {
    display: block;
}

@media (max-width: 1800px) {
    .timeFields {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media (max-width: 1400px) {
    .single-sensor-chart .table-head {
        display: none;
    }

    .single-sensor-chart .gateway-chart-info .row > div {
        height: auto !important;
    }
    
    .single-sensor-chart .gateway-chart-info .custom-checkbox {
        padding: 12px !important;
    }

    .single-sensor-chart .mobile-creating-chart {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .single-sensor-chart .tick-icon {
        align-self: flex-end;
    }

    .single-sensor-chart .mobile-chart-label {
        font-weight: 600;
    }

    .single-sensor-chart .mobile-chart-col {
        height: 30px !important;
    }

    .single-sensor-chart .mobile-chart-col .color-picker__toggle {
        padding: 0 !important;
    }

    .single-sensor-chart .mobile-creating-chart .flex-basis-220px, .single-sensor-chart .mobile-creating-chart .flex-basis-120px {
        flex: none !important;
    }

    .single-sensor-chart .mobile-creating-chart .mobile-chart-col {
        height: auto !important;
    }

    .single-sensor-chart .mobile-chart-info {
        display: block;
    }

    .single-sensor-chart .mobile-chart-info #chart-edit-form {
        width: 100%;
    }
    
    .single-sensor-chart .mobile-chart-info .gateway-chart-info {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}


@media (max-width: 992px) {
    .storage-box {
        border-right: 0px;
    }

    .gateway-chart-info .row {
        margin: 0;
    }

    .selected-gateway-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .selected-gateway-info {
        display: flex;
        flex-direction: column;
        border: 1px solid #2F3A51;
        margin-bottom: 10px;
        width: 100%;
        border-radius: 5px;
        padding: 20px;
        gap: 20px;
    }

    .gateway-chart-info {
        padding: 20px !important;
    }

    .selected-gateway-header i {
        padding-right: 0;
    }

    .field-group {
        display: flex;
        justify-content: space-between;
    }
    
    .field-group > * {
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .mobile-chart-info {
        display: block;
    }

    .desktop-chart-info {
        display: none;
    }    
    
    .mobile-chart-info #chart-edit-form {
        width: 100%;
    }
    
    .mobile-chart-info .gateway-chart-info {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }

    .cstm-table .gateway-chart-info .row > div {
        height: auto !important;
    }
    
    .cstm-table .gateway-chart-info .custom-checkbox {
        padding: 12px !important;
    }

    .mobile-creating-chart {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .tick-icon {
        align-self: flex-end;
    }

    .mobile-chart-label {
        font-weight: 600;
    }

    .mobile-chart-col {
        height: 30px !important;
    }

    .mobile-chart-col .color-picker__toggle {
        padding: 0 !important;
    }

    .mobile-creating-chart .flex-basis-220px, .mobile-creating-chart .flex-basis-120px {
        flex: none !important;
    }

    .mobile-creating-chart .mobile-chart-col {
        height: auto !important;
    }
}

@media (max-width: 768px) {
    .gateway-header,
    .uart-config-subheader {
        flex-direction: column;
    }
    .gateway-detail-button {
        display: flex;
        flex-grow: 1;
    }

    .gateway-detail-button button {
        flex: 1;
    }

    .mini-button {
        margin-bottom: 0.5rem;
    }

    #lastUpdateDate {
        justify-content: flex-start;
    }

    #lastUpdateStatus {
        justify-content: flex-start;
    }

    .uart-reset {
        justify-content: space-between;
        align-items: end;
        text-align: left;
        font-size: 0.7rem;
    }
}

@media (max-width: 400px) {
    .Toastify__toast-container:has(#update_success) {
        width: 100%;
    }
    .polarity-box {
        margin-bottom: 10px;
    }
}
