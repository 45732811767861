.react-tel-input .form-control {
    width: 100%;
    border: 1px solid #e8e8e8;
    padding-left: 70px;
}
.react-tel-input .selected-flag {
    width: 60px;
    padding-left: 20px;
}

.react-tel-input .flag-dropdown {
    background-color: #ffffff;
    border-color: #e8e8e8;
}
