.continuous-alert-option-label {
    display: flex;
    align-items: center;
}

.continuous-alert-option-label > label {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .continuous-alert-option-label > label {
        margin-bottom: 0.5rem;
    }
}
