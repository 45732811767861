:root .grid-stack-item>.ui-resizable-handle {
    filter: none;
}

.grid-stack {
    position: relative;
}

.grid-stack.grid-stack-rtl {
    direction: ltr;
}

.grid-stack.grid-stack-rtl>.grid-stack-item {
    direction: rtl;
}

.grid-stack .grid-stack-placeholder>.placeholder-content {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0;
    position: absolute;
    width: auto;
    z-index: 0 !important;
    text-align: center;
}

.grid-stack>.grid-stack-item {
    width: 100%;
    position: absolute;
    padding: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-handle {
    position: absolute;
    font-size: 0.1px;
    display: block;
    -ms-touch-action: none;
    touch-action: none;
}

.grid-stack>.grid-stack-item.ui-resizable-disabled>.ui-resizable-handle,
.grid-stack>.grid-stack-item.ui-resizable-autohide>.ui-resizable-handle {
    display: none;
}

.grid-stack>.grid-stack-item>.ui-resizable-se,
.grid-stack>.grid-stack-item>.ui-resizable-sw {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDUxMS42MjYgNTExLjYyNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTExLjYyNiA1MTEuNjI3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTMyOC45MDYsNDAxLjk5NGgtMzYuNTUzVjEwOS42MzZoMzYuNTUzYzQuOTQ4LDAsOS4yMzYtMS44MDksMTIuODQ3LTUuNDI2YzMuNjEzLTMuNjE1LDUuNDIxLTcuODk4LDUuNDIxLTEyLjg0NSAgIGMwLTQuOTQ5LTEuODAxLTkuMjMxLTUuNDI4LTEyLjg1MWwtNzMuMDg3LTczLjA5QzI2NS4wNDQsMS44MDksMjYwLjc2LDAsMjU1LjgxMywwYy00Ljk0OCwwLTkuMjI5LDEuODA5LTEyLjg0Nyw1LjQyNCAgIGwtNzMuMDg4LDczLjA5Yy0zLjYxOCwzLjYxOS01LjQyNCw3LjkwMi01LjQyNCwxMi44NTFjMCw0Ljk0NiwxLjgwNyw5LjIyOSw1LjQyNCwxMi44NDVjMy42MTksMy42MTcsNy45MDEsNS40MjYsMTIuODUsNS40MjYgICBoMzYuNTQ1djI5Mi4zNThoLTM2LjU0MmMtNC45NTIsMC05LjIzNSwxLjgwOC0xMi44NSw1LjQyMWMtMy42MTcsMy42MjEtNS40MjQsNy45MDUtNS40MjQsMTIuODU0ICAgYzAsNC45NDUsMS44MDcsOS4yMjcsNS40MjQsMTIuODQ3bDczLjA4OSw3My4wODhjMy42MTcsMy42MTcsNy44OTgsNS40MjQsMTIuODQ3LDUuNDI0YzQuOTUsMCw5LjIzNC0xLjgwNywxMi44NDktNS40MjQgICBsNzMuMDg3LTczLjA4OGMzLjYxMy0zLjYyLDUuNDIxLTcuOTAxLDUuNDIxLTEyLjg0N2MwLTQuOTQ4LTEuODA4LTkuMjMyLTUuNDIxLTEyLjg1NCAgIEMzMzguMTQyLDQwMy44MDIsMzMzLjg1Nyw0MDEuOTk0LDMyOC45MDYsNDAxLjk5NHoiIGZpbGw9IiM2NjY2NjYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.grid-stack>.grid-stack-item>.ui-resizable-nw {
    cursor: nw-resize;
    width: 20px;
    height: 20px;
    top: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-n {
    cursor: n-resize;
    height: 10px;
    top: 0;
    left: 25px;
    right: 25px;
}

.grid-stack>.grid-stack-item>.ui-resizable-ne {
    cursor: ne-resize;
    width: 20px;
    height: 20px;
    top: 0;
}

.grid-stack>.grid-stack-item>.ui-resizable-e {
    cursor: e-resize;
    width: 10px;
    top: 15px;
    bottom: 15px;
}

.grid-stack>.grid-stack-item>.ui-resizable-se {
    cursor: se-resize;
    width: 20px;
    height: 20px;
}

.grid-stack>.grid-stack-item>.ui-resizable-s {
    cursor: s-resize;
    height: 10px;
    left: 25px;
    bottom: 0;
    right: 25px;
}

.grid-stack>.grid-stack-item>.ui-resizable-sw {
    cursor: sw-resize;
    width: 20px;
    height: 20px;
}

.grid-stack>.grid-stack-item>.ui-resizable-w {
    cursor: w-resize;
    width: 10px;
    top: 15px;
    bottom: 15px;
}

.grid-stack>.grid-stack-item.ui-draggable-dragging>.ui-resizable-handle {
    display: none !important;
}

.grid-stack.grid-stack-animate,
.grid-stack.grid-stack-animate .grid-stack-item {
    -webkit-transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
    -moz-transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
    -ms-transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
    -o-transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
    transition: left 0.3s, top 0.3s, height 0.3s, width 0.3s;
}

.grid-stack.grid-stack-animate .grid-stack-item.ui-draggable-dragging,
.grid-stack.grid-stack-animate .grid-stack-item.ui-resizable-resizing,
.grid-stack.grid-stack-animate .grid-stack-item.grid-stack-placeholder {
    -webkit-transition: left 0s, top 0s, height 0s, width 0s;
    -moz-transition: left 0s, top 0s, height 0s, width 0s;
    -ms-transition: left 0s, top 0s, height 0s, width 0s;
    -o-transition: left 0s, top 0s, height 0s, width 0s;
    transition: left 0s, top 0s, height 0s, width 0s;
}

.ui-draggable-dragging,
.ui-resizable-resizing {
    z-index: 100;
}

.ui-draggable-dragging>.grid-stack-item-content,
.ui-resizable-resizing>.grid-stack-item-content {
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0.8;
}

.ui-draggable-dragging {
    will-change: left, top;
    cursor: move;
}

.ui-resizable-resizing {
    will-change: width, height;
}

.grid-stack-item > .grid-stack-item-content {
    inset: 0 !important;
}

.grid-stack > .grid-stack-item[gs-w="0"] {
    width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-x="0"] {
    left: 0%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="0"] {
    min-width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="0"] {
    max-width: 0%;
  }
  .grid-stack > .grid-stack-item[gs-w="1"] {
    width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="1"] {
    left: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="1"] {
    min-width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="1"] {
    max-width: 8.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="2"] {
    width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="2"] {
    left: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="2"] {
    min-width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="2"] {
    max-width: 16.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="3"] {
    width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-x="3"] {
    left: 25%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="3"] {
    min-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="3"] {
    max-width: 25%;
  }
  .grid-stack > .grid-stack-item[gs-w="4"] {
    width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="4"] {
    left: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="4"] {
    min-width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="4"] {
    max-width: 33.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="5"] {
    width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="5"] {
    left: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="5"] {
    min-width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="5"] {
    max-width: 41.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="6"] {
    width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-x="6"] {
    left: 50%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="6"] {
    min-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="6"] {
    max-width: 50%;
  }
  .grid-stack > .grid-stack-item[gs-w="7"] {
    width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="7"] {
    left: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="7"] {
    min-width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="7"] {
    max-width: 58.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="8"] {
    width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="8"] {
    left: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="8"] {
    min-width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="8"] {
    max-width: 66.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="9"] {
    width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-x="9"] {
    left: 75%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="9"] {
    min-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="9"] {
    max-width: 75%;
  }
  .grid-stack > .grid-stack-item[gs-w="10"] {
    width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-x="10"] {
    left: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="10"] {
    min-width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="10"] {
    max-width: 83.3333333333%;
  }
  .grid-stack > .grid-stack-item[gs-w="11"] {
    width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-x="11"] {
    left: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="11"] {
    min-width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-max-w="11"] {
    max-width: 91.6666666667%;
  }
  .grid-stack > .grid-stack-item[gs-w="12"] {
    width: 100%;
  }
  .grid-stack > .grid-stack-item[gs-x="12"] {
    left: 100%;
  }
  .grid-stack > .grid-stack-item[gs-min-w="12"] {
    min-width: 100% !important;
  }
  .grid-stack > .grid-stack-item[gs-max-w="12"] {
    max-width: 100% !important;
  }

  @media screen and (max-width: 768px) {
    [gs-w] {
      width: 100% !important;
    }
  }