.invitation-box {
    background: #2f3a51;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    padding-left: 90px;
    float: left;
    padding-right: 40px;
    margin-bottom: 10px;
}
.invitation-box .first-letter {
    padding: 7px 0px;
    border-radius: 50px;
    background: #ffffff;
    color: #00cbaf;
    font-size: 18px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 22px;
    left: 25px;
    text-align: center;
}
.invitation-box .invitation-details {
    width: 70%;
    float: left;
}
.invitation-box .accept-decline {
    width: 30%;
    float: left;
}
.invitation-box .btn.accepted {
    color: #ffffff;
}
.invitation-box .btn.accepted i {
    color: #00cbaf;
    float: left;
    margin-right: 10px;
}

.btn.declined {
    color: #ffffff;
}

.btn.declined i {
    color: #f2495e;
    float: left;
    margin-right: 10px;
}

.invitation-box p.invite-date {
    color: #828fab;
    margin-bottom: 5px;
}
.invitation-box h5 {
    margin-bottom: 0;
}
.invitation-box .invite-org-details {
    background: #3a4660;
    padding: 20px 0;
    border-radius: 5px;
    float: left;
    width: 100%;
}
.invitation-box .descriptor-item {
    width: 100%;
    border-bottom: none;
    float: left;
    padding: 0px 30px;
}
.invitation-box .descriptor-item .b-bottom {
    width: 100%;
    border-bottom: 1px solid #3e4b67;
    float: left;
    margin: 10px 0;
}
.invitation-box .descriptor-item h6 {
    margin: 0;
}
.invitation-box h6.ginfo {
    color: #828fab;
    text-align: right;
    word-wrap: break-word;
}

.text-limitor {
    max-width: 50%;
}

@media (max-width: 992px) {
    .invitation-box .invitation-details {
        width: 100%;
    }
    .invitation-box .accept-decline {
        width: 100%;
        margin-bottom: 20px;
        text-align: left !important;
    }
}
@media (max-width: 768px) {
    /* upto md*/
    .invitation-box .invite-org-details {
        padding: 15px 0;
    }
    .invitation-box .descriptor-item {
        padding: 10px 20px;
    }
    .invitation-box .descriptor-item .b-bottom {
        display: none;
    }
    .invitation-box .descriptor-item h6 {
        width: 100%;
    }
    .text-limitor {
        max-width: 100%;
    }
}
